import React, { useState, useContext } from 'react';
import { Form, Alert, Button } from 'react-bootstrap';
import { SettingsContext } from "../../../../SettingsContext";
import PhoneNumberInput from "../../../../global/PhoneNumberInput";
import { addCustomerToOrganization } from "../../../../../services/Http/Api";
import { useTranslation } from 'react-i18next'; // Import useTranslation

const UserCreate = ({ onClose, onUserAdded }) => {
    const { siteSettings } = useContext(SettingsContext);
    const { t } = useTranslation(); // Initialize translation hook
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        phone_number: '',
        phone_country_id: '',
        country_id: '',
        personal_id: '',
        gender: ''
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePhoneNumberChange = ({ phoneCountryCode, phone }) => {
        setFormData({ ...formData, phone_country_id: phoneCountryCode, phone_number: phone });
    };

    const handleSubmit = async () => {
        setLoading(true);
        setErrors({});
        try {
            await addCustomerToOrganization(formData);
            onUserAdded(); // Notify parent component to refresh the user list
            onClose(); // Close the modal
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Failed to create buyer:', error);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form>
            {Object.keys(errors).length > 0 && (
                <Alert variant="danger">{t('Users.UserCreate.errorAlert')}</Alert> // Use translation for error alert
            )}

            <Form.Group className="mb-3">
                <Form.Label>{t('Users.UserCreate.name')}</Form.Label> {/* Translated label */}
                <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>{t('Users.UserCreate.surname')}</Form.Label> {/* Translated label */}
                <Form.Control
                    type="text"
                    name="surname"
                    value={formData.surname}
                    onChange={handleInputChange}
                    isInvalid={!!errors.surname}
                />
                <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>{t('Users.UserCreate.email')}</Form.Label> {/* Translated label */}
                <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>{t('Users.UserCreate.country')}</Form.Label> {/* Translated label */}
                <Form.Select
                    name="country_id"
                    value={formData.country_id}
                    onChange={handleInputChange}
                    isInvalid={!!errors.country_id}
                >
                    <option value="">{t('Users.UserCreate.selectCountry')}</option> {/* Translated option */}
                    {siteSettings?.countries.map((country) => (
                        <option key={country.id} value={country.id}>{country.name}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.country_id}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>{t('Users.UserCreate.phoneNumber')}</Form.Label> {/* Translated label */}
                <PhoneNumberInput
                    countryCodes={siteSettings.countries}
                    phoneValue={formData.phone_number}
                    phoneCountryValue={formData.phone_country_id}
                    onChange={handlePhoneNumberChange}
                />
                {errors.phone_number && <div className="invalid-feedback d-block">{errors.phone_number}</div>}
                {errors.phone_country_id && <div className="invalid-feedback d-block">{errors.phone_country_id}</div>}
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>{t('Users.UserCreate.personalId')}</Form.Label> {/* Translated label */}
                <Form.Control
                    type="text"
                    name="personal_id"
                    value={formData.personal_id}
                    onChange={handleInputChange}
                    isInvalid={!!errors.personal_id}
                />
                <Form.Control.Feedback type="invalid">{errors.personal_id}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>{t('Users.UserCreate.gender')}</Form.Label> {/* Translated label */}
                <Form.Select
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    isInvalid={!!errors.gender}
                >
                    <option value="">{t('Users.UserCreate.selectGender')}</option> {/* Translated option */}
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.gender}</Form.Control.Feedback>
            </Form.Group>

            <div className="text-end">
                <Button variant="secondary" onClick={onClose} disabled={loading}>
                    {t('Users.UserCreate.cancel')} {/* Translated cancel button */}
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? t('Users.UserCreate.saving') : t('Users.UserCreate.save')} {/* Translated save button */}
                </Button>
            </div>
        </Form>
    );
};

export default UserCreate;
