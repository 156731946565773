import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Spinner, Input, Button } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import classnames from 'classnames';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import QuestionModal from "../../global/QuestionModal";

const WarrantiesList = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('1');
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [newTemplateName, setNewTemplateName] = useState('');
    const [newTemplateDescription, setNewTemplateDescription] = useState('');
    const [selectedManufacturer, setSelectedManufacturer] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [showTutorialModal, setShowTutorialModal] = useState(false);

    // Dummy data for warranties
    const warranties = [
        {
            id: 1,
            name: 'Standard Warranty',
            status: 'Active',
            expirationDate: '2025-12-31',
            coverageDetails: 'Covers manufacturing defects.',
            termsAndConditions: 'Must provide proof of purchase.',
            documentationUrl: 'http://example.com/standard-warranty'
        },
        {
            id: 2,
            name: 'Extended Warranty',
            status: 'Active',
            expirationDate: '2026-06-30',
            coverageDetails: 'Covers all parts and labor for an additional year.',
            termsAndConditions: 'No accidental damage coverage.',
            documentationUrl: 'http://example.com/extended-warranty'
        },
    ];

    // Dummy data for warranty templates
    const warrantyTemplates = [
        { id: 1, name: 'Basic Warranty', description: 'Basic coverage for one year', manufacturer: 'Manufacturer A', category: 'Category 1' },
        { id: 2, name: 'Premium Warranty', description: 'Comprehensive coverage including accidents', manufacturer: 'Manufacturer B', category: 'Category 2' },
    ];

    const manufacturers = [
        { value: 'manufacturer-a', label: 'Manufacturer A' },
        { value: 'manufacturer-b', label: 'Manufacturer B' },
        { value: 'manufacturer-c', label: 'Manufacturer C' },
    ];

    const categories = [
        { value: 'category-1', label: 'Category 1' },
        { value: 'category-2', label: 'Category 2' },
        { value: 'category-3', label: 'Category 3' },
    ];

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleShowTemplateModal = () => setShowTemplateModal(true);
    const handleCloseTemplateModal = () => {
        setShowTemplateModal(false);
        setNewTemplateName('');
        setNewTemplateDescription('');
        setSelectedManufacturer(null);
        setSelectedCategory(null);
    };

    const handleCreateTemplate = () => {
        console.log('Creating new template:', newTemplateName, newTemplateDescription, selectedManufacturer, selectedCategory);
        handleCloseTemplateModal();
    };

    const filteredWarranties = warranties.filter(warranty =>
        warranty.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const columns = [
        { name: t('Warranties.warrantyName'), selector: row => row.name, sortable: true },
        { name: t('Warranties.status'), selector: row => row.status, sortable: true },
        { name: t('Warranties.expirationDate'), selector: row => row.expirationDate, sortable: true },
        { name: t('Warranties.coverageDetails'), selector: row => row.coverageDetails, sortable: true },
        { name: t('Warranties.termsAndConditions'), selector: row => row.termsAndConditions, sortable: true },
        { name: t('Warranties.documentation'), cell: row => <a href={row.documentationUrl} target="_blank" rel="noopener noreferrer">{t('Warranties.view')}</a> }
    ];

    const templateColumns = [
        { name: t('Warranties.templateName'), selector: row => row.name, sortable: true },
        { name: t('Warranties.description'), selector: row => row.description, sortable: true },
        { name: t('Warranties.manufacturer'), selector: row => row.manufacturer, sortable: true },
        { name: t('Warranties.category'), selector: row => row.category, sortable: true },
    ];

    return (
        <div className="container-fluid mt-4">
            {/* Navigation Tabs */}
            <Nav tabs className="mb-3 justify-content-start">
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => toggleTab('1')}
                        style={{ cursor: 'pointer' }}
                    >
                        {t('Warranties.warrantiesOverview')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => toggleTab('2')}
                        style={{ cursor: 'pointer' }}
                    >
                        {t('Warranties.warrantyTemplates')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => toggleTab('3')}
                        style={{ cursor: 'pointer' }}
                    >
                        {t('Warranties.searchWarranties')}
                    </NavLink>
                </NavItem>
            </Nav>

            {/* Action Buttons for Modals */}
            <div className="d-flex justify-content-end mb-3">
                <Button color="link" onClick={() => setShowQuestionModal(true)}>
                    <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '1.5rem', color: 'rgba(130, 22, 146, 1)' }} />
                </Button>
                <Button color="link" onClick={() => { setShowTutorialModal(true); }}>
                    <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '1.5rem', color: 'rgba(130, 22, 146, 1)' }} />
                </Button>
            </div>

            {/* Tab Content */}
            <TabContent activeTab={activeTab}>
                {/* Warranties Overview Tab */}
                <TabPane tabId="1">
                    <Card className="mb-3">
                        <CardBody>
                            {loading ? (
                                <Spinner color="primary" />
                            ) : (
                                <DataTable
                                    columns={columns}
                                    data={filteredWarranties}
                                    pagination
                                    highlightOnHover
                                    noDataComponent={<div>{t('Warranties.noWarranties')}</div>}
                                />
                            )}
                        </CardBody>
                    </Card>
                </TabPane>

                {/* Warranty Templates Tab */}
                <TabPane tabId="2">
                    <Card className="mb-3">
                        <CardBody>
                            <h5>{t('Warranties.warrantyTemplates')}</h5>
                            <Button color="primary" onClick={handleShowTemplateModal}>{t('Warranties.createNewTemplate')}</Button>
                            <DataTable
                                columns={templateColumns}
                                data={warrantyTemplates}
                                pagination
                                highlightOnHover
                                noDataComponent={<div>{t('Warranties.noTemplates')}</div>}
                            />
                        </CardBody>
                    </Card>
                </TabPane>

                {/* Search Warranties Tab */}
                <TabPane tabId="3">
                    <Card className="mb-3">
                        <CardBody>
                            <h5>{t('Warranties.searchByWarrantyName')}</h5>
                            <Input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder={t('Warranties.enterWarrantyName')}
                                className="mb-2"
                            />
                            <DataTable
                                columns={columns}
                                data={filteredWarranties}
                                pagination
                                highlightOnHover
                                noDataComponent={<div>{t('Warranties.noWarranties')}</div>}
                            />
                        </CardBody>
                    </Card>
                </TabPane>
            </TabContent>

            {/* Create Warranty Template Modal */}
            <Modal isOpen={showTemplateModal} toggle={handleCloseTemplateModal}>
                <div className="modal-header">
                    <h5 className="modal-title">{t('Warranties.createWarrantyTemplate')}</h5>
                    <button type="button" className="close" onClick={handleCloseTemplateModal}>
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Input
                        type="text"
                        value={newTemplateName}
                        onChange={(e) => setNewTemplateName(e.target.value)}
                        placeholder={t('Warranties.templateName')}
                        className="mb-2"
                    />
                    <Input
                        type="textarea"
                        value={newTemplateDescription}
                        onChange={(e) => setNewTemplateDescription(e.target.value)}
                        placeholder={t('Warranties.templateDescription')}
                        className="mb-2"
                    />
                    <Select
                        placeholder={t('Warranties.selectManufacturer')}
                        options={manufacturers}
                        onChange={(option) => setSelectedManufacturer(option)}
                        className="mb-2"
                    />
                    <Select
                        placeholder={t('Warranties.selectCategory')}
                        options={categories}
                        onChange={(option) => setSelectedCategory(option)}
                        className="mb-2"
                    />
                </div>
                <div className="modal-footer">
                    <Button color="secondary" onClick={handleCloseTemplateModal}>{t('Warranties.cancel')}</Button>
                    <Button color="primary" onClick={handleCreateTemplate}>{t('Warranties.createTemplate')}</Button>
                </div>
            </Modal>

            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={() => setShowQuestionModal(false)}
                param="warranties_overview"
            />

            {/* Tutorial Modal */}
            <Modal show={showTutorialModal} onHide={() => setShowTutorialModal(false)} size="xl" centered>
                <div className="modal-header">
                    <h5 className="modal-title">{t('Warranties.tutorialTitle')}</h5>
                    <button type="button" className="close" onClick={() => setShowTutorialModal(false)}>
                        <span>&times;</span>
                    </button>
                </div>
                <Modal.Body>
                    <div style={{ height: 'auto' }}>
                        <div className="welcome-message" style={{ marginBottom: '1.5rem' }}>
                            <p style={{ fontSize: '1.5rem', lineHeight: '1.6', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Warranties.welcomeMessage')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Warranties.createTemplateTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Warranties.createTemplateMessage')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Warranties.trackStatusTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Warranties.trackStatusMessage')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Warranties.searchWarrantiesTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Warranties.searchWarrantiesMessage')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Warranties.documentationTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Warranties.documentationMessage')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Warranties.manageTemplatesTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Warranties.manageTemplatesMessage')}
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default WarrantiesList;
