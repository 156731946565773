import React, { useState, useEffect } from 'react';
import { fetchUsersStatistics } from '../../../../services/Http/Api';
import SalesByCountries from './components/SalesByCountries';
import { FaUsers, FaUserTie, FaGlobe, FaMoneyBillWave, FaWallet } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import QuestionModal from "../../../global/QuestionModal"; // Import useTranslation

const Statistics = () => {
    const { t } = useTranslation(); // Initialize translation hook
    const [statistics, setStatistics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showQuestionModal, setShowQuestionModal] = useState(false);

    const handleShowQuestionModal = () => setShowQuestionModal(true);
    const handleCloseQuestionModal = () => setShowQuestionModal(false);
    useEffect(() => {
        const getStatistics = async () => {
            try {
                const data = await fetchUsersStatistics();
                // Check if data is empty or missing required keys
                if (data && Object.keys(data).length > 0) {
                    setStatistics(data);
                } else {
                    setStatistics(null); // Set to null if data is empty
                }
            } catch (error) {
                console.error('Error fetching statistics:', error);
            } finally {
                setLoading(false);
            }
        };

        getStatistics();
    }, []);

    if (loading) {
        return <p>{t('Users.UserStatistics.loading')}</p>; // Translated loading message
    }

    if (!statistics) {
        return <p>{t('Users.UserStatistics.noStatistics')}</p>; // Translated no statistics message
    }

    return (
        <div className="statistics-container mt-5">
            <h3>{t('Users.UserStatistics.overviewTitle')}
                <button className="btn btn-link" onClick={handleShowQuestionModal} style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)' }}>
                    <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '1.5rem' }} />
                </button>
            </h3>
            <div className="row mb-4">
                {/* Total Buyers */}
                <div className="col-md-3 mb-3">
                    <div className="card">
                        <div className="card-body d-flex align-items-center">
                            <FaUsers size={30} className="me-3 text-primary" />
                            <div>
                                <h5 className="card-title">{t('Users.UserStatistics.totalBuyers')}</h5>
                                <p className="card-text">{statistics.total_buyers.toLocaleString()}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Total Buyers Own */}
                <div className="col-md-3 mb-3">
                    <div className="card">
                        <div className="card-body d-flex align-items-center">
                            <FaUserTie size={30} className="me-3 text-success" />
                            <div>
                                <h5 className="card-title">{t('Users.UserStatistics.totalBuyersOwn')}</h5>
                                <p className="card-text">{statistics.total_buyers_own.toLocaleString()}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Total Buyers Global */}
                <div className="col-md-3 mb-3">
                    <div className="card">
                        <div className="card-body d-flex align-items-center">
                            <FaGlobe size={30} className="me-3 text-info" />
                            <div>
                                <h5 className="card-title">{t('Users.UserStatistics.totalBuyersGlobal')}</h5>
                                <p className="card-text">{statistics.total_buyers_global.toLocaleString()}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Total Revenue Own */}
                <div className="col-md-3 mb-3">
                    <div className="card">
                        <div className="card-body d-flex align-items-center">
                            <FaMoneyBillWave size={30} className="me-3 text-warning" />
                            <div>
                                <h5 className="card-title">{t('Users.UserStatistics.totalRevenueOwn')}</h5>
                                <p className="card-text">${parseFloat(statistics.total_revenue_own).toLocaleString()}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Total Revenue Global */}
                <div className="col-md-3 mb-3">
                    <div className="card">
                        <div className="card-body d-flex align-items-center">
                            <FaWallet size={30} className="me-3 text-danger" />
                            <div>
                                <h5 className="card-title">{t('Users.UserStatistics.totalRevenueGlobal')}</h5>
                                <p className="card-text">${parseFloat(statistics.total_revenue_global).toLocaleString()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section for Sales by Countries and Top Buyers by Items */}
            <div className="row mb-4">
                {/* Adjusted col-md-5 for more space utilization */}
                <div className="col-md-5">
                    <SalesByCountries data={statistics.summary_by_countries} />
                </div>

                <div className="col-md-5">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{t('Users.UserStatistics.topBuyersByItems')}</h5>
                            <ul className="list-group">
                                {statistics.top_buyers_by_items.map((buyer, index) => (
                                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                        {buyer.user_name} {buyer.user_surname} - {buyer.count_of_items} {t('Users.UserStatistics.items')}
                                        <span className={`badge bg-${buyer.own_buyer ? 'success' : 'primary'}`}>
                                            {buyer.own_buyer ? t('Users.UserStatistics.own') : t('Users.UserStatistics.global')}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* Top Buyers by Total Spend */}
            <div className="row mb-4">
                <div className="col-md-5">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{t('Users.UserStatistics.topBuyersByTotalSpent')}</h5>
                            <ul className="list-group">
                                {statistics.top_buyers_by_total_spent.map((buyer, index) => (
                                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                        {buyer.user_name} {buyer.user_surname} - ${parseFloat(buyer.total_spent).toLocaleString()}
                                        <span className={`badge bg-${buyer.own_buyer ? 'success' : 'primary'}`}>
                                            {buyer.own_buyer ? t('Users.UserStatistics.own') : t('Users.UserStatistics.global')}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <QuestionModal
                show={showQuestionModal}
                onClose={handleCloseQuestionModal}
                param="users_statistics" // Pass the parameter for the question part
            />
        </div>

    );
};

export default Statistics;
