import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isAuthenticated } from '../services/Http/AuthService';

const ProtectedRoute = ({ element }) => {
    const isAuth = isAuthenticated();
    return isAuth ? element : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
