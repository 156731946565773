import React from 'react';

const UserInfoSettings = () => {
    return (
        <div>
            <h3>User Info Settings</h3>
            {/* Form for User Info */}
            <form>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input type="text" className="form-control" placeholder="Enter your name" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input type="email" className="form-control" placeholder="Enter your email" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Phone Number</label>
                    <input type="text" className="form-control" placeholder="Enter your phone number" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Country</label>
                    <select className="form-control">
                        {/* Populate with country options */}
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">Save Changes</button>
            </form>
        </div>
    );
};

export default UserInfoSettings;
