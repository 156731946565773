// models/Strategy.js

class Strategy {
    constructor(data = {}) {
        this.id = data.id || null;
        this.promotionId = data.promotion_id || null; // The promotion ID that this strategy is associated with
        this.strategyTypeId = data.strategy_type_id || ''; // The type of strategy, e.g., Notifications
        this.name = data.name || ''; // The name of the strategy
        this.globalUsers = data.global_users || false; // Whether the strategy is applicable to global users
        this.frequencyCap = data.frequency_cap || null; // Frequency cap for the strategy
        this.impressionLimit = data.impression_limit || null; // Impression limit for the strategy

        // Initialize values associated with this strategy
        this.values = (data.values || []).map(value => new StrategyValue(value));
    }

    // Method to transform the data to a format suitable for backend communication
    toBackendFormat() {
        return {
            id: this.id,
            promotion_id: this.promotionId,
            strategy_type_id: this.strategyTypeId,
            name: this.name,
            global_users: this.globalUsers,
            frequency_cap: this.frequencyCap,
            impression_limit: this.impressionLimit,
            values: this.values.map(value => value.toBackendFormat()), // Convert values to backend format
        };
    }
}

// Class representing the values for a strategy
class StrategyValue {
    constructor(data = {}) {
        this.id = data.id || null;
        this.promotionStrategyId = data.promotion_strategy_id || null; // The ID of the associated promotion strategy
        this.attributeId = data.attribute_id || ''; // The attribute ID from the strategy type attributes
        this.value = data.value || ''; // The value assigned to this attribute
    }

    // Method to transform the data to a format suitable for backend communication
    toBackendFormat() {
        return {
            id: this.id,
            promotion_strategy_id: this.promotionStrategyId,
            attribute_id: this.attributeId,
            value: this.value,
        };
    }
}

export default Strategy;
