import React, { useState } from 'react';
import GeneralSettings from './GeneralSettings';
import UserInfoSettings from './UserInfoSettings';
import SecuritySettings from './SecuritySettings';
import BillingSettings from './BillingSettings';

const SettingsPage = () => {
    const [activeTab, setActiveTab] = useState('general');

    const renderTabContent = () => {
        switch (activeTab) {
            case 'general':
                return <GeneralSettings />;
            case 'user-info':
                return <UserInfoSettings />;
            case 'security':
                return <SecuritySettings />;
            case 'billing':
                return <BillingSettings />;
            default:
                return <GeneralSettings />;
        }
    };

    return (
        <div className="settings-page container">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'general' ? 'active' : ''}`}
                        onClick={() => setActiveTab('general')}
                    >
                        General
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'user-info' ? 'active' : ''}`}
                        onClick={() => setActiveTab('user-info')}
                    >
                        User Info
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'security' ? 'active' : ''}`}
                        onClick={() => setActiveTab('security')}
                    >
                        Security
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'billing' ? 'active' : ''}`}
                        onClick={() => setActiveTab('billing')}
                    >
                        Billing
                    </button>
                </li>
            </ul>
            <div className="tab-content">
                {renderTabContent()}
            </div>
        </div>
    );
};

export default SettingsPage;
