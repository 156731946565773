import React, { createContext, useState, useEffect } from 'react';
import { fetchSiteSettings } from '../services/Http/Api';
import SiteSettings from "../models/global/SiteSettings";

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
    const [siteSettings, setSiteSettings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const loadSettings = async () => {
            try {
                const settingsData = await fetchSiteSettings();

                // Destructure only the needed fields from settingsData for the SiteSettings constructor
                const { countries, languages, periods, transactions, promotions } = settingsData;

                const settings = new SiteSettings({ countries, languages, periods, transactions,promotions }); // Pass the destructured fields

                if (isMounted) {
                    setSiteSettings(settings); // Store the SiteSettings object in state
                    setLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Failed to fetch site settings', error);
                    setError('Failed to load settings');
                    setLoading(false);
                }
            }
        };

        loadSettings();

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <SettingsContext.Provider value={{ siteSettings, loading, error }}>
            {children}
        </SettingsContext.Provider>
    );
};
