import React, { useContext } from 'react';
import {SettingsContext} from "../SettingsContext";

const PeriodSelectBox = ({ value, onChange }) => {
    const { siteSettings, loading } = useContext(SettingsContext);

    if (loading) {
        return <div>Loading...</div>;
    }
    return (
        <select className="form-select w-auto" value={value} onChange={onChange}>
            {siteSettings.periods.map(period => (
                <option key={period.value+period.name} value={period.value}>
                    {period.name}
                </option>
            ))}
        </select>
    );
};

export default PeriodSelectBox;
