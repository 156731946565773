import React from 'react';
import { Link } from 'react-router-dom';
import { getCurrentUser } from '../../services/Http/AuthService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTachometerAlt,
    faUsers,
    faExchangeAlt,
    faStore,
    faBoxOpen,
    faClipboardList,
    faFileAlt,
    faBullhorn,
    faSearch,
    faCog,
    faSignOutAlt,
    faCashRegister, faTools,faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Navigation = ({ handleLogout }) => {
    const { t } = useTranslation();
    const user = getCurrentUser();

    return (
        <ul className="menu-inner py-1" style={{ fontSize: '1.2rem' }}>
            <li className="menu-item">
                <Link className="menu-link" to="/dashboard" style={{ color: '#d27fea', fontSize: '1.2rem', padding: '12px 20px' }}>
                    <FontAwesomeIcon icon={faTachometerAlt} className="me-3" style={{ color: '#d27fea', fontSize: '1.5rem' }} /> {t('Navigation.dashboard')}
                </Link>
            </li>
            <li className="menu-item">
                <Link className="menu-link" to="/users" style={{ color: '#d27fea', fontSize: '1.2rem', padding: '12px 20px' }}>
                    <FontAwesomeIcon icon={faUsers} className="me-3" style={{ color: '#d27fea', fontSize: '1.5rem' }} /> {t('Navigation.users')}
                </Link>
            </li>
            <li className="menu-item">
                <Link className="menu-link" to="/transactions" style={{ color: '#d27fea', fontSize: '1.2rem', padding: '12px 20px' }}>
                    <FontAwesomeIcon icon={faExchangeAlt} className="me-3" style={{ color: '#d27fea', fontSize: '1.5rem' }} /> {t('Navigation.transactions')}
                </Link>
            </li>
            <li className="menu-item">
                <Link className="menu-link" to="/venues" style={{ color: '#d27fea', fontSize: '1.2rem', padding: '12px 20px' }}>
                    <FontAwesomeIcon icon={faStore} className="me-3" style={{ color: '#d27fea', fontSize: '1.5rem' }} /> {t('Navigation.venues')}
                </Link>
            </li>
            <li className="menu-item">
                <Link className="menu-link" to="/campaigns" style={{ color: '#d27fea', fontSize: '1.2rem', padding: '12px 20px' }}>
                    <FontAwesomeIcon icon={faBullhorn} className="me-3" style={{ color: '#d27fea', fontSize: '1.5rem' }} /> {t('Navigation.campaigns')}
                </Link>
            </li>
            <li className="menu-item">
                <Link className="menu-link" to="/items" style={{ color: '#d27fea', fontSize: '1.2rem', padding: '12px 20px' }}>
                    <FontAwesomeIcon icon={faBoxOpen} className="me-3" style={{ color: '#d27fea', fontSize: '1.5rem' }} /> {t('Navigation.items')}
                </Link>
            </li>
            <li className="menu-item">
                <Link className="menu-link" to="/warranties" style={{ color: '#d27fea', fontSize: '1.2rem', padding: '12px 20px' }}>
                    <FontAwesomeIcon icon={faClipboardList} className="me-3" style={{ color: '#d27fea', fontSize: '1.5rem' }} /> {t('Navigation.warranties')}
                    <FontAwesomeIcon icon={faExclamationCircle} className="ms-2" style={{ color: 'red', fontSize: '1rem' }} /> {/* Under Development Icon */}
                </Link>
            </li>
            <li className="menu-item">
                <Link className="menu-link" to="/authorized_services" style={{ color: '#d27fea', fontSize: '1.2rem', padding: '12px 20px' }}>
                    <FontAwesomeIcon icon={faTools} className="me-3" style={{ color: '#d27fea', fontSize: '1.5rem' }} /> {t('Navigation.authorizedServices')}
                    <FontAwesomeIcon icon={faExclamationCircle} className="ms-2" style={{ color: 'red', fontSize: '1rem' }} /> {/* Under Development Icon */}
                </Link>
            </li>
            <li className="menu-item">
                <Link className="menu-link" to="/integration" style={{ color: '#d27fea', fontSize: '1.2rem', padding: '12px 20px' }}>
                    <FontAwesomeIcon icon={faCashRegister} className="me-3" style={{ color: '#d27fea', fontSize: '1.5rem' }} /> {t('Navigation.pos_integration')}
                </Link>
            </li>
            <li className="menu-item">
                <Link className="menu-link" to="/settings" style={{ color: '#d27fea', fontSize: '1.2rem', padding: '12px 20px' }}>
                    <FontAwesomeIcon icon={faCog} className="me-3" style={{ color: '#d27fea', fontSize: '1.5rem' }} /> {t('Navigation.settings')}
                    <FontAwesomeIcon icon={faExclamationCircle} className="ms-2" style={{ color: 'red', fontSize: '1rem' }} /> {/* Under Development Icon */}
                </Link>
            </li>
            <li className="menu-item">
                <Link onClick={handleLogout} className="menu-link" style={{ color: '#d27fea', fontSize: '1.2rem', padding: '12px 20px' }}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-3" style={{ color: '#d27fea', fontSize: '1.5rem' }} /> {t('Navigation.logout')}
                </Link>
            </li>
        </ul>
    );
};

export default Navigation;
