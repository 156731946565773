// models/SiteSettings.js
import Country from './SiteConstants/Country';
import Language from './SiteConstants/Language';
import Period from "./SiteConstants/Period";
import Transaction from "./SiteConstants/Transaction";
import Promotions from "./SiteConstants/Promotions";

class SiteSettings {
    constructor({ countries = [], languages = [], periods = [], transactions =[], promotions =[] } = {}) {
        // Map the raw country and language data to the Country and Language models
        this.countries = countries.map(country => new Country(country));
        this.languages = languages.map(language => new Language(language));
        this.periods = periods.map(period => new Period(period));
        this.transactions =  new Transaction(transactions);
        this.promotions = new Promotions(promotions);
    }

    getAllCountries() {
        return this.countries;
    }

    // Method to get all languages
    getAllLanguages() {
        return this.languages;
    }

    // Method to get all periods
    getAllPeriods() {
        return this.periods;
    }

    // Access condition types from promotions
    getConditionTypes() {
        return this.promotions.getConditionTypes();
    }
    getIncentiveTypes() {
        return this.promotions.getIncentiveTypes();
    }
    getTargetTypes() {
        return this.promotions.getTargetTypes();
    }
    getStrategyTypes() {
        return this.promotions.getStrategyTypes();
    }

}

export default SiteSettings;
