import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, FormGroup, Form } from 'reactstrap';
import Select from 'react-select';
import { fetchManufacturers } from "../../../../services/Http/Api";
import { useTranslation } from 'react-i18next'; // Import useTranslation

const SearchFilters = ({ onFilter }) => {
    const { t } = useTranslation(); // Initialize translation hook
    const [searchTerm, setSearchTerm] = useState(''); // For search term
    const [manufacturerId, setManufacturerId] = useState(''); // For manufacturer ID
    const [manufacturers, setManufacturers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    // Fetch manufacturers from API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetchManufacturers({ page: currentPage, perPage: 100 });
                setManufacturers(result.data); // Set manufacturers data
            } catch (error) {
                console.error('Error fetching manufacturers:', error);
            }
        };

        fetchData();
    }, [currentPage]);

    const handleSearch = (e) => {
        e.preventDefault();
        // Pass the filters to the parent component (ItemsOverview) only when "Search" is pressed
        onFilter({
            searchTerm, // Pass the search term from input
            manufacturerId: manufacturerId || null, // Pass the manufacturerId from Select
        });
    };

    return (
        <Form onSubmit={handleSearch}>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder={t('Items.SearchFilters.search_placeholder')} // Translated placeholder
                        />
                    </FormGroup>
                </Col>

                <Col md="6">
                    <FormGroup>
                        <Select
                            placeholder={t('Items.SearchFilters.select_manufacturer')} // Translated placeholder
                            options={manufacturers.map((manufacturer) => ({
                                value: manufacturer.manufacturer_id,
                                label: `${manufacturer.manufacturer_name} (${manufacturer.item_count})`,
                            }))}
                            onChange={(selectedOption) => setManufacturerId(selectedOption?.value || '')}
                            isClearable
                        />
                    </FormGroup>
                </Col>

                <Col md="12">
                    <Button type="submit" color="primary">
                        {t('Items.SearchFilters.search_button')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default SearchFilters;
