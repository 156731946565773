import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setAuthData } from '../../services/Http/AuthService';
import { login } from '../../services/Http/Api';
import { FlagIcon } from 'react-flag-kit';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faExclamationCircle, faInfoCircle, faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import UserInfoModal from '../global/UserInfoModal'; // Import the UserInfoModal
import Logo from '../../../src/assets/images/gsoft_logo.png';

const LoginPage = ({ setAuth }) => {
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');
    const [showTutorialModal, setShowTutorialModal] = useState(!localStorage.getItem('tutorialSeen'));
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (showTutorialModal) return; // Don't check for user info modal until tutorial is closed
        const contactEmail = localStorage.getItem('contactEmail');
        if (!contactEmail) {
            setShowUserInfoModal(true); // Show user info modal if email is not in local storage
        }
    }, [showTutorialModal]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoginError(''); // Reset login error message
        try {
            const data = await login(email, password);
            setAuthData(data.token); // Assuming the token is returned directly
            setAuth(true); // Update auth state
            navigate('/'); // Redirect to home after successful login
        } catch (error) {
            setLoginError(t('LoginPage.login_error'));
        }
    };

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        localStorage.setItem('selectedLanguage', language);
        i18n.changeLanguage(language);
    };

    useEffect(() => {
        if (!localStorage.getItem('selectedLanguage')) {
            localStorage.setItem('selectedLanguage', 'en');
        } else {
            i18n.changeLanguage(selectedLanguage);
        }
    }, [selectedLanguage, i18n]);

    const handleCloseTutorialModal = () => {
        setShowTutorialModal(false);
        localStorage.setItem('tutorialSeen', 'true'); // Save to local storage
    };

    const handleCloseUserInfoModal = () => {
        setShowUserInfoModal(false);
    };

    return (
        <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="card" style={{ width: '400px', padding: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                <div className="text-center mb-4">
                    <img src={Logo} alt="GSOFT Logo" style={{ width: '150px', marginBottom: '10px' }} />
                    <h2 style={{ color: 'rgba(130, 22, 146, 1)' }}>DEMO</h2>
                    <button
                        className="btn btn-link"
                        onClick={() => setShowTutorialModal(true)}
                        style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)' }}
                    >
                        <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '1.5rem' }} />
                    </button>
                </div>
                <div className="d-flex justify-content-around mb-3">
                    <button
                        className={`btn ${selectedLanguage === 'en' ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={() => handleLanguageChange('en')}
                        style={{ borderRadius: '50%', width: '50px', height: '50px' }}
                    >
                        <FlagIcon code="GB" size={32} />
                    </button>
                    <button
                        className={`btn ${selectedLanguage === 'hr' ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={() => handleLanguageChange('hr')}
                        style={{ borderRadius: '50%', width: '50px', height: '50px' }}
                    >
                        <FlagIcon code="HR" size={32} />
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group mb-3">
                        <label>{t('LoginPage.email_label')}</label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label>{t('LoginPage.password_label')}</label>
                        <input
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    {loginError && <div className="alert alert-danger">{loginError}</div>}
                    <button type="submit" className="btn btn-primary w-100">{t('LoginPage.login_button')}</button>
                </form>
            </div>

            {/* Tutorial Modal */}
            <Modal show={showTutorialModal} onHide={handleCloseTutorialModal} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('LoginPage.Tutorial.welcome_title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: 'auto' }}>
                        <div className="welcome-message" style={{ marginBottom: '1.5rem' }}>
                            <p style={{ fontSize: '1.5rem', lineHeight: '1.6', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('LoginPage.Tutorial.welcome_message')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('LoginPage.Tutorial.about_app_title', { defaultValue: "About the App" })}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('LoginPage.Tutorial.about_app')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('LoginPage.Tutorial.info_title', { defaultValue: "Information" })}
                            </h5>
                            <p style={{ fontSize: '1.2rem', lineHeight: '1.6' }}>
                                {t('LoginPage.Tutorial.info_message')}
                                <FontAwesomeIcon icon={faInfoCircle} style={{ color: 'rgba(130, 22, 146, 1)', marginLeft: '0.5rem' }} />
                            </p>
                            <p style={{ fontSize: '1.2rem', lineHeight: '1.6' }}>
                                <FontAwesomeIcon icon={faExclamationCircle} className="ms-2" style={{ color: 'red', fontSize: '1rem' }} /> {/* Under Development Icon */}
                                {t('LoginPage.Tutorial.development_message')}
                            </p>
                        </div>


                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('LoginPage.Tutorial.credentials_title', { defaultValue: "Demo Credentials" })}
                            </h5>
                            <p style={{ fontSize: '1.2rem', lineHeight: '1.6' }}>
                                {t('LoginPage.Tutorial.demo_credentials').split('\n').map((line, index) => (
                                    <React.Fragment key={index}>{line}<br /></React.Fragment>
                                ))}
                            </p>
                        </div>
                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('LoginPage.Tutorial.question_title', { defaultValue: "Have Questions?" })}
                            </h5>
                            <p style={{ fontSize: '1.2rem', lineHeight: '1.6' }}>
                                {t('LoginPage.Tutorial.question_message', {
                                    defaultValue: "If you have any questions about the system or page, just click the question mark icon and write us your question. Someone will answer you soon."
                                })}
                                <FontAwesomeIcon icon={faQuestionCircle} style={{ color: 'rgba(130, 22, 146, 1)', marginLeft: '0.5rem' }} />
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* User Info Modal */}
            <UserInfoModal
                show={showUserInfoModal}
                onClose={handleCloseUserInfoModal}
            />
        </div>
    );
};

export default LoginPage;
