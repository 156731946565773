// UserCountryComponent.js
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';
import { SettingsContext } from "../../../../SettingsContext";

const UserCountryComponent = ({ value, operator, onChange, target, type, attribute }) => {
    const { siteSettings } = useContext(SettingsContext);
    const [selectedCountries, setSelectedCountries] = useState([]); // Store selected countries

    // Fetch supported operators based on target, type, and attribute
    let supportedOperators = [];
    let conditionTypes = [];

    if (target === 'conditions') {
        conditionTypes = siteSettings?.getConditionTypes() || [];
    } else if (target === 'targets') {
        conditionTypes = siteSettings?.getTargetTypes() || [];
    }

    // Find the condition type and attribute to get supported operators
    if (conditionTypes.length > 0) {
        const selectedConditionType = conditionTypes.find(condType => condType.key === type);
        if (selectedConditionType) {
            const selectedAttribute = selectedConditionType.getAttributes().find(attr => attr.key === attribute);
            if (selectedAttribute) {
                supportedOperators = selectedAttribute.supportedOperators || [];
            }
        }
    }

    // Memoize country options to prevent recalculation on each render
    const countryOptions = useMemo(() => {
        const countries = siteSettings?.getAllCountries() || [];
        return countries.map(country => ({
            value: country.id,
            label: country.name,
        }));
    }, [siteSettings]);

    // Preselect countries based on `value`
    useEffect(() => {
        if (value) {
            let countryIds = [];
            try {
                countryIds = JSON.parse(value); // Parse the JSON string
            } catch (error) {
                console.error('Invalid JSON format for country IDs:', error);
                countryIds = [];
            }

            // Filter the selected countries from the available options
            const preSelectedCountries = countryOptions.filter(option => countryIds.includes(option.value));
            setSelectedCountries(preSelectedCountries);
        } else {
            setSelectedCountries([]);
        }
    }, [value]);

    // Handle the selection change
    const handleSelectChange = (selected) => {
        const selectedValues = selected ? selected.map(option => option.value) : [];
        setSelectedCountries(selected); // Update local state
        onChange('value', JSON.stringify(selectedValues)); // Update the parent component with JSON string
    };

    return (
        <FormGroup>
            {/* Operator Selection */}
            <Label for="operator">Operator</Label>
            <Input
                type="select"
                id="operator"
                value={operator || ''}
                onChange={(e) => onChange('operator', e.target.value)}
            >
                <option value="">Select Operator</option>
                {supportedOperators.map(op => (
                    <option key={op} value={op}>{op}</option>
                ))}
            </Input>

            {/* Country Selection */}
            <Label className="mt-2" for="value">Country</Label>
            <Select
                id="value"
                isMulti
                options={countryOptions}
                value={selectedCountries} // Ensure this value is controlled by state
                onChange={handleSelectChange}
                placeholder="Select countries"
                className="basic-multi-select"
                classNamePrefix="select"
                noOptionsMessage={() => "No countries available"}
            />
        </FormGroup>
    );
};

export default UserCountryComponent;
