import React, { useContext } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { SettingsContext } from "../../../SettingsContext";
import VenueSelectorComponent from "./components/VenueSelectorComponent";
import ManufacturerSelectorComponent from "./components/ManufacturerSelectorComponent";
import ItemSelectorComponent from "./components/ItemSelectorComponent";
import UserCountryComponent from "./components/UserCountryComponent";
import UserGenderComponent from "./components/UserGenderComponent";
import UserAgeComponent from "./components/UserAgeComponent";

const ConditionComponent = ({ condition, onChange }) => {
    const { siteSettings } = useContext(SettingsContext);
    const conditionTypes = siteSettings?.getConditionTypes() || [];

    // Find the selected condition type and attribute based on IDs
    const selectedType = conditionTypes.find(type => type.id === condition.typeId);
    const selectedAttribute = selectedType?.attributes.find(attr => attr.id === condition.attributeId);

    // Handle type change
    const handleTypeChange = (event) => {
        const newTypeId = event.target.value;
        onChange('typeId', newTypeId);
        onChange('attributeId', '');
        onChange('value', '');
        onChange('operator', '');
    };

    // Handle attribute change
    const handleAttributeChange = (event) => {
        const newAttributeId = event.target.value;
        onChange('attributeId', newAttributeId);
        onChange('value', '');
        onChange('operator', '');
    };

    return (
        <FormGroup className="mb-2 border p-3">
            <Label for="conditionType">Condition Type</Label>
            <Input
                type="select"
                id="conditionType"
                value={condition.typeId || ''}
                onChange={handleTypeChange}
            >
                <option value="">Select Condition Type</option>
                {conditionTypes.map(type => (
                    <option key={type.id} value={type.id}>
                        {type.name}
                    </option>
                ))}
            </Input>

            {selectedType && (
                <>
                    <Label className="mt-2" for="conditionAttribute">Condition Attribute</Label>
                    <Input
                        type="select"
                        id="conditionAttribute"
                        value={condition.attributeId || ''}
                        onChange={handleAttributeChange}
                    >
                        <option value="">Select Attribute</option>
                        {selectedType.getAttributes().map(attr => (
                            <option key={attr.id} value={attr.id}>
                                {attr.label}
                            </option>
                        ))}
                    </Input>
                </>
            )}

            {selectedAttribute && (
                <div className="mt-3">
                    {/* Only render the specific component, and do not duplicate the operator and value */}
                    {(() => {
                        switch (selectedAttribute.key) {
                            case 'age':
                                return (
                                    <UserAgeComponent
                                        value={condition.value}
                                        operator={condition.operator}
                                        target="conditions"
                                        type={selectedType.key}
                                        attribute={selectedAttribute.key}
                                        onChange={onChange}
                                    />
                                );
                            case 'gender':
                                return (
                                    <UserGenderComponent
                                        value={condition.value}
                                        operator={condition.operator}
                                        target="conditions"
                                        type={selectedType.key}
                                        attribute={selectedAttribute.key}
                                        onChange={onChange}
                                    />
                                );
                            case 'country':
                                return (
                                    <UserCountryComponent
                                        value={condition.value}
                                        operator={condition.operator}
                                        target="conditions"
                                        type={selectedType.key}
                                        attribute={selectedAttribute.key}
                                        onChange={onChange}
                                    />
                                );
                            case 'item_id':
                                return (
                                    <ItemSelectorComponent
                                        value={condition.value}
                                        operator={condition.operator}
                                        target="conditions"
                                        type={selectedType.key}
                                        attribute={selectedAttribute.key}
                                        onChange={onChange}
                                    />
                                );
                            case 'manufacturer_id':
                                return (
                                    <ManufacturerSelectorComponent
                                        value={condition.value}
                                        operator={condition.operator}
                                        target="conditions"
                                        type={selectedType.key}
                                        attribute={selectedAttribute.key}
                                        onChange={onChange}
                                    />
                                );
                            case 'venue_id':
                                return (
                                    <VenueSelectorComponent
                                        value={condition.value}
                                        operator={condition.operator}
                                        target="conditions"
                                        type={selectedType.key}
                                        attribute={selectedAttribute.key}
                                        onChange={onChange}
                                    />
                                );
                            default:
                                return null;
                        }
                    })()}
                </div>
            )}
        </FormGroup>
    );
};

export default ConditionComponent;
