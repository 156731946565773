import React, { useContext, useEffect } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { SettingsContext } from "../../../SettingsContext";
import MultiplierComponent from "./components/MultiplierComponent";
import FreeItemSelectorComponent from "./components/FreeItemSelectorComponent";
import DiscountPercentageComponent from "./components/DiscountPercentageComponent";
import BundleItemsComponent from "./components/BundleItemsComponent";

const IncentiveComponent = ({ incentive, onChange }) => {
    const { siteSettings } = useContext(SettingsContext);
    const incentiveTypes = siteSettings?.getIncentiveTypes() || [];

    // Find the selected incentive type based on the `typeId`
    const selectedType = incentiveTypes.find(type => type.id === incentive.typeId);
    // This line should now correctly reference incentive.attributeId
    const selectedAttribute = selectedType?.attributes.find(attr => attr.id === incentive.attributeId);

    // If the selectedAttribute exists, the supportedOperators should be correctly populated
    const supportedOperators = selectedAttribute ? selectedAttribute.supportedOperators : [];

    // Handle type change
    const handleTypeChange = (event) => {
        const newTypeId = event.target.value;
        onChange('typeId', newTypeId);
        onChange('attributeId', ''); // Reset attribute to match `attributeId` key
        onChange('value', ''); // Reset value
        onChange('operator', ''); // Reset operator if necessary
    };

    // Handle attribute change
    const handleAttributeChange = (event) => {
        const newAttributeId = event.target.value;
        onChange('attributeId', newAttributeId); // Ensure correct field name here
        onChange('value', ''); // Reset value when attribute changes
    };

    return (
        <FormGroup className="mb-2 border p-3">
            <Label for="incentiveType">Incentive Type</Label>
            <Input
                type="select"
                id="incentiveType"
                value={incentive.typeId || ''}
                onChange={handleTypeChange}
            >
                <option value="">Select Incentive Type</option>
                {incentiveTypes.map(type => (
                    <option key={type.id} value={type.id}>
                        {type.name}
                    </option>
                ))}
            </Input>

            {selectedType && (
                <>
                    <Label className="mt-2" for="incentiveAttribute">Incentive Attribute</Label>
                    <Input
                        type="select"
                        id="incentiveAttribute"
                        value={incentive.attributeId || ''} // Make sure to use `attributeId` here
                        onChange={handleAttributeChange}
                    >
                        <option value="">Select Attribute</option>
                        {selectedType.attributes.map(attr => (
                            <option key={attr.id} value={attr.id}>
                                {attr.name}
                            </option>
                        ))}
                    </Input>
                </>
            )}

            {selectedType && selectedAttribute && (
                <div className="mt-3">
                    {/* Render the specific component based on the selected attribute */}
                    {(() => {
                        switch (selectedAttribute.key) {
                            case 'multiplier':
                                return (
                                    <MultiplierComponent
                                        value={incentive.value}
                                        operator={incentive.operator}
                                        onChange={onChange}
                                    />
                                );
                            case 'free_item_id':
                                return (
                                    <FreeItemSelectorComponent
                                        value={incentive.value}
                                        operator={incentive.operator}
                                        target="incentives"
                                        type={selectedType.key}
                                        attribute={selectedAttribute.key}
                                        onChange={onChange}
                                    />
                                );
                            case 'discount_percentage':
                                return (
                                    <DiscountPercentageComponent
                                        value={incentive.value}
                                        operator={incentive.operator}
                                        onChange={onChange}
                                    />
                                );
                            case 'bundle_items':
                                return (
                                    <BundleItemsComponent
                                        value={incentive.value}
                                        operator={incentive.operator}
                                        target="incentives"
                                        type={selectedType.key}
                                        attribute={selectedAttribute.key}
                                        onChange={onChange}
                                    />
                                );
                            default:
                                return null;
                        }
                    })()}
                </div>
            )}
        </FormGroup>
    );
};

export default IncentiveComponent;

