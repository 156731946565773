import React from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import App from './App';

const AppWithRouter = () => {
    const navigate = useNavigate();
    return <App navigate={navigate} />;
};

const Root = () => (
    <Router>
        <AppWithRouter />
    </Router>
);

export default Root;
