import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Spinner, Button, Input, Row, Col } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import { fetchCampaigns } from '../../../../services/Http/Api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QuestionModal from '../../../global/QuestionModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const CampaignList = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(); // Initialize translation hook
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [filters, setFilters] = useState({ searchTerm: '' });
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [showQuestionModal, setShowQuestionModal] = useState(false); // State for question modal
    const [showTutorialModal, setShowTutorialModal] = useState(false); // State for tutorial modal

    const columns = [
        { name: t('Campaigns.name'), selector: row => row.name || "N/A", sortable: true },
        { name: t('Campaigns.description'), selector: row => row.description || "N/A", sortable: true },
        { name: t('Campaigns.startDate'), selector: row => row.start_date || "N/A", sortable: true },
        { name: t('Campaigns.endDate'), selector: row => row.end_date || "N/A", sortable: true },
        { name: t('Campaigns.goalDescription'), selector: row => row.goal_description || "N/A", sortable: true },
        { name: t('Campaigns.budget'), selector: row => row.budget || "N/A", sortable: true },
        {
            name: t('Campaigns.actions'),
            cell: row => (
                <>
                    <Button color="primary" size="sm" onClick={() => handleEdit(row.id)}>{t('Campaigns.edit')}</Button>{' '}
                    <Button color="danger" size="sm" onClick={() => handleDelete(row.id)}>{t('Campaigns.delete')}</Button>
                </>
            ),
        },
    ];

    const fetchData = async (page, size = perPage, appliedFilters = filters) => {
        setLoading(true);
        try {
            const response = await fetchCampaigns({
                page,
                perPage: size,
                sortBy: sortField,
                sortDirection,
                search: appliedFilters.searchTerm,
            });

            const { data, total } = response.data;
            setCampaigns(data);
            setTotalRows(total);
        } catch (error) {
            console.error("Error fetching campaigns:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        setFilters({ searchTerm: e.target.value });
        setCurrentPage(1);
        fetchData(1, perPage, { searchTerm: e.target.value });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchData(page);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setSortField(column.selector);
        setSortDirection(sortDirection);
        fetchData(currentPage);
    };

    const handleEdit = (id) => {
        navigate(`/campaigns/edit/${id}`);
    };

    const handleDelete = async (id) => {
        // Handle campaign deletion logic
    };

    const handleAddNewCampaign = () => {
        navigate('/campaigns/create');
    };

    useEffect(() => {
        fetchData(currentPage, perPage);
    }, [currentPage, perPage]);

    return (
        <div>
            <Row className="mb-3">
                <Col md={6}>
                    <Input
                        type="text"
                        placeholder={t('Campaigns.search')}
                        value={filters.searchTerm}
                        onChange={handleSearchChange}
                    />
                </Col>
                <Col md={6} className="text-end">
                    <Button color="success" onClick={handleAddNewCampaign}>{t('Campaigns.addNew')}</Button>
                </Col>
            </Row>

            <div className="d-flex justify-content-end mb-3">
                <Button color="link" onClick={() => setShowQuestionModal(true)}>
                    <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '1.5rem', color: 'rgba(130, 22, 146, 1)' }} />
                </Button>
                <Button color="link" onClick={() => setShowTutorialModal(true)}>
                    <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '1.5rem', color: 'rgba(130, 22, 146, 1)' }} />
                </Button>
            </div>

            <DataTable
                columns={columns}
                data={campaigns}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                onSort={handleSort}
                sortServer
                highlightOnHover
                progressPending={loading}
                progressComponent={<Spinner />}
            />

            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={() => setShowQuestionModal(false)}
                param="campaigns_overview" // Pass the parameter for the question part
            />

            {/* Tutorial Modal */}
            <Modal show={showTutorialModal} onHide={() => setShowTutorialModal(false)} size="xl" centered>
                <div className="modal-header">
                    <h5 className="modal-title">{t('Campaigns.tutorialTitle')}</h5>
                    <button type="button" className="close" onClick={() => setShowTutorialModal(false)}>
                        <span>&times;</span>
                    </button>
                </div>
                <Modal.Body>
                    <div style={{ height: 'auto' }}>
                        <div className="welcome-message" style={{ marginBottom: '1.5rem' }}>
                            <p style={{ fontSize: '1.5rem', lineHeight: '1.6', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Campaigns.Tutorial.welcomeMessage')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Campaigns.Tutorial.createCampaignTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Campaigns.Tutorial.createCampaignMessage')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Campaigns.Tutorial.manageCampaignsTitle')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Campaigns.Tutorial.manageCampaignsMessage')}
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default CampaignList;
