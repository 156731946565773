import React, {useEffect} from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const PromotionSettings = ({ promotion, onChange }) => {
    // Function to handle changes and pass them to parent
    const handleInputChange = (field, value) => {
        onChange(field, value);
    };

    const daysOfWeek = [
        { label: 'Sunday', value: 'Sunday' },
        { label: 'Monday', value: 'Monday' },
        { label: 'Tuesday', value: 'Tuesday' },
        { label: 'Wednesday', value: 'Wednesday' },
        { label: 'Thursday', value: 'Thursday' },
        { label: 'Friday', value: 'Friday' },
        { label: 'Saturday', value: 'Saturday' }
    ];

    // Check if every day is selected
    const isEveryday = promotion.daysOfWeek && promotion.daysOfWeek.length === 7;

    // Handle day selection changes
    const handleDayChange = (day, isChecked) => {
        let updatedDaysOfWeek = [...(promotion.daysOfWeek || [])];

        if (day === 'Everyday') {
            updatedDaysOfWeek = isChecked ? daysOfWeek.map(d => d.value) : [];
        } else {
            if (isChecked) {
                updatedDaysOfWeek.push(day);
            } else {
                updatedDaysOfWeek = updatedDaysOfWeek.filter(d => d !== day);
            }
        }

        handleInputChange('daysOfWeek', updatedDaysOfWeek);
    };

    return (
        <div className="mb-3 p-3 border">
            <h5>General Settings</h5>

            {/* Promotion Name Input */}
            <FormGroup>
                <Label for="promotionName">Promotion Name</Label>
                <Input
                    type="text"
                    id="promotionName"
                    value={promotion.name || ''}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    placeholder="Enter promotion name"
                />
            </FormGroup>

            {/* Description Input */}
            <FormGroup>
                <Label for="description">Description</Label>
                <Input
                    type="textarea"
                    id="description"
                    value={promotion.description || ''}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                    placeholder="Enter promotion description"
                />
            </FormGroup>

            {/* Days of the Week Selection */}
            <FormGroup tag="fieldset">
                <Label>Days of the Week</Label>
                <div className="d-flex flex-wrap align-items-center mb-2">
                    <FormGroup check className="me-3">
                        <Label check>
                            <Input
                                type="checkbox"
                                checked={isEveryday}
                                onChange={(e) => handleDayChange('Everyday', e.target.checked)}
                            />
                            Everyday
                        </Label>
                    </FormGroup>

                    {daysOfWeek.map((day, index) => (
                        <FormGroup check key={index} className="me-3">
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={promotion.daysOfWeek?.includes(day.value)}
                                    onChange={(e) => handleDayChange(day.value, e.target.checked)}
                                />
                                {day.label}
                            </Label>
                        </FormGroup>
                    ))}
                </div>
            </FormGroup>

            {/* Start Time Input */}
            <FormGroup>
                <Label for="startTime">Start Time</Label>
                <Input
                    type="time"
                    id="startTime"
                    value={promotion.startTime || ''}
                    onChange={(e) => handleInputChange('startTime', e.target.value)}
                />
            </FormGroup>

            {/* End Time Input */}
            <FormGroup>
                <Label for="endTime">End Time</Label>
                <Input
                    type="time"
                    id="endTime"
                    value={promotion.endTime || ''}
                    onChange={(e) => handleInputChange('endTime', e.target.value)}
                />
            </FormGroup>

            {/* Discount Input */}
            <FormGroup>
                <Label for="discount">Discount</Label>
                <Input
                    type="number"
                    id="discount"
                    value={promotion.discount || ''}
                    onChange={(e) => handleInputChange('discount', e.target.value)}
                    placeholder="Enter discount percentage"
                />
            </FormGroup>
        </div>
    );
};

export default PromotionSettings;
