import React, { useEffect, useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { fetchAllVenues } from '../../../../services/Http/Api';
import { FlagIcon } from "react-flag-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

const VenuesList = () => {
    const { t } = useTranslation();
    const [venues, setVenues] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchAllVenues();
                setVenues(response.data);
            } catch (error) {
                console.error('Error fetching venues:', error);
            }
        };

        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setResetPaginationToggle(!resetPaginationToggle);
    };

    const handleAddNew = () => {
        navigate('/venues/create');
    };

    const handleEditVenue = (id) => {
        navigate(`/venues/view/${id}`);
    };

    const handleDeleteVenue = (id) => {
        console.log("Deleting");
    };

    const filteredVenues = venues.filter((venue) =>
        venue.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        venue.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const columns = useMemo(
        () => [
            { name: t('Venues.columns.id'), selector: (row) => row.id, sortable: true },
            { name: t('Venues.columns.name'), selector: (row) => row.name, sortable: true },
            { name: t('Venues.columns.email'), selector: (row) => row.email, sortable: true },
            { name: t('Venues.columns.phone'), selector: (row) => '+' + row.phone_country.phonecode + row.phone, sortable: true },
            {
                name: t('Venues.columns.country'),
                selector: (row) => (
                    <div className="d-flex align-items-center">
                        <FlagIcon code={row.country.iso} size={24} />
                        <span className="ms-2">{row.country.name}</span>
                    </div>
                ),
                sortable: true
            },
            { name: t('Venues.columns.address'), selector: (row) => row.address, sortable: true },
            {
                name: t('Venues.columns.action'),
                cell: (row) => (
                    <>
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="text-primary cursor-pointer"
                            style={{ marginRight: '8px', cursor: 'pointer' }}
                            onClick={() => handleEditVenue(row.id)}
                        />
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="text-danger cursor-pointer"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteVenue(row.id)}
                        />
                    </>
                ),
                grow: 0,
                minWidth: '80px',
                style: {
                    position: 'sticky',
                    right: '0px',
                    backgroundColor: 'white',
                },
            },
        ],
        [t]
    );

    return (
        <div className="mt-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                    <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder={t('Venues.search_placeholder')}
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
                <button onClick={handleAddNew} className="btn btn-success">
                    {t('Venues.add_new_button')}
                </button>
            </div>

            <div>
                <DataTable
                    columns={columns}
                    data={filteredVenues}
                    pagination
                    persistTableHead
                    highlightOnHover
                    resetPaginationToggle={resetPaginationToggle}
                    noHeader
                    onPageChange={(page) => setPage(page)}
                    onChangeRowsPerPage={(newPerPage, newPage) => {
                        setPerPage(newPerPage);
                        setPage(newPage);
                    }}
                />
            </div>
        </div>
    );
};

export default VenuesList;
