import React from 'react';
import { FlagIcon } from 'react-flag-kit';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const SalesByCountries = ({ data }) => {
    const { t } = useTranslation(); // Initialize translation hook

    return (
        <div className="card h-100">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="mb-0">{t('Users.UserStatistics.salesByCountry.title')}</h5> {/* Translated title */}
            </div>
            <div className="card-body">
                <ul className="list-unstyled">
                    {data.map((countryData, index) => (
                        <li key={index} className="d-flex align-items-center py-3 border-bottom">
                            <div className="me-3">
                                <FlagIcon code={countryData.country.iso} size={48} className="rounded" />
                            </div>
                            <div className="d-flex w-100 justify-content-between align-items-center">
                                <div className="me-3">
                                    <h6 className="mb-0">${parseFloat(countryData.sale_count).toLocaleString()}k</h6>
                                    <small className="text-muted">
                                        {t('Users.UserStatistics.salesByCountry.userCount', { userCount: countryData.user_count })}
                                    </small>
                                </div>
                                <div className="text-end">
                                    <span className="badge bg-primary text-white fw-bold">
                                        ${parseFloat(countryData.total_sales).toLocaleString()}
                                    </span>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SalesByCountries;
