// models/Condition.js
class Condition {
    constructor(data = {}) {
        this.id = data.id || null;
        this.promotionId = data.promotion_id || null; // Added field to capture promotion ID
        this.typeId = data.type_id || ''; // Adjusted to match backend data
        this.attributeId = data.attribute_id || ''; // Adjusted to match backend data
        this.operator = data.operator || '';
        this.value = data.value || '';
    }

    toBackendFormat() {
        return {
            id: this.id,
            promotion_id: this.promotionId,
            type_id: this.typeId,
            attribute_id: this.attributeId,
            operator: this.operator,
            value: this.value,
        };
    }
}

export default Condition;
