import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Modal } from 'reactstrap';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom'; // Use useNavigate for navigation
import ItemsOverview from './ItemsOverview';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'; // Import icons
import QuestionModal from '../../../global/QuestionModal'; // Import your QuestionModal

const Items = () => {
    const { t } = useTranslation(); // Initialize translation hook
    const [activeTab, setActiveTab] = useState('1');
    const [showQuestionModal, setShowQuestionModal] = useState(false); // State for the question modal
    const [showTutorialModal, setShowTutorialModal] = useState(false); // State for the tutorial modal
    const navigate = useNavigate(); // Initialize the navigation function

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleShowQuestionModal = () => setShowQuestionModal(true);
    const handleCloseQuestionModal = () => setShowQuestionModal(false);

    const handleShowTutorialModal = () => setShowTutorialModal(true);
    const handleCloseTutorialModal = () => setShowTutorialModal(false);

    return (
        <div className="items-page">
            <div className="d-flex justify-content-end mb-3">
                <button
                    className="btn btn-link"
                    onClick={handleShowQuestionModal}
                    style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)', marginLeft: '0.5rem' }}
                >
                    <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '1.5rem' }} />
                </button>
                <button
                    className="btn btn-link"
                    onClick={handleShowTutorialModal}
                    style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)', marginLeft: '0.5rem' }}
                >
                    <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '1.5rem' }} />
                </button>
            </div>

            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => toggle('1')}
                    >
                        {t('Items.overview')}
                    </NavLink>
                </NavItem>
                {/*
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => toggle('2')}
                    >
                        {t('Items.performance_insights')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => toggle('3')}
                    >
                        {t('Items.warranty_alerts')}
                    </NavLink>
                </NavItem>
                */}
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <ItemsOverview />
                </TabPane>
            </TabContent>

            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={handleCloseQuestionModal}
                param="items" // Pass the parameter for the question part
            />

            {/* Tutorial Modal */}
            <Modal show={showTutorialModal} onHide={handleCloseTutorialModal} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Items.Tutorial.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: 'auto' }}>
                        {/* Add tutorial content here */}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Items;
