import React, { useState, useEffect } from 'react';
import { fetchVenueIntegration, generateVenueSecret } from "../../../../services/Http/Api";
import { useTranslation } from 'react-i18next';

const VenueIntegration = ({ venueId }) => {
    const { t } = useTranslation();
    const [apiKey, setApiKey] = useState('');
    const [apiStatus, setApiStatus] = useState(false);
    const [apiSecret, setApiSecret] = useState(null); // Store the generated secret here
    const [secretTimer, setSecretTimer] = useState(60); // For the countdown timer

    // Fetch current API Key and status on component mount
    useEffect(() => {
        const loadVenueIntegration = async () => {
            try {
                const response = await fetchVenueIntegration(venueId);
                setApiKey(response.api_key || '');  // If no API key, show empty
                setApiStatus(response.is_api_active);  // Set active/inactive status
            } catch (error) {
                console.error('Failed to load venue integration details:', error);
            }
        };

        loadVenueIntegration();
    }, [venueId]);

    // Function to generate a new API secret and possibly API key
    const handleGenerateSecret = async () => {
        try {
            const response = await generateVenueSecret(venueId);
            setApiKey(response.api_key);  // Update API key if a new one is generated
            setApiSecret(response.api_secret);  // Show the generated secret

            // Start countdown for 60 seconds to remove secret from view
            setSecretTimer(60);
            const countdown = setInterval(() => {
                setSecretTimer(prevTimer => {
                    if (prevTimer <= 1) {
                        clearInterval(countdown);
                        setApiSecret(null);  // Remove secret after 60 seconds
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        } catch (error) {
            console.error('Failed to generate API secret:', error);
        }
    };

    return (
        <div>
            <h3>{t('Venues.VenueView.VenueIntegration.api_integration')}</h3>

            {/* Display API Key */}
            <div className="mb-3">
                <label htmlFor="apiKeyInput" className="form-label">{t('Venues.VenueView.VenueIntegration.api_key')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="apiKeyInput"
                    value={apiKey}
                    readOnly
                />
            </div>

            {/* Button to generate API Secret */}
            <button className="btn btn-primary" onClick={handleGenerateSecret}>
                {t('Venues.VenueView.VenueIntegration.generate_secret')}
            </button>

            {/* Display API Secret with a countdown timer */}
            {apiSecret && (
                <div className="mt-3">
                    <div className="alert alert-success">
                        <strong>{t('Venues.VenueView.VenueIntegration.generated_secret', { secret: apiSecret })}</strong>
                    </div>
                    <p>{t('Venues.VenueView.VenueIntegration.secret_timer', { seconds: secretTimer })}</p>
                </div>
            )}
        </div>
    );
};

export default VenueIntegration;
