import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Modal, Button } from 'react-bootstrap';
import { fetchUsersByOrganization } from '../../../../services/Http/Api';
import UserCreate from "./components/UserCreate";
import { useTranslation } from 'react-i18next';
import QuestionModal from "../../../global/QuestionModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons"; // Import useTranslation

const UsersList = () => {
    const { t } = useTranslation(); // Initialize translation hook
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showUserModal, setShowUserModal] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState('id'); // default sort column
    const [sortDirection, setSortDirection] = useState('asc'); // default sort direction
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const handleShowQuestionModal = () => setShowQuestionModal(true);
    const handleCloseQuestionModal = () => setShowQuestionModal(false);

    // Fetch users with pagination support
    const loadUsers = async (page) => {
        setLoading(true);
        try {
            const { users, total, currentPage } = await fetchUsersByOrganization(page, perPage, sortBy, sortDirection);
            setUsers(users);
            setTotalRows(total);
            setCurrentPage(currentPage);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadUsers(currentPage);
    }, [currentPage, perPage, sortBy, sortDirection]);

    const handleUserModalShow = () => {
        setShowUserModal(true);
    };

    const handleUserModalClose = () => setShowUserModal(false);

    const handleUserAdded = () => {
        loadUsers(currentPage); // Reload the user list after a new user is added
        handleUserModalClose(); // Close the modal
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        loadUsers(page);
    };

    // Handle sorting change
    const handleSort = (column, sortDirection) => {
        setSortBy(column.key); // Set the column to sort by
        setSortDirection(sortDirection); // Set the sort direction
    };

    const columns = [
        { name: t('Users.UsersList.columns.userId'), key: 'user_id', selector: row => row.user_id, sortable: true },
        { name: t('Users.UsersList.columns.name'), key: 'user_name', selector: row => row.user_name, sortable: true },
        {
            name: t('Users.UsersList.columns.totalSpent'),
            key: 'total_spent',
            selector: row => parseFloat(row.total_spent), // Sorting based on numeric value
            sortable: true,
            cell: row => `$${parseFloat(row.total_spent).toLocaleString()}` // Display formatted value
        },
        {
            name: t('Users.UsersList.columns.firstTransactionDate'),
            key: 'first_transaction_date',
            selector: row => new Date(row.first_transaction_date), // Sorting based on Date object
            sortable: true,
            cell: row => row.first_transaction_date ? new Date(row.first_transaction_date).toLocaleDateString() : "N/A" // Display formatted value
        },
        {
            name: t('Users.UsersList.columns.lastTransactionDate'),
            key: 'last_transaction_date',
            selector: row => new Date(row.last_transaction_date), // Sorting based on Date object
            sortable: true,
            cell: row => row.last_transaction_date ? new Date(row.last_transaction_date).toLocaleDateString() : "N/A" // Display formatted value
        },
        { name: t('Users.UsersList.columns.countOfItems'), key: 'count_of_items', selector: row => row.count_of_items, sortable: true },
        { name: t('Users.UsersList.columns.venuesCount'), key: 'venues_count', selector: row => row.venues_statistics.length, sortable: false },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.own_buyer,
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.2)',
            },
        },
        {
            when: row => !row.own_buyer,
            style: {
                backgroundColor: 'rgba(248, 215, 218, 0.2)',
            },
        },
    ];

    return (
        <div className="mt-3">
            <Button variant="primary" onClick={handleUserModalShow}>
                {t('Users.UsersList.addUserButton')} {/* Use translation for button */}
            </Button>
            <div className="legend mt-3 mb-3">
                <button className="btn btn-link" onClick={handleShowQuestionModal} style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)', marginLeft: '0.5rem' }}>
                    <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '1.5rem' }} />
                </button>
                <h5>{t('Users.UsersList.legend')}</h5> {/* Use translation for legend */}
                <ul className="list-unstyled">
                    <li>
                        <span
                            style={{
                                display: 'inline-block',
                                width: '20px',
                                height: '20px',
                                backgroundColor: 'rgba(63, 195, 128, 0.2)',
                                border: '1px solid #3fc380',
                                marginRight: '10px',
                            }}
                        ></span>
                        <span>{t('Users.UsersList.ownUser')}</span> {/* Use translation for own user */}
                    </li>
                    <li>
                        <span
                            style={{
                                display: 'inline-block',
                                width: '20px',
                                height: '20px',
                                backgroundColor: 'rgba(248, 215, 218, 0.2)',
                                border: '1px solid #f8d7da',
                                marginRight: '10px',
                            }}
                        ></span>
                        <span>{t('Users.UsersList.globalUser')}</span> {/* Use translation for global user */}
                    </li>
                </ul>
            </div>
            <DataTable
                columns={columns}
                data={users}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={loading}
                highlightOnHover
                conditionalRowStyles={conditionalRowStyles}
                onSort={handleSort} // Add this line to handle sorting
                sortServer // Indicate that sorting is handled on the server
            />

            {/* User Modal */}
            <Modal show={showUserModal} onHide={handleUserModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Users.UsersList.modalTitle')}</Modal.Title> {/* Use translation for modal title */}
                </Modal.Header>
                <Modal.Body>
                    <UserCreate
                        onClose={handleUserModalClose}
                        onUserAdded={handleUserAdded} // Pass the callback for when a user is added
                    />
                </Modal.Body>
            </Modal>
            <QuestionModal
                show={showQuestionModal}
                onClose={handleCloseQuestionModal}
                param="users_timeline" // Pass the parameter for the question part
            />
        </div>
    );
};

export default UsersList;
