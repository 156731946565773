// models/SiteConstants/Promotion.js

class Promotions {
    constructor({ conditions = [], incentives = [], targets = [],strategies = [] } = {}) {
        // Map the raw data to structured objects
        this.conditionTypes = conditions.map(condition => new ConditionType(condition));
        this.incentiveTypes = incentives.map(incentive => new IncentiveType(incentive));
        this.targetTypes = targets.map(target => new TargetType(target));
        this.strategyTypes = strategies.map(target => new StrategyType(target));
    }

    // Method to get all condition types
    getConditionTypes() {
        return this.conditionTypes;
    }

    // Method to get all incentive types
    getIncentiveTypes() {
        return this.incentiveTypes;
    }

    // Method to get all target types
    getTargetTypes() {
        return this.targetTypes;
    }

    getStrategyTypes() {
        return this.strategyTypes;
    }

    // Method to find a specific condition type by key
    getConditionTypeByKey(key) {
        return this.conditionTypes.find(conditionType => conditionType.key === key);
    }

    // Method to find a specific incentive type by key
    getIncentiveTypeByKey(key) {
        return this.incentiveTypes.find(incentiveType => incentiveType.key === key);
    }

    // Method to find a specific target type by key
    getTargetTypeByKey(key) {
        return this.targetTypes.find(targetType => targetType.key === key);
    }
}

// Class representing Condition Type and its attributes
class ConditionType {
    constructor({ id, name, key, target_model, attributes = [] }) {
        this.id = id;
        this.name = name;
        this.key = key;
        this.targetModel = target_model;
        this.attributes = attributes.map(attr => new ConditionAttribute(attr)); // Initialize ConditionAttributes
    }

    // Method to get all attributes for this condition type
    getAttributes() {
        return this.attributes;
    }
}

// Class representing Condition Attribute
class ConditionAttribute {
    constructor({ id, key, name, data_type, supported_operators = [], condition_functionality = null }) {
        this.id = id;
        this.key = key;
        this.label = name;
        this.type = data_type;
        this.supportedOperators = supported_operators;
        this.conditionFunctionality = condition_functionality;
    }
}

// Class representing Incentive Type and its attributes
class IncentiveType {
    constructor({ id, name, key, target_model, attributes = [] }) {
        this.id = id;
        this.name = name;
        this.key = key;
        this.targetModel = target_model;
        this.attributes = attributes.map(attr => new IncentiveAttribute(attr));
    }

    // Method to get all attributes for this incentive type
    getAttributes() {
        return this.attributes;
    }
}

// Class representing Incentive Attribute
class IncentiveAttribute {
    constructor({ id, name, key, data_type, supported_operators = [], is_required = true }) {
        this.id = id;
        this.name = name;
        this.key = key;
        this.dataType = data_type;
        this.supportedOperators = supported_operators;
        this.isRequired = is_required;
    }
}

// Class representing Target Type and its attributes
class TargetType {
    constructor({ id, name, key, target_model, attributes = [] }) {
        this.id = id;
        this.name = name;
        this.key = key;
        this.targetModel = target_model;
        this.attributes = attributes.map(attr => new TargetAttribute(attr));
    }

    // Method to get all attributes for this target type
    getAttributes() {
        return this.attributes;
    }
}

// Class representing Target Attribute
class TargetAttribute {
    constructor({ id, name, key, data_type, supported_operators = [], is_required = true }) {
        this.id = id;
        this.name = name;
        this.key = key;
        this.dataType = data_type;
        this.supportedOperators = supported_operators;
        this.isRequired = is_required;
    }
}
class StrategyType {
    constructor({ id, name, key, attributes = [] }) {
        this.id = id;
        this.name = name;
        this.key = key;
        this.attributes = attributes.map(attr => new StrategyAttribute(attr)); // Initialize StrategyAttributes
    }

    // Method to get all attributes for this strategy type
    getAttributes() {
        return this.attributes;
    }
}

// Class representing Strategy Attribute
class StrategyAttribute {
    constructor({ id, key, name, data_type }) {
        this.id = id;
        this.key = key;
        this.label = name;
        this.dataType = data_type;
    }
}
export default Promotions;
