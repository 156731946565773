import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {postQuestion} from "../../services/Http/Api"; // Ensure axios is imported

const QuestionModal = ({ show, onClose, param }) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [question, setQuestion] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [hasStoredName, setHasStoredName] = useState(false);
    const [hasStoredEmail, setHasStoredEmail] = useState(false);

    useEffect(() => {
        if (show) {
            // Reset form fields and errors when modal is opened
            setErrors({});
            setLoading(false);
            setQuestion('');

        // Check local storage for name and email
        const contactEmail = localStorage.getItem('contactEmail');
        const contactName = localStorage.getItem('contactName');

            if (contactEmail) {
                setEmail(contactEmail);
                setHasStoredEmail(true);
            } else {
                setEmail('');
                setHasStoredEmail(false);
            }

            if (contactName) {
                setName(contactName);
                setHasStoredName(true);
            } else {
                setName('');
                setHasStoredName(false);
            }
        }
    }, [show]);

    const handleSubmit = async () => {
        const newErrors = {};
        if (!question.trim()) newErrors.question = t('QuestionModal.errors.questionRequired');

        // Validate Name and Email if they are not stored
        if (!hasStoredName && !name.trim()) newErrors.name = t('QuestionModal.errors.nameRequired');
        if (!hasStoredEmail && !email.trim()) newErrors.email = t('QuestionModal.errors.emailRequired');

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setLoading(true);
            try {
                await postQuestion(name, email, question, param); // Call postQuestion instead
                // Save Name and Email to localStorage if they were not stored
                if (!hasStoredName) {
                    localStorage.setItem('contactName', name);
                }
                if (!hasStoredEmail) {
                    localStorage.setItem('contactEmail', email);
                }
                onClose(); // Close the modal after saving data
            } catch (error) {
                console.error('Failed to submit question:', error);
                setErrors({ submit: t('QuestionModal.errors.submitFailed') });
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('QuestionModal.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {Object.keys(errors).length > 0 && (
                    <Alert variant="danger">
                        {errors.submit || t('QuestionModal.errors.generic')}
                    </Alert>
                )}
                <Form>
                    {!hasStoredName && (
                        <Form.Group controlId="formName" className="mb-3">
                            <Form.Label>{t('QuestionModal.name')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('QuestionModal.namePlaceholder')}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    {!hasStoredEmail && (
                        <Form.Group controlId="formEmail" className="mb-3">
                            <Form.Label>{t('QuestionModal.email')}</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder={t('QuestionModal.emailPlaceholder')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                isInvalid={!!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    <Form.Group controlId="formQuestion" className="mb-3">
                        <Form.Label>{t('QuestionModal.question')}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder={t('QuestionModal.questionPlaceholder')}
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            isInvalid={!!errors.question}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.question}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {t('QuestionModal.cancel')}
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? t('QuestionModal.saving') : t('QuestionModal.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default QuestionModal;
