import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMapMarkerAlt, faGlobe, faBuilding, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from "react-i18next";

const VenueDetails = ({ venue }) => {
    // Parse social media links if available
    const { t } = useTranslation(); // In
    const socialLinks = venue.social_media_links ? JSON.parse(venue.social_media_links) : {};

    return (
        <div className="card">
            <div className="card-header bg-primary text-white">
                <h3 className="card-title mb-0">{t('Venues.VenueView.VenueDetails.title')}</h3>
            </div>
            <div className="card-body">
                {/* General Venue Information */}
                <div className="row mb-3">
                    <div className="col-md-6 mb-2">
                        <FontAwesomeIcon icon={faBuilding} className="me-2 text-secondary" />
                        <strong>{t('Venues.VenueView.VenueDetails.name_label')}</strong>
                        <p className="text-muted">{venue.name}</p>
                    </div>
                    <div className="col-md-6 mb-2">
                        <FontAwesomeIcon icon={faEnvelope} className="me-2 text-secondary" />
                        <strong>{t('Venues.VenueView.VenueDetails.email_label')}</strong>
                        <p className="text-muted">{venue.email}</p>
                    </div>
                    <div className="col-md-6 mb-2">
                        <strong>{t('Venues.VenueView.VenueDetails.description_label')}</strong>
                        <p className="text-muted">{venue.description}</p>
                    </div>
                    <div className="col-md-6 mb-2">
                        <FontAwesomeIcon icon={faGlobe} className="me-2 text-secondary" />
                        <strong>{t('Venues.VenueView.VenueDetails.website_label')}</strong>
                        <p className="text-muted">
                            <a href={venue.website} target="_blank" rel="noopener noreferrer" className="text-primary">
                                {venue.website}
                            </a>
                        </p>
                    </div>
                    <div className="col-md-6 mb-2">
                        <FontAwesomeIcon icon={faPhoneAlt} className="me-2 text-secondary" />
                        <strong>{t('Venues.VenueView.VenueDetails.phone_label')}</strong>
                        <p className="text-muted">
                            {venue.phone_country?.phonecode && `+${venue.phone_country.phonecode}`} {venue.phone}
                        </p>
                    </div>
                    <div className="col-md-6 mb-2">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2 text-secondary" />
                        <strong>{t('Venues.VenueView.VenueDetails.address_label')}</strong>
                        <p className="text-muted">{venue.address}</p>
                    </div>
                    <div className="col-md-6 mb-2">
                        <strong>{t('Venues.VenueView.VenueDetails.city_label')}</strong>
                        <p className="text-muted">{venue.city}</p>
                    </div>
                    <div className="col-md-6 mb-2">
                        <strong>{t('Venues.VenueView.VenueDetails.country_label')}</strong>
                        <p className="text-muted">{venue.country?.name}</p>
                    </div>
                    <div className="col-md-6 mb-2">
                        <strong>{t('Venues.VenueView.VenueDetails.latitude_label')}</strong>
                        <p className="text-muted">{venue.latitude}</p>
                    </div>
                    <div className="col-md-6 mb-2">
                        <strong>{t('Venues.VenueView.VenueDetails.longitude_label')}</strong>
                        <p className="text-muted">{venue.longitude}</p>
                    </div>
                    <div className="col-md-6 mb-2">
                        <strong>{t('Venues.VenueView.VenueDetails.organization_label')}</strong>
                        <p className="text-muted">{venue.organization?.name}</p>
                    </div>
                </div>

                {/* Social Media Links */}
                <div className="border-top pt-3">
                    <h4 className="card-title mb-3">{t('Venues.VenueView.VenueDetails.social_media')}</h4>
                    <div className="row">
                        {socialLinks.twitter && (
                            <div className="col-md-4 mb-2 d-flex align-items-center">
                                <FontAwesomeIcon icon={faTwitter} className="me-2 text-info" />
                                <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer" className="text-info">
                                    {t('Venues.VenueView.VenueDetails.twitter')}
                                </a>
                            </div>
                        )}
                        {socialLinks.facebook && (
                            <div className="col-md-4 mb-2 d-flex align-items-center">
                                <FontAwesomeIcon icon={faFacebook} className="me-2 text-primary" />
                                <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer" className="text-primary">
                                    {t('Venues.VenueView.VenueDetails.facebook')}
                                </a>
                            </div>
                        )}
                        {socialLinks.instagram && (
                            <div className="col-md-4 mb-2 d-flex align-items-center">
                                <FontAwesomeIcon icon={faInstagram} className="me-2 text-warning" />
                                <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer" className="text-warning">
                                    {t('Venues.VenueView.VenueDetails.instagram')}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VenueDetails;
