class Country {
    constructor({ id, name, iso, iso3, numcode, phonecode }) {
        this.id = id; // public property
        this.name = name; // public property
        this.iso = iso; // ISO 2-character country code
        this.iso3 = iso3; // ISO 3-character country code
        this.numcode = numcode; // numeric country code
        this.phonecode = phonecode; // phone country code
    }
}

export default Country;
