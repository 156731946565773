import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const PhoneNumberInput = ({ countryCodes, phoneValue, phoneCountryValue, onChange }) => {
    const { t } = useTranslation(); // Initialize translation hook
    const [selectedCountryCode, setSelectedCountryCode] = useState(phoneCountryValue || '');
    const [phoneNumber, setPhoneNumber] = useState(phoneValue || '');

    useEffect(() => {
        setSelectedCountryCode(phoneCountryValue || '');
        setPhoneNumber(phoneValue || '');
    }, [phoneValue, phoneCountryValue]);

    const handleCountryCodeChange = (e) => {
        const countryCode = e.target.value;
        setSelectedCountryCode(countryCode);
        onChange({
            phoneCountryCode: countryCode,
            phone: phoneNumber // Send as 'phone'
        });
    };

    const handlePhoneNumberChange = (e) => {
        const number = e.target.value;
        setPhoneNumber(number);
        onChange({
            phoneCountryCode: selectedCountryCode,
            phone: number // Send as 'phone'
        });
    };

    return (
        <div className="input-group">
            <select
                className="form-select"
                value={selectedCountryCode}
                onChange={handleCountryCodeChange}
            >
                <option value="">{t('PhoneInput.selectCountryCode')}</option> {/* Use translation for the placeholder */}
                {countryCodes.map(country => (
                    <option key={country.id} value={country.id}>
                        {`${country.name} (+${country.phonecode})`}
                    </option>
                ))}
            </select>
            <input
                type="tel"
                name="phoneNumber"
                className="form-control phone-number-input"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder={t('PhoneInput.placeholder')} // Use translation for input placeholder
            />
        </div>
    );
};

// Prop validation to ensure correct data types
PhoneNumberInput.propTypes = {
    countryCodes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        name: PropTypes.string.isRequired,
        phonecode: PropTypes.string.isRequired,
    })).isRequired,
    phoneValue: PropTypes.string,
    phoneCountryValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default PhoneNumberInput;
