import React, { useContext } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { SettingsContext } from "../../../SettingsContext";
import UserCountryComponent from "./components/UserCountryComponent";
import UserGenderComponent from "./components/UserGenderComponent";
import UserAgeComponent from "./components/UserAgeComponent";

const TargetComponent = ({ target, onChange }) => {
    const { siteSettings } = useContext(SettingsContext);
    const targetTypes = siteSettings?.getTargetTypes() || [];

    // Find the selected target type and attribute based on IDs
    const selectedType = targetTypes.find(type => type.id === target.typeId);
    const selectedAttribute = selectedType?.attributes.find(attr => attr.id === target.attributeId);

    // Handle type change
    const handleTypeChange = (event) => {
        const newTypeId = event.target.value;
        onChange('typeId', newTypeId);
        onChange('attributeId', '');
        onChange('value', '');
        onChange('operator', '');
    };

    // Handle attribute change
    const handleAttributeChange = (event) => {
        const newAttributeId = event.target.value;
        onChange('attributeId', newAttributeId);
        onChange('value', '');
        onChange('operator', '');
    };

    return (
        <FormGroup className="mb-2 border p-3">
            <Label for="targetType">Target Type</Label>
            <Input
                type="select"
                id="targetType"
                value={target.typeId || ''}
                onChange={handleTypeChange}
            >
                <option value="">Select Target Type</option>
                {targetTypes.map(type => (
                    <option key={type.id} value={type.id}>
                        {type.name}
                    </option>
                ))}
            </Input>

            {selectedType && (
                <>
                    <Label className="mt-2" for="targetAttribute">Target Attribute</Label>
                    <Input
                        type="select"
                        id="targetAttribute"
                        value={target.attributeId || ''}
                        onChange={handleAttributeChange}
                    >
                        <option value="">Select Attribute</option>
                        {selectedType.attributes.map(attr => (
                            <option key={attr.id} value={attr.id}>
                                {attr.name || attr.label}
                            </option>
                        ))}
                    </Input>
                </>
            )}

            {selectedAttribute && (
                <div className="mt-3">
                    {/* Render the appropriate component based on the selected attribute key */}
                    {(() => {
                        switch (selectedAttribute.key) {
                            case 'age':
                                return (
                                    <UserAgeComponent
                                        value={target.value}
                                        operator={target.operator}
                                        target="targets"
                                        type={selectedType.key}
                                        attribute={selectedAttribute.key}
                                        onChange={onChange}
                                    />
                                );
                            case 'gender':
                                return (
                                    <UserGenderComponent
                                        value={target.value}
                                        operator={target.operator}
                                        target="targets"
                                        type={selectedType.key}
                                        attribute={selectedAttribute.key}
                                        onChange={onChange}
                                    />
                                );
                            case 'country':
                                return (
                                    <UserCountryComponent
                                        value={target.value}
                                        operator={target.operator}
                                        target="targets"
                                        type={selectedType.key}
                                        attribute={selectedAttribute.key}
                                        onChange={onChange}
                                    />
                                );
                            default:
                                return null;
                        }
                    })()}
                </div>
            )}
        </FormGroup>
    );
};

export default TargetComponent;
