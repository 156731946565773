import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { fetchUserTimeline } from '../../../../services/Http/Api';
import { useTranslation } from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import QuestionModal from "../../../global/QuestionModal"; // Import useTranslation

const Timeline = () => {
    const { t } = useTranslation(); // Initialize translation hook
    const [chartData, setChartData] = useState({ categories: [], series: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const handleShowQuestionModal = () => setShowQuestionModal(true);
    const handleCloseQuestionModal = () => setShowQuestionModal(false);

    useEffect(() => {
        const loadTimeline = async () => {
            try {
                setLoading(true);
                const data = await fetchUserTimeline(); // Fetch your timeline data from the API

                // Extract categories (months) and series data (user counts)
                const categories = data.map(item => item.registration_month); // ['2023-01', '2023-02', ...]
                const counts = data.map(item => item.user_count); // [50, 30, ...]

                setChartData({
                    categories,
                    series: [
                        {
                            name: t('Users.Timeline.title'), // Use translation for series name
                            data: counts // Set counts directly as the data for series
                        }
                    ]
                });
            } catch (error) {
                setError(t('Users.Timeline.error')); // Use translation for error message
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        loadTimeline();
    }, [t]); // Add t to dependencies

    if (loading) {
        return <div>{t('Users.Timeline.loading')}</div>; // Use translation for loading message
    }

    if (error) {
        return <div className="text-danger">{error}</div>;
    }

    // Chart options
    const chartOptions = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            }
        },
        xaxis: {
            categories: chartData.categories, // Display months on the x-axis
            title: {
                text: t('Users.Timeline.xAxisTitle') // Use translation for x-axis title
            },
            labels: {
                rotate: -45 // Rotate labels if you have many months
            }
        },
        yaxis: {
            title: {
                text: t('Users.Timeline.yAxisTitle') // Use translation for y-axis title
            }
        },
        title: {
            text: t('Users.Timeline.title'), // Use translation for the chart title
            align: 'center'
        }
    };

    return (
        <>    <button className="btn btn-link" onClick={handleShowQuestionModal} style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)', marginLeft: '0.5rem' }}>
            <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '1.5rem' }} />
        </button>
        <ReactApexChart
            options={chartOptions}
            series={chartData.series}
            type="bar"
            height={350}
        />
            <QuestionModal
                show={showQuestionModal}
                onClose={handleCloseQuestionModal}
                param="users_timeline" // Pass the parameter for the question part
            />
        </>
    );
};

export default Timeline;
