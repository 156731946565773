// UserGenderComponent.js
import React, { useContext } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { SettingsContext } from "../../../../SettingsContext";

const UserGenderComponent = ({ value, operator, onChange, target, type, attribute }) => {
    const { siteSettings } = useContext(SettingsContext);

    // Fetch supported operators based on target, type, and attribute
    let supportedOperators = [];
    let conditionTypes = [];

    if (target === 'conditions') {
        conditionTypes = siteSettings?.getConditionTypes() || [];

        // Find the condition type and attribute to get supported operators
        const selectedConditionType = conditionTypes.find(condType => condType.key === type);
        if (selectedConditionType) {
            const selectedAttribute = selectedConditionType.getAttributes().find(attr => attr.key === attribute);
            if (selectedAttribute) {
                supportedOperators = selectedAttribute.supportedOperators || [];
            }
        }
    }

    if (target === 'targets') {
        conditionTypes = siteSettings?.getTargetTypes() || [];

        // Find the condition type and attribute to get supported operators
        const selectedConditionType = conditionTypes.find(condType => condType.key === type);
        if (selectedConditionType) {
            const selectedAttribute = selectedConditionType.getAttributes().find(attr => attr.key === attribute);
            if (selectedAttribute) {
                supportedOperators = selectedAttribute.supportedOperators || [];
            }
        }
    }

    return (
        <FormGroup>
            {/* Operator Selection */}
            <Label for="operator">Operator</Label>
            <Input
                type="select"
                id="operator"
                value={operator || ''}
                onChange={(e) => onChange('operator', e.target.value)}
            >
                <option value="">Select Operator</option>
                {supportedOperators.map(op => (
                    <option key={op} value={op}>{op}</option>
                ))}
            </Input>

            {/* Gender Selection */}
            <Label className="mt-2" for="value">Gender</Label>
            <Input
                type="select"
                id="value"
                value={value || ''}
                onChange={(e) => onChange('value', e.target.value)}
            >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
            </Input>
        </FormGroup>
    );
};

export default UserGenderComponent;
