// models/Promotion.js
import Condition from './Condition';
import Target from './Target';
import Incentive from './Incentive';
import Strategy from "./Strategy";

class Promotion {
    constructor(data = {}) {
        this.id = data.id || null;
        this.name = data.name || '';
        this.description = data.description || '';
        this.startDate = data.start_date || '';
        this.endDate = data.end_date || '';
        this.daysOfWeek = data.days_of_week || [];
        this.startTime = data.start_time || '';
        this.endTime = data.end_time || '';
        this.discount = data.discount || 0;
        this.pointsMultiplier = data.pointsMultiplier || 0;
        this.active = data.active || false;
        this.conditions = (data.conditions || []).map(cond => new Condition(cond));
        this.targets = (data.targets || []).map(target => new Target(target));
        this.incentives = (data.incentives || []).map(incentive => new Incentive(incentive));
        this.strategies = (data.strategies || []).map(strategy => new Strategy(strategy));
    }

    toBackendFormat() {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            start_date: this.startDate,
            end_date: this.endDate,
            days_of_week: this.daysOfWeek,
            start_time: this.startTime,
            end_time: this.endTime,
            discount: this.discount,
            pointsMultiplier: this.pointsMultiplier,
            active: this.active,
            conditions: this.conditions.map(cond => cond.toBackendFormat()),
            targets: this.targets.map(target => target.toBackendFormat()),
            incentives: this.incentives.map(incentive => incentive.toBackendFormat()),
        };
    }

    // Method to add a new condition
    addCondition() {
        this.conditions.push(new Condition({ id: this.conditions.length + 1 }));
    }

    // Method to add a new target
    addTarget() {
        this.targets.push(new Target({ id: this.targets.length + 1 }));
    }

    // Method to add a new incentive
    addIncentive() {
        this.incentives.push(new Incentive({ id: this.incentives.length + 1 }));
    }

    // Method to update a field in this promotion
    updateField(field, value) {
        this[field] = value;
    }
}

export default Promotion;
