import {jwtDecode} from 'jwt-decode';

class CurrentUser {
    constructor(id, name, surname, routes) {
        this.id = id;
        this.name = name;
        this.surname = surname;
        this.routes = routes;
    }
}

export const setAuthData = (token) => {
    const decodedToken = jwtDecode(token);

    const user = new CurrentUser(decodedToken.id, decodedToken.name, decodedToken.surname, decodedToken.routes);

    localStorage.setItem('jwt_token', token);
    localStorage.setItem('user', JSON.stringify(user));
};

export const getToken = () => {
    return localStorage.getItem('jwt_token');
};

export const getCurrentUser = () => {
    const currentUserJSON = localStorage.getItem('user');
    if (currentUserJSON) {
        const currentUserData = JSON.parse(currentUserJSON);
        return new CurrentUser(currentUserData.id, currentUserData.name, currentUserData.surname, currentUserData.routes);
    }
    return null;
};

export const clearAuthData = () => {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('user');
};

export const isAuthenticated = () => {
    return !!getToken();
};
