export class Venue {
    constructor({ id = null, name = '', description = '', email = '', phone = '', countryCode = '', address = '', website = '' }) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.email = email;
        this.phone = phone;
        this.countryCode = countryCode;
        this.address = address;
        this.website = website;
    }
}
