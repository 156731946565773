import React from 'react';

const SecuritySettings = () => {
    return (
        <div>
            <h3>Security Settings</h3>
            {/* Form for Password and Recovery Email */}
            <form>
                <div className="mb-3">
                    <label className="form-label">Current Password</label>
                    <input type="password" className="form-control" placeholder="Enter current password" />
                </div>
                <div className="mb-3">
                    <label className="form-label">New Password</label>
                    <input type="password" className="form-control" placeholder="Enter new password" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Confirm New Password</label>
                    <input type="password" className="form-control" placeholder="Confirm new password" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Recovery Email</label>
                    <input type="email" className="form-control" placeholder="Enter recovery email" />
                </div>
                <button type="submit" className="btn btn-primary">Update Security Info</button>
            </form>
        </div>
    );
};

export default SecuritySettings;
