// src/lang/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';
import fr from './fr';
import de from './de';
import hr from './hr';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            hr: { translation: hr },
            fr: { translation: fr },
            de: { translation: de },
        },
        lng: 'en', // Default language
        fallbackLng: 'en', // Fallback language if translation is not found
        interpolation: {
            escapeValue: false, // React already escapes by default
        },
    });

export default i18n;
