import React from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const PromotionList = ({ promotions, selectedPromotionIndex, onSelectPromotion, onAddPromotion }) => {
    const { t } = useTranslation(); // Initialize translation hook

    return (
        <div>
            <h3>{t('Campaigns.PromotionList.promotionsList')}</h3>
            <Button color="success" className="mb-2" onClick={onAddPromotion}>
                {t('Campaigns.PromotionList.addPromotion')}
            </Button>
            <ul className="list-group">
                {promotions.map((promotion, index) => (
                    <li
                        key={index}
                        className={`list-group-item ${selectedPromotionIndex === index ? 'active' : ''}`}
                        onClick={() => onSelectPromotion(index)}
                        style={{ cursor: 'pointer' }}
                    >
                        {promotion.name || t('Campaigns.PromotionList.promotion', { index: index + 1 })}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PromotionList;
