import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const MultiplierComponent = ({ value, operator, onChange }) => {
    return (
        <FormGroup>
            <Label for="operator">Operator</Label>
            <Input
                type="select"
                id="operator"
                value={operator || ''}
                onChange={(e) => onChange('operator', e.target.value)}
            >
                <option value="">Select Operator</option>
                <option value="=">=</option>
            </Input>

            <Label for="value" className="mt-2">Multiplier</Label>
            <Input
                type="number"
                id="value"
                value={value || ''}
                onChange={(e) => onChange('value', e.target.value)}
                placeholder="Enter multiplier"
            />
        </FormGroup>
    );
};

export default MultiplierComponent;
