import React, { useContext, useEffect, useState, useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import { FormGroup, Label, Input } from 'reactstrap';
import { fetchItems } from "../../../../../services/Http/Api";
import { SettingsContext } from "../../../../SettingsContext";

const FreeItemSelectorComponent = ({ value, operator, onChange, target, type, attribute }) => {
    const { siteSettings } = useContext(SettingsContext);
    const [selectedItem, setSelectedItem] = useState(null); // Store the selected item

    // Get supported operators based on target, type, and attribute
    const supportedOperators = useMemo(() => {
        if (target === 'incentives') {
            const incentiveTypes = siteSettings?.getIncentiveTypes() || [];
            const selectedIncentiveType = incentiveTypes.find(incentive => incentive.key === type);

            if (selectedIncentiveType) {
                const selectedAttribute = selectedIncentiveType.attributes.find(attr => attr.key === attribute);
                return selectedAttribute?.supportedOperators || [];
            }
        }
        return [];
    }, [siteSettings, target, type, attribute]);

    // Fetch initial items and pre-select the item based on `value`
    useEffect(() => {
        const fetchInitialItems = async () => {
            try {
                const response = await fetchItems({ search: '', perPage: 100, page: 1 });
                const options = response.data.map(item => ({
                    value: item.id,
                    label: item.product_name,
                }));

                if (value) {
                    let selectedItemId = null;
                    try {
                        selectedItemId = JSON.parse(value);
                    } catch (error) {
                        console.error('Invalid JSON format for item ID:', error);
                    }

                    const preSelectedItem = options.find(option => option.value === selectedItemId);
                    setSelectedItem(preSelectedItem || null);
                }
            } catch (error) {
                console.error('Error fetching initial items:', error);
            }
        };

        fetchInitialItems(); // Fetch items once on mount
    }, [value]);

    // Custom debounce function
    const debouncePromise = (func, delay) => {
        let timer;
        return (...args) => {
            return new Promise((resolve) => {
                if (timer) clearTimeout(timer);
                timer = setTimeout(() => resolve(func(...args)), delay);
            });
        };
    };

    // Load options function using debounce
    const loadOptions = async (inputValue) => {
        try {
            const response = await fetchItems({ search: inputValue, perPage: 10, page: 1 });

            return response.data.map(item => ({
                value: item.id,
                label: item.product_name,
            }));
        } catch (error) {
            console.error('Error fetching items:', error);
            return []; // Return empty array on error
        }
    };

    // Debounced loadOptions
    const debouncedLoadOptions = useMemo(() => debouncePromise(loadOptions, 700), []);

    // Handle the selection change
    const handleSelectChange = (selected) => {
        if (!selected) {
            setSelectedItem(null);
            onChange('value', JSON.stringify(null)); // Update parent component with null
            return;
        }

        setSelectedItem(selected); // Update the local state with the selected item
        onChange('value', JSON.stringify(selected.value)); // Update the parent component with JSON string
    };

    return (
        <FormGroup>
            {/* Operator Selection */}
            <Label for="operator">Operator</Label>
            <Input
                type="select"
                id="operator"
                value={operator || ''}
                onChange={(e) => onChange('operator', e.target.value)}
            >
                <option value="">Select Operator</option>
                {supportedOperators.map(op => (
                    <option key={op} value={op}>{op}</option>
                ))}
            </Input>

            {/* Free Item Selection */}
            <Label className="mt-2" for="value">Free Item</Label>
            <AsyncSelect
                id="value"
                cacheOptions={false} // Disable caching for reliable fetching
                defaultOptions // Use default options for quicker initial results
                loadOptions={debouncedLoadOptions} // Debounced loadOptions function
                value={selectedItem} // Controlled value
                onChange={handleSelectChange}
                placeholder="Search and select a free item"
                noOptionsMessage={() => "No items available"}
            />
        </FormGroup>
    );
};

export default FreeItemSelectorComponent;
