import React, { useState, useEffect, useContext } from 'react';
import { fetchTransactionStats } from '../../../../services/Http/Api';
import DataTable from 'react-data-table-component';
import { SettingsContext } from "../../../SettingsContext";
import QuestionModal from "../../../global/QuestionModal";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

const TransactionsOverview = ({ period }) => {
    const { t } = useTranslation(); // Initialize translation hook
    const { siteSettings, loading: settingsLoading } = useContext(SettingsContext);
    const [transactions, setTransactions] = useState([]);
    const [totals, setTotals] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [totalRows, setTotalRows] = useState(0);
    const [sortBy, setSortBy] = useState('date');  // Default sort column
    const [sortDirection, setSortDirection] = useState('asc');  // Default sort direction
    const [showQuestionModal, setShowQuestionModal] = useState(false); // State for the question modal

    // Fetch transaction stats based on the selected period and sorting
    useEffect(() => {
        const loadStats = async () => {
            setLoading(true);
            try {
                const data = await fetchTransactionStats({
                    period,
                    page: currentPage,
                    perPage,
                    sortBy,
                    sortDirection
                });

                setTotals(data.totals);
                setTransactions(data.stats.data);
                setTotalRows(data.stats.total);
            } catch (error) {
                console.error('Failed to load stats', error);
            } finally {
                setLoading(false);
            }
        };

        loadStats();
    }, [period, currentPage, perPage, sortBy, sortDirection]);

    const handlePageChange = (page) => setCurrentPage(page);
    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };
    const handleSort = (column, sortDirection) => {
        // If the column is sortable, map the selector to the column name for the backend sorting
        let columnField;

        switch (column.name) {
            case t('Transactions.TransactionsOverview.columns.date'):
                columnField = 'date';
                break;
            case t('Transactions.TransactionsOverview.columns.totalPrice'):
                columnField = 'total_price';
                break;
            case t('Transactions.TransactionsOverview.columns.totalRevenue'):
                columnField = 'total_revenue';
                break;
            case t('Transactions.TransactionsOverview.columns.averagePrice'):
                columnField = 'average_price';
                break;
            case t('Transactions.TransactionsOverview.columns.averageRevenue'):
                columnField = 'average_revenue';
                break;
            case t('Transactions.TransactionsOverview.columns.transactionCount'):
                columnField = 'transaction_count';
                break;
            default:
                columnField = 'date'; // Fallback to date if no match
        }

        setSortBy(columnField);  // Set the actual field name for backend
        setSortDirection(sortDirection);  // Set sort direction (asc/desc)
    };

    const numberFormatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
    });

    const columns = [
        {
            name: t('Transactions.TransactionsOverview.columns.date'), // Use translation
            selector: row => new Date(row.date).toLocaleDateString(),
            sortable: true
        },
        {
            name: t('Transactions.TransactionsOverview.columns.totalPrice'), // Use translation
            selector: row => numberFormatter.format(row.total_price),
            sortable: true
        },
        {
            name: t('Transactions.TransactionsOverview.columns.totalRevenue'), // Use translation
            selector: row => numberFormatter.format(row.total_revenue),
            sortable: true
        },
        {
            name: t('Transactions.TransactionsOverview.columns.averagePrice'), // Use translation
            selector: row => numberFormatter.format(row.average_price || 0),
            sortable: true
        },
        {
            name: t('Transactions.TransactionsOverview.columns.averageRevenue'), // Use translation
            selector: row => numberFormatter.format(row.average_revenue || 0),
            sortable: true
        },
        {
            name: t('Transactions.TransactionsOverview.columns.transactionCount'), // Use translation
            selector: row => row.transaction_count,
            sortable: true
        },
    ];

    return (
        <div>
            <div className="totals-container d-flex justify-content-between mb-4">
                {/* Totals Row */}
                <div className="total-box">
                    <p>{t('Transactions.TransactionsOverview.distinctManufacturers')}</p>
                    <h4>{totals ? totals.distinct_manufacturers : 0}</h4>
                </div>
                <div className="total-box">
                    <p>{t('Transactions.TransactionsOverview.totalPrice')}</p>
                    <h4>{totals ? numberFormatter.format(totals.total_price || 0): 0.00}</h4>
                </div>
                <div className="total-box">
                    <p>{t('Transactions.TransactionsOverview.totalRevenue')}</p>
                    <h4>{totals ? numberFormatter.format(totals.total_revenue || 0) : 0.00}</h4>
                </div>
                <div className="total-box">
                    <p>{t('Transactions.TransactionsOverview.totalTransactions')}</p>
                    <h4>{totals ? totals.total_transactions : 0.00}</h4>
                </div>
                <div className="total-box">
                    <p>{t('Transactions.TransactionsOverview.distinctBuyers')}</p>
                    <h4>{totals ? totals.distinct_buyer_count : 0}</h4>
                </div>
            </div>

            {/* Question Modal Trigger */}
            <button
                className="btn btn-link"
                onClick={() => setShowQuestionModal(true)} // Trigger question modal
                style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)', marginBottom: '1rem' }}
            >
                <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '1.5rem' }} />
            </button>

            {/* Transaction Data Table */}
            <DataTable
                columns={columns}
                data={transactions}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                onSort={handleSort}
                sortServer
                highlightOnHover
                progressPending={loading}
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
            />

            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={() => setShowQuestionModal(false)}
                param="transactions_table" // Pass the parameter for the question part
            />
        </div>
    );
};

export default TransactionsOverview;
