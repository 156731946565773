import axios from 'axios';
import {clearAuthData, getToken} from './AuthService';
import {Venue} from "../../models/Venue";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Request interceptor to add the auth token header to requests
axiosInstance.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;  // Append token
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            //clearAuthData();
            window.location.reload();
        }
        return Promise.reject(error);
    }
);

export const login = async (email, password) => {
    try {
        const response = await axiosInstance.post('/login', { email, password });
        return response.data;
    } catch (error) {
        console.error('Login error:', error.response);
        throw error;
    }
};

export const postQuestion = async (name, email, question, question_part) => {
    try {
        const response = await axiosInstance.post('/question', { name, email, question, question_part});
        return response.data;
    } catch (error) {
        console.error('Login error:', error.response);
        throw error;
    }
};

export const registerUser = async (userData) => {
    try {
        const formData = new FormData();
        for (const key in userData) {
            formData.append(key, userData[key]);
        }
        const response = await axiosInstance.post('/register', formData);
        return response.data;
    } catch (error) {
        console.error('Error registering user:', error);
        throw error;
    }
};

export const fetchOrganizations = async ({ page, perPage, searchTerm, selectedType }) => {
    try {
        const { data } = await axiosInstance.get('/organizations', {
            params: {
                page,
                perPage,
                searchTerm,
                selectedType
            }
        });
        return data;
    } catch (error) {
        console.error('Error fetching organizations:', error);
        throw error;
    }
};
// services/Http/Api.js
export const fetchManufacturers = async ({ page, perPage, search }) => {
    try {
        const { data } = await axiosInstance.get('/items/manufacturers', {
            params: {
                page,
                perPage,
                search,
            }
        });
        return data;
    } catch (error) {
        console.error('Error fetching manufacturers:', error);
        throw error;
    }
};

export const fetchItems = async ({ manufacturerId, perPage, page, search, sortBy, sortDirection }) => {
    try {
        const { data } = await axiosInstance.get('/items', {
            params: {
                manufacturerId,  // Filter by manufacturer ID
                perPage,         // Pagination: Number of items per page
                page,            // Pagination: Current page
                search,          // Search field: Can be model number, serial number, or product name
                sortBy,          // Sorting field
                sortDirection,   // Sorting direction (asc/desc)
            },
        });
        return data;
    } catch (error) {
        console.error('Error fetching items:', error);
        throw error;
    }
};


export const fetchOrganizationTypes = async () => {
    try {
        const { data } = await axiosInstance.get('/organizationTypes');
        return data;
    } catch (error) {
        console.error('Error fetching organization types:', error);
        throw error;
    }
};

export const fetchCountryCodes = async () => {
    try {
        const { data } = await axiosInstance.get('/countryCodes');
        return data;
    } catch (error) {
        console.error('Error fetching organization types:', error);
        throw error;
    }
};

export const createOrganization = async (postData) => {
    try {
        const { data } = await axiosInstance.post('/organizations/create', postData); // Pass postData to the post request
        return data;
    } catch (error) {
        console.error('Error creating organization:', error);
        throw error;
    }
};

export const fetchOrganizationById = async (id) => {
    try {
        const response = await axiosInstance.get(`${API_URL}/organizations/${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch organization by ID:', error);
        throw error;
    }
};
export const fetchVenuesByOrganization = async (organizationId) => {
    try {
        const response = await axiosInstance.get(`/organizations/${organizationId}/venues`);
        return response.data.map(venueData => new Venue(venueData));
    } catch (error) {
        console.error('Failed to fetch venues by organization ID:', error);
        throw error;
    }
};

export const fetchUsersByOrganization = async (page = 1, perPage = 10, sortBy = 'id', sortDirection = 'asc') => {
    try {
        const response = await axiosInstance.get('/buyers', {
            params: {
                page,
                per_page: perPage,
                sortBy,
                sortDirection,
            },
        });
        return {
            users: response.data.data,       // The actual user data
            total: response.data.total,      // The total number of users
            currentPage: response.data.current_page, // Current page
            lastPage: response.data.last_page, // Last page
        };
    } catch (error) {
        console.error('Failed to fetch users:', error);
        throw error;
    }
};
export const fetchUserTimeline = async () => {
    try {
        const { data } = await axiosInstance.get('/buyers/timeline');
        return data;
    } catch (error) {
        console.error('Error fetching user timeline:', error);
        throw error;
    }
};


export const fetchVenueById = async (venueId) => {
    try {
        const response = await axiosInstance.get(`/venues/${venueId}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch venue by ID:', error);
        throw error;
    }
};
export const fetchVenueIntegration = async (venueId) => {
    try {
        const response = await axiosInstance.get(`/venues/${venueId}/integration`);
        return response.data;  // Expected to return the API key and status
    } catch (error) {
        console.error('Error fetching venue integration:', error);
        throw error;
    }
};


export const generateVenueSecret = async (venueId) => {
    try {
        const response = await axiosInstance.put(`/venues/${venueId}/integration/generate`);
        return response.data;  // Expected to return the generated API key and secret
    } catch (error) {
        console.error('Error generating API secret for venue:', error);
        throw error;
    }
};

export const generateVenueApiKey = async (venueId) => {
    try {
        const response = await axiosInstance.put(`/venues/${venueId}/integration/generate`);
        return response.data;
    } catch (error) {
        console.error('Error generating API key for venue:', error);
        throw error;
    }
};

export const fetchVenueStatistics = async ({ venueId, period, page, perPage, sortBy, sortDirection }) => {
    try {
        // Construct the API call with the venueId and the filters passed in the query params
        const response = await axiosInstance.get(`/venues/${venueId}/statistics`, {
            params: {
                period,        // Period filter (e.g., 'last_7_days', 'last_month')
                page,          // Pagination: current page
                perPage,       // Number of results per page
                sortBy,        // Sorting field (e.g., 'date', 'total_revenue')
                sortDirection, // Sorting direction ('asc' or 'desc')
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching venue statistics:', error);
        throw error;
    }
};



export const fetchUsersStatistics = async () => {
    try {
        const response = await axiosInstance.get('/buyers/statistics');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch statistics:', error);
        throw error;
    }
};


export const fetchAllVenues = async () => {
    try {
        const response = await axiosInstance.get(`/venues`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch all venues:', error);
        throw error;
    }
};
export const fetchSiteSettings = async () => {
    try {
        const response = await axiosInstance.get('/settings');
        return response.data;
    } catch (error) {
        console.error('Error fetching site settings', error);
        throw error;
    }
};
export const addVenueToOrganization = async (venueData) => {
    try {
        const response = await axiosInstance.post(`/venues`, venueData);
        return response.data;
    } catch (error) {
        console.error('Failed to add venue to organization:', error);
        throw error;
    }
};

export const updateVenue = async (venueId, venueData) => {
    try {
        const response = await axiosInstance.put(`/venues/${venueId}`, venueData);
        return response.data;
    } catch (error) {
        console.error('Failed to update venue:', error);
        throw error;
    }
};
export const addUserToOrganization = async (organizationId, userData) => {
    try {
        const response = await axiosInstance.post(`/organizations/${organizationId}/users`, userData);
        return response.data;
    } catch (error) {
        console.error('Failed to add user to organization:', error);
        throw error;
    }
};
export const addCustomerToOrganization = async (userData) => {
    try {
        const response = await axiosInstance.post(`/buyers/`, userData);
        return response.data;
    } catch (error) {
        console.error('Failed to add user to organization:', error);
        throw error;
    }
};

export const addUserToVenue = async (venueId, userData) => {
    try {
        const response = await axiosInstance.post(`/venues/${venueId}/users`, userData);
        return response.data;
    } catch (error) {
        console.error('Failed to add user to venue:', error);
        throw error;
    }
};
export const fetchAllUsers= async () =>{
}

export const fetchUsersByVenue= async (id) =>{
}

export const addUser = async (id,user) =>{
}

export const fetchRolesForOrganization = async () => {
    try {
        const response = await axiosInstance.get(`/organizations/roles`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch roles for organization:', error);
        throw error;
    }
};
export const fetchDashboardData = async () => {
    try {
        const response = await axiosInstance.get('/dashboard');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch dashboard data:', error);
        throw error;
    }
};

export const fetchRolesForVenue = async () => {
    try {
        const response = await axiosInstance.get(`/venues/roles`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch roles for venue:', error);
        throw error;
    }
};

export const fetchRolesForSystem = async () => {
    try {
        const response = await axiosInstance.get('/system/roles');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch roles for system:', error);
        throw error;
    }
};

export const fetchTransactionStats = async ({ period, page = 1, perPage = 10, sortBy = 'date', sortDirection = 'asc' }) => {
    try {
        const response = await axiosInstance.get('/transactions/stats', {
            params: {
                period,
                page,
                perPage,
                sortBy,
                sortDirection
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch transaction stats', error);
        throw error;
    }
};
export const fetchDemographicsData = async (params) => {
    try {
        const response = await axiosInstance.get('/transactions/demographics', { params });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch demographics data:', error);
        throw error;
    }
};
export const fetchTransactionProgress = async (params) => {
    try {
        const response = await axiosInstance.get('/transactions/progress', { params });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch demographics data:', error);
        throw error;
    }
}

export const fetchItemsWithFilters = async ({ modelNumber, serialNumber, manufacturerId, status, startDate, endDate, page = 1, perPage = 10 }) => {
    try {
        const { data } = await axiosInstance.get('/items', {
            params: {
                modelNumber,
                serialNumber,
                manufacturerId,
                status,
                startDate,
                endDate,
                page,
                perPage
            }
        });
        return data;
    } catch (error) {
        console.error('Error fetching items with filters:', error);
        throw error;
    }
};

export const fetchBestSellingItems = async ({ period, page = 1, perPage = 10 }) => {
    try {
        const { data } = await axiosInstance.get('/items/best-selling', {
            params: {
                period,
                page,
                perPage
            }
        });
        return data;
    } catch (error) {
        console.error('Error fetching best selling items:', error);
        throw error;
    }
};

export const fetchWarrantyExpiryAlerts = async ({ period = '30_days', page = 1, perPage = 10 }) => {
    try {
        const { data } = await axiosInstance.get('/items/warranty-expiry', {
            params: {
                period,
                page,
                perPage
            }
        });
        return data;
    } catch (error) {
        console.error('Error fetching warranty expiry alerts:', error);
        throw error;
    }
};

export const fetchItemDetails = async (itemId) => {
    try {
        const { data } = await axiosInstance.get(`/items/manufacturerItem/${itemId}`);
        return data;
    } catch (error) {
        console.error('Error fetching item details:', error);
        throw error;
    }
};

export const fetchItemTransactionHistory = async (itemId) => {
    try {
        const { data } = await axiosInstance.get(`/items/${itemId}/transactions`);
        return data;
    } catch (error) {
        console.error('Error fetching item transaction history:', error);
        throw error;
    }
};

export const updateItemDetails = async (itemId, updatedData) => {
    try {
        const { data } = await axiosInstance.put(`/items/${itemId}`, updatedData);
        return data;
    } catch (error) {
        console.error('Error updating item details:', error);
        throw error;
    }
};

export const fetchSoldItems = async ({ itemId, page = 1, perPage = 10, sortBy = 'date', sortDirection = 'asc' }) => {
    try {
        const { data } = await axiosInstance.get(`/items/manufacturerItem/${itemId}/soldItems`, {
            params: {
                page,
                perPage,
                sortBy,
                sortDirection
            }
        });
        return data;
    } catch (error) {
        console.error('Error fetching sold items:', error);
        throw error;
    }
};

export const fetchSalesInsights = async ({ period, page = 1, perPage = 10 }) => {
    try {
        const { data } = await axiosInstance.get('/items/sales-insights', {
            params: {
                period,
                page,
                perPage
            }
        });
        return data;
    } catch (error) {
        console.error('Error fetching sales insights:', error);
        throw error;
    }
};

export const fetchItemPerformanceCharts = async (itemId) => {
    try {
        const { data } = await axiosInstance.get(`/items/manufacturerItem/${itemId}/performance-charts`);
        return data;
    } catch (error) {
        console.error('Error fetching chart data:', error);
        throw error;
    }
};

export const fetchItemTransactions = async (itemId) => {
    try {
        const { data } = await axiosInstance.get(`/items/${itemId}/transactions`);
        return data;
    } catch (error) {
        console.error('Error fetching transactions:', error);
        throw error;
    }
};
export const fetchPerformanceInsights = async () => {
    try {
        const { data } = await axiosInstance.get('/items/performance-insights');
        return data;
    } catch (error) {
        console.error('Error fetching performance insights:', error);
        throw error;
    }
};

export const fetchItemPerformanceInsights = async (itemId, page, perPage, period, sortField, sortDirection) => {
    try {
        const { data } = await axiosInstance.get(`/items/manufacturerItem/${itemId}/performance-insights`, {
            params: {
                page,
                perPage,
                period,
                sortBy: sortField,
                sortDirection,
            }
        });
        return data;
    } catch (error) {
        console.error('Error fetching performance insights:', error);
        throw error;
    }
};

export const fetchCampaigns = async ({ page = 1, perPage = 10, sortBy = 'name', sortDirection = 'asc' }) => {
    try {
        const response = await axiosInstance.get('/campaigns', {
            params: {
                page,
                perPage,
                sortBy,
                sortDirection,
            },
        });
        return response;
    } catch (error) {
        console.error('Error fetching campaigns:', error);
        throw error;
    }
};

export const createCampaign = async (campaignData) => {
    try {
        const response = await axiosInstance.post('/campaigns', campaignData);
        return response.data;
    } catch (error) {
        console.error('Error creating campaign:', error);
        throw error;
    }
};
export const calculateCampaign = async (campaignData) => {
    try {
        const response = await axiosInstance.post('/campaigns/calculate', campaignData);
        return response.data;
    } catch (error) {
        console.error('Error creating campaign:', error);
        throw error;
    }
};

export const fetchCampaign = async (campaignId) => {
    try {
        const response = await axiosInstance.get(`/campaigns/${campaignId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching campaign:', error);
        throw error;
    }
};
export const updateCampaign = async (id, campaignData) => {
    try {
        const response = await axiosInstance.put(`/campaigns/${id}`, campaignData);
        return response.data;
    } catch (error) {
        console.error('Error updating campaign:', error);
        throw error;
    }
};












export default axiosInstance;
