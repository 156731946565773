import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Spinner } from 'reactstrap';
import { Modal } from 'react-bootstrap'; // Importing Modal from react-bootstrap
import classnames from 'classnames';
import ItemDescription from './ItemDescription';
import TransactionsTable from './TransactionsTable';
import { useParams } from 'react-router-dom';
import { fetchItemDetails } from "../../../../../services/Http/Api";
import { useTranslation } from 'react-i18next'; // Import useTranslation
import QuestionModal from "../../../../global/QuestionModal"; // Import QuestionModal
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'; // Import icons

const ViewItem = () => {
    const { itemId } = useParams();
    const { t } = useTranslation(); // Initialize translation hook
    const [activeTab, setActiveTab] = useState('1');
    const [itemDetails, setItemDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showTutorialModal, setShowTutorialModal] = useState(false); // State for the tutorial modal
    const [showQuestionModal, setShowQuestionModal] = useState(false); // State for the question modal

    useEffect(() => {
        const getItemDetails = async () => {
            setLoading(true);
            try {
                const data = await fetchItemDetails(itemId);
                setItemDetails(data);
            } catch (error) {
                console.error('Error fetching item details:', error);
            } finally {
                setLoading(false);
            }
        };

        getItemDetails();
    }, [itemId]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleShowTutorialModal = () => setShowTutorialModal(true);
    const handleCloseTutorialModal = () => setShowTutorialModal(false);

    const handleShowQuestionModal = () => setShowQuestionModal(true);
    const handleCloseQuestionModal = () => setShowQuestionModal(false);

    return (
        <div className="container-fluid mt-4">
            {/* Navigation Tabs */}
            <Nav tabs className="mb-3 justify-content-start">
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => toggleTab('1')}
                        style={{ cursor: 'pointer' }}
                    >
                        {t('Items.ViewItem.tabs.description')} {/* Translated Description Tab */}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => toggleTab('2')}
                        style={{ cursor: 'pointer' }}
                    >
                        {t('Items.ViewItem.tabs.transactions')} {/* Translated Transactions Tab */}
                    </NavLink>
                </NavItem>
            </Nav>

            {/* Buttons to Trigger Modals */}
            <div className="mb-3">
                <button
                    className="btn btn-link"
                    onClick={handleShowQuestionModal}
                    style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)', marginRight: '0.5rem' }}
                >
                    <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '1.5rem' }} />
                </button>
                <button
                    className="btn btn-link"
                    onClick={handleShowTutorialModal}
                    style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)' }}
                >
                    <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '1.5rem' }} />
                </button>
            </div>

            {/* Tab Content */}
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Card className="mb-3">
                        <CardBody>
                            {loading ? (
                                <div className="text-center">
                                    <Spinner color="primary" />
                                </div>
                            ) : (
                                <ItemDescription itemDetails={itemDetails} />
                            )}
                        </CardBody>
                    </Card>
                </TabPane>

                <TabPane tabId="2">
                    <Card className="mb-3">
                        <CardBody>
                            <TransactionsTable itemId={itemId} />
                        </CardBody>
                    </Card>
                </TabPane>
            </TabContent>

            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={handleCloseQuestionModal}
                param="item_view" // Pass the parameter for the question part
            />

            {/* Tutorial Modal */}
            <Modal show={showTutorialModal} onHide={handleCloseTutorialModal} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Items.ViewItem.Tutorial.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: 'auto' }}>
                        <div className="welcome-message" style={{ marginBottom: '1.5rem' }}>
                            <p style={{ fontSize: '1.5rem', lineHeight: '1.6', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Items.ViewItem.Tutorial.welcome_message')}
                            </p>
                        </div>

                        {/* Description Section */}
                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Items.ViewItem.Tutorial.tabs.description.title')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Items.ViewItem.Tutorial.tabs.description.message')}
                            </p>
                        </div>

                        {/* Transactions Section */}
                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Items.ViewItem.Tutorial.tabs.transactions.title')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Items.ViewItem.Tutorial.tabs.transactions.message')}
                            </p>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Items.ViewItem.Tutorial.tabs.transactions.additional_message', {
                                    defaultValue: "Please note that charts, timelines, and item details by user demographics will be available in future updates."
                                })}
                            </p>
                        </div>


                    </div>
                </Modal.Body>

            </Modal>
        </div>
    );
};

export default ViewItem;
