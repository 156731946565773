import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Spinner, Input, Button, Modal } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

const AuthorizedServiceCenters = () => {
    const { t } = useTranslation(); // Initialize translation hook
    const [activeTab, setActiveTab] = useState('1');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [newServiceName, setNewServiceName] = useState('');
    const [newServiceAddress, setNewServiceAddress] = useState('');
    const [newServicePhone, setNewServicePhone] = useState('');
    const [selectedManufacturer, setSelectedManufacturer] = useState('');

    // Dummy data for authorized service centers
    const authorizedServices = [
        { id: 1, name: 'Authorized Service A', address: '123 Main St, Zagreb', phone: '01 2345 678' },
        { id: 2, name: 'Authorized Service B', address: '456 Second St, Split', phone: '021 2345 678' },
        { id: 3, name: 'Authorized Service C', address: '789 Third St, Rijeka', phone: '051 2345 678' },
    ];

    const manufacturers = [
        { value: 'manufacturer-a', label: 'Manufacturer A' },
        { value: 'manufacturer-b', label: 'Manufacturer B' },
        { value: 'manufacturer-c', label: 'Manufacturer C' },
    ];

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setShowModal(false);
        setNewServiceName('');
        setNewServiceAddress('');
        setNewServicePhone('');
    };

    const handleCreateService = () => {
        console.log('Creating new service:', newServiceName, newServiceAddress, newServicePhone, selectedManufacturer);
        handleCloseModal();
    };

    const columns = [
        { name: t('AuthorizedServices.name'), selector: row => row.name, sortable: true },
        { name: t('AuthorizedServices.address'), selector: row => row.address, sortable: true },
        { name: t('AuthorizedServices.phone'), selector: row => row.phone, sortable: true },
    ];

    return (
        <div className="container-fluid mt-4">
            {/* Navigation Tabs */}
            <Nav tabs className="mb-3 justify-content-start">
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => toggleTab('1')}
                        style={{ cursor: 'pointer' }}
                    >
                        {t('AuthorizedServices.overview')}
                    </NavLink>
                </NavItem>
            </Nav>

            {/* Action Button for Modals */}
            <div className="d-flex justify-content-end mb-3">
                <Button color="primary" onClick={handleShowModal}>{t('AuthorizedServices.addNewService')}</Button>
            </div>

            {/* Tab Content */}
            <TabContent activeTab={activeTab}>
                {/* Authorized Services Overview Tab */}
                <TabPane tabId="1">
                    <Card className="mb-3">
                        <CardBody>
                            {loading ? (
                                <Spinner color="primary" />
                            ) : (
                                <DataTable
                                    columns={columns}
                                    data={authorizedServices}
                                    pagination
                                    highlightOnHover
                                    noDataComponent={<div>{t('AuthorizedServices.noServices')}</div>}
                                />
                            )}
                        </CardBody>
                    </Card>
                </TabPane>
            </TabContent>

            {/* Create Authorized Service Modal */}
            <Modal isOpen={showModal} toggle={handleCloseModal}>
                <div className="modal-header">
                    <h5 className="modal-title">{t('AuthorizedServices.createService')}</h5>
                    <button type="button" className="close" onClick={handleCloseModal}>
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Input
                        type="text"
                        value={newServiceName}
                        onChange={(e) => setNewServiceName(e.target.value)}
                        placeholder={t('AuthorizedServices.serviceName')}
                        className="mb-2"
                    />
                    <Input
                        type="text"
                        value={newServiceAddress}
                        onChange={(e) => setNewServiceAddress(e.target.value)}
                        placeholder={t('AuthorizedServices.serviceAddress')}
                        className="mb-2"
                    />
                    <Input
                        type="text"
                        value={newServicePhone}
                        onChange={(e) => setNewServicePhone(e.target.value)}
                        placeholder={t('AuthorizedServices.servicePhone')}
                        className="mb-2"
                    />
                </div>
                <div className="modal-footer">
                    <Button color="secondary" onClick={handleCloseModal}>{t('AuthorizedServices.cancel')}</Button>
                    <Button color="primary" onClick={handleCreateService}>{t('AuthorizedServices.create')}</Button>
                </div>
            </Modal>
        </div>
    );
}

export default AuthorizedServiceCenters;
