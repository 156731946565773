import React, { useState, useEffect } from 'react';
import { fetchCountryCodes } from "../../../services/Http/Api";
import PhoneNumberInput from "../PhoneNumberInput";
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VenueForm = ({ venue, onSave }) => {
    const { t } = useTranslation(); // Initialize translation hook
    const [countryCodes, setCountryCodes] = useState([]);
    const [socialLinks, setSocialLinks] = useState({
        twitter: '',
        facebook: '',
        instagram: ''
    });

    const [venueData, setVenueData] = useState({
        name: venue?.name || '',
        description: venue?.description || '',
        email: venue?.email || '',
        phone: venue?.phone || '',
        phone_country_id: venue?.phone_country_id ? String(venue.phone_country_id) : '', // Ensure this is a string
        country_id: venue?.country_id || '',
        address: venue?.address || '',
        city: venue?.city || '',
        website: venue?.website || '',
        latitude: venue?.latitude || '',
        longitude: venue?.longitude || '',
        organization_id: venue?.organization_id || ''
    });

    useEffect(() => {
        if (venue?.social_media_links) {
            const parsedLinks = JSON.parse(venue.social_media_links);
            setSocialLinks({
                twitter: parsedLinks.twitter || '',
                facebook: parsedLinks.facebook || '',
                instagram: parsedLinks.instagram || ''
            });
        }
    }, [venue]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await fetchCountryCodes();
                setCountryCodes(response);
            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };
        fetchCountries();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVenueData(prevData => ({ ...prevData, [name]: value }));
    };

    const handlePhoneNumberChange = (data) => {
        setVenueData(prevData => ({
            ...prevData,
            phone: data.phone,
            phone_country_id: data.phoneCountryCode
        }));
    };

    const handleSocialChange = (e) => {
        const { name, value } = e.target;
        setSocialLinks(prevLinks => ({ ...prevLinks, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const fullVenueData = {
            ...venueData,
            social_media_links: JSON.stringify(socialLinks)
        };
        onSave(fullVenueData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Accordion defaultActiveKey="0">
                {/* Basic Information Section */}
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t('Venues.VenueForm.basic_info')}</Accordion.Header>
                    <Accordion.Body>
                        <div className="mb-6">
                            <label className="form-label" htmlFor="venue-name">{t('Venues.VenueForm.name_label')}</label>
                            <input
                                type="text"
                                name="name"
                                id="venue-name"
                                className="form-control"
                                value={venueData.name}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.name_placeholder')}
                            />
                        </div>
                        <div className="mb-6">
                            <label className="form-label" htmlFor="venue-description">{t('Venues.VenueForm.description_label')}</label>
                            <textarea
                                name="description"
                                id="venue-description"
                                className="form-control"
                                value={venueData.description}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.description_placeholder')}
                            />
                        </div>
                        <div className="mb-6">
                            <label className="form-label" htmlFor="venue-email">{t('Venues.VenueForm.email_label')}</label>
                            <input
                                type="email"
                                name="email"
                                id="venue-email"
                                className="form-control"
                                value={venueData.email}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.email_placeholder')}
                            />
                        </div>
                        <div className="mb-6">
                            <label className="form-label" htmlFor="venue-website">{t('Venues.VenueForm.website_label')}</label>
                            <input
                                type="url"
                                name="website"
                                id="venue-website"
                                className="form-control"
                                value={venueData.website}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.website_placeholder')}
                            />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

                {/* Location Details Section */}
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('Venues.VenueForm.location_details')}</Accordion.Header>
                    <Accordion.Body>
                        <PhoneNumberInput
                            countryCodes={countryCodes}
                            phoneValue={venueData.phone}
                            phoneCountryValue={venueData.phone_country_id}
                            onChange={handlePhoneNumberChange}
                        />
                        <div className="mb-6">
                            <label className="form-label" htmlFor="venue-country">{t('Venues.VenueForm.country_label')}</label>
                            <select
                                name="country_id"
                                id="venue-country"
                                className="form-control"
                                value={venueData.country_id}
                                onChange={handleChange}
                            >
                                <option value="">{t('Venues.VenueForm.select_country')}</option>
                                {countryCodes.map(country => (
                                    <option key={country.id} value={country.id}>
                                        {`${country.name} (+${country.phonecode})`}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-6">
                            <label className="form-label" htmlFor="venue-address">{t('Venues.VenueForm.address_label')}</label>
                            <input
                                type="text"
                                name="address"
                                id="venue-address"
                                className="form-control"
                                value={venueData.address}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.address_placeholder')}
                            />
                        </div>
                        <div className="mb-6">
                            <label className="form-label" htmlFor="venue-city">{t('Venues.VenueForm.city_label')}</label>
                            <input
                                type="text"
                                name="city"
                                id="venue-city"
                                className="form-control"
                                value={venueData.city}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.city_placeholder')}
                            />
                        </div>
                        <div className="mb-6">
                            <label className="form-label" htmlFor="venue-latitude">{t('Venues.VenueForm.latitude_label')}</label>
                            <input
                                type="text"
                                name="latitude"
                                id="venue-latitude"
                                className="form-control"
                                value={venueData.latitude}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.latitude_placeholder')}
                            />
                        </div>
                        <div className="mb-6">
                            <label className="form-label" htmlFor="venue-longitude">{t('Venues.VenueForm.longitude_label')}</label>
                            <input
                                type="text"
                                name="longitude"
                                id="venue-longitude"
                                className="form-control"
                                value={venueData.longitude}
                                onChange={handleChange}
                                placeholder={t('Venues.VenueForm.longitude_placeholder')}
                            />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

                {/* Social Media Links Section */}
                <Accordion.Item eventKey="2">
                    <Accordion.Header>{t('Venues.VenueForm.social_media')}</Accordion.Header>
                    <Accordion.Body>
                        <div className="mb-6">
                            <label className="form-label" htmlFor="venue-twitter">{t('Venues.VenueForm.twitter_label')}</label>
                            <input
                                type="text"
                                name="twitter"
                                id="venue-twitter"
                                className="form-control"
                                value={socialLinks.twitter}
                                onChange={handleSocialChange}
                                placeholder={t('Venues.VenueForm.twitter_placeholder')}
                            />
                        </div>
                        <div className="mb-6">
                            <label className="form-label" htmlFor="venue-facebook">{t('Venues.VenueForm.facebook_label')}</label>
                            <input
                                type="text"
                                name="facebook"
                                id="venue-facebook"
                                className="form-control"
                                value={socialLinks.facebook}
                                onChange={handleSocialChange}
                                placeholder={t('Venues.VenueForm.facebook_placeholder')}
                            />
                        </div>
                        <div className="mb-6">
                            <label className="form-label" htmlFor="venue-instagram">{t('Venues.VenueForm.instagram_label')}</label>
                            <input
                                type="text"
                                name="instagram"
                                id="venue-instagram"
                                className="form-control"
                                value={socialLinks.instagram}
                                onChange={handleSocialChange}
                                placeholder={t('Venues.VenueForm.instagram_placeholder')}
                            />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <div className="form-footer pt-4">
                <button type="submit" className="btn btn-primary">{t('Venues.VenueForm.save_button')}</button>
            </div>
        </form>
    );
};

export default VenueForm;
