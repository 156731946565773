import React, { useEffect, useState, useContext } from 'react';
import { fetchDemographicsData } from '../../../../services/Http/Api';
import ApexCharts from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { SettingsContext } from '../../../SettingsContext';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import QuestionModal from "../../../global/QuestionModal";

const TransactionsStatistics = ({ period }) => {
    const { t } = useTranslation(); // Initialize translation hook
    const { siteSettings } = useContext(SettingsContext); // Get siteSettings context
    const [demographicsData, setDemographicsData] = useState({
        gender: {},
        ageGroups: {},
        cities: {},
        countries: {},
        residentialCountries: {}
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showQuestionModal, setShowQuestionModal] = useState(false); // State for the question modal

    useEffect(() => {
        const loadDemographicsData = async () => {
            setLoading(true);
            try {
                const data = await fetchDemographicsData({ period });
                setDemographicsData({
                    gender: data.gender_summary,
                    ageGroups: data.age_group_summary,
                    cities: data.city_summary,
                    countries: data.country_summary,
                    residentialCountries: data.residential_country_summary
                });
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        loadDemographicsData();
    }, [period]);

    if (loading) {
        return <div>{t('Transactions.TransactionsStatistics.loading')}</div>;
    }

    if (error) {
        return <div>{t('Transactions.TransactionsStatistics.error', { error: error.message })}</div>;
    }

    // Get countries from siteSettings
    const countries = siteSettings?.getAllCountries() || [];

    // Prepare chart data
    const genderChartData = {
        series: Object.values(demographicsData.gender),
        labels: Object.keys(demographicsData.gender)
    };

    const ageGroupChartData = {
        series: [{
            name: t('Transactions.TransactionsStatistics.ageGroupDistribution'),
            data: Object.values(demographicsData.ageGroups)
        }],
        categories: Object.keys(demographicsData.ageGroups)
    };

    // Prepare Country Chart Data with only existing countries
    const countryChartData = {
        series: Object.values(demographicsData.countries).filter(country => country !== undefined),
        labels: countries
            .filter(country => demographicsData.countries[country.id]) // Filter only existing countries
            .map(country => country.name) // Use country names as labels
    };

    // Prepare Residential Country Chart Data with only existing countries
    const residentialCountryChartData = {
        series: [{
            name: t('Transactions.TransactionsStatistics.residentialCountryDistribution'), // Use translation
            data: Object.values(demographicsData.residentialCountries).filter(country => country !== undefined)
        }],
        labels: countries
            .filter(country => demographicsData.residentialCountries[country.id]) // Filter only existing countries
            .map(country => country.name) // Use country names as labels
    };

    // Prepare City Chart Data
    const cityChartData = {
        series: [{
            name: t('Transactions.TransactionsStatistics.cityDistribution'), // Use translation
            data: Object.values(demographicsData.cities)
        }],
        categories: Object.keys(demographicsData.cities) // City names as categories
    };

    return (
        <div className="container">
            <h2>{t('Transactions.TransactionsStatistics.title')}</h2>

            {/* Question Modal Trigger */}
            <Button
                variant="link"
                onClick={() => setShowQuestionModal(true)} // Trigger question modal
                style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)', marginBottom: '1rem' }}
            >
                <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '1.5rem' }} />
            </Button>

            {/* First row with two pie charts */}
            <div className="row">
                {genderChartData.series.length > 0 && (
                    <div className="col-md-6">
                        <h3>{t('Transactions.TransactionsStatistics.genderDistribution')}</h3>
                        <ApexCharts
                            options={{ labels: genderChartData.labels }}
                            series={genderChartData.series}
                            type="pie"
                        />
                    </div>
                )}

                {countryChartData.series.length > 0 && (
                    <div className="col-md-6">
                        <h3>{t('Transactions.TransactionsStatistics.countryDistribution')}</h3>
                        <ApexCharts
                            options={{ labels: countryChartData.labels }} // Ensure labels are set correctly
                            series={countryChartData.series}
                            type="pie"
                        />
                    </div>
                )}
            </div>

            {/* Second row with bar chart */}
            {ageGroupChartData.series[0].data.length > 0 && (
                <div className="row">
                    <div className="col-md-12">
                        <h3>{t('Transactions.TransactionsStatistics.ageGroupDistribution')}</h3>
                        <ApexCharts
                            options={{
                                xaxis: {
                                    categories: ageGroupChartData.categories
                                }
                            }}
                            series={ageGroupChartData.series}
                            type="bar"
                        />
                    </div>
                </div>
            )}

            {/* Third row with bar chart */}
            {cityChartData.series[0].data.length > 0 && (
                <div className="row">
                    <div className="col-md-12">
                        <h3>{t('Transactions.TransactionsStatistics.cityDistribution')}</h3>
                        <ApexCharts
                            options={{
                                xaxis: {
                                    categories: cityChartData.categories // Use city names as labels
                                }
                            }}
                            series={cityChartData.series}
                            type="bar"
                        />
                    </div>
                </div>
            )}

            {/* Fourth row with bar chart */}
            {residentialCountryChartData.series[0].data.length > 0 && (
                <div className="row">
                    <div className="col-md-12">
                        <h3>{t('Transactions.TransactionsStatistics.residentialCountryDistribution')}</h3>
                        <ApexCharts
                            options={{
                                xaxis: {
                                    categories: residentialCountryChartData.labels // Use residential country names as labels
                                }
                            }}
                            series={residentialCountryChartData.series}
                            type="bar"
                        />
                    </div>
                </div>
            )}

            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={() => setShowQuestionModal(false)}
                param="transactions_statistics" // Pass the parameter for the question part
            />
        </div>
    );
};

export default TransactionsStatistics;
