import React, { useEffect, useState } from 'react';
import { fetchOrganizationTypes } from '../../../services/Http/Api';

const OrganizationForm = ({ organization, onChange }) => {
    const [organizationTypes, setOrganizationTypes] = useState([]);

    useEffect(() => {
        // Fetch organization types when the component mounts
        const fetchTypes = async () => {
            try {
                const types = await fetchOrganizationTypes();
                setOrganizationTypes(types);
            } catch (error) {
                console.error('Error fetching organization types:', error);
            }
        };

        fetchTypes();
    }, []);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        onChange({
            [name]: type === 'checkbox' ? checked : value
        });
    };

    return (
        <>
            <h3>Organization Fields</h3>
            <label className="form-label">Name:</label>
            <input
                type="text"
                name="name"
                className="form-control"
                value={organization.name}
                onChange={handleInputChange}
                required
            />

            <label className="form-label">Description:</label>
            <textarea
                name="description"
                className="form-control"
                value={organization.description}
                onChange={handleInputChange}
            />

            <label className="form-label">Logo URL:</label>
            <input
                type="url"
                name="logo"
                className="form-control"
                value={organization.logo}
                onChange={handleInputChange}
            />

            <label className="form-label">Founded Date:</label>
            <input
                type="date"
                name="founded_date"
                className="form-control"
                value={organization.founded_date}
                onChange={handleInputChange}
            />

            <label className="form-check-label">
                <input
                    type="checkbox"
                    name="active"
                    className="form-check-input"
                    checked={organization.active}
                    onChange={handleInputChange}
                />
                Active
            </label>

            <label className="form-label">Organization Type:</label>
            <select
                name="organization_type_id"
                className="form-control"
                value={organization.organization_type_id}
                onChange={handleInputChange}
                required
            >
                <option value="">Select Organization Type</option>
                {organizationTypes.map(type => (
                    <option key={type.id} value={type.id}>{type.name}</option>
                ))}
            </select>
        </>
    );
};

export default OrganizationForm;
