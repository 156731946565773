import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import { isAuthenticated, clearAuthData } from './services/Http/AuthService';
import AppRoutes from './components/layout/AppRoutes';
import Navigation from './components/layout/Navigation';
import LoginPage from './components/layout/LoginPage';
import { useTranslation } from 'react-i18next';
import i18n from './lang/i18n'; // Import your i18n instance
import './assets/css/core.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/App.css';



function App() {
    const [auth, setAuth] = useState(isAuthenticated());
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleLogout = () => {
        clearAuthData();
        setAuth(false);
        navigate('/');
    };

    useEffect(() => {
        setAuth(isAuthenticated());
        const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
        i18n.changeLanguage(selectedLanguage);
    }, []);

    if (!auth) {
        return (
            <div className="App">
                <LoginPage setAuth={setAuth} setLoginError={setLoginError} />
            </div>
        );
    }

    return (
        <div className="layout-page" style={{ backgroundColor: '#ffffff' }}>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <aside id="layout-menu-fixed" className="layout-menu menu-vertical menu">
                        <div className="app-brand">
                            <Link className="app-brand-link" to="/" style={{ textDecoration: 'none' }}>
                                <span className="app-brand-text demo menu-text fw-bolder ms-2" style={{ color: '#d27fea', fontSize: '1.5rem' }}>
                                    <i className="bi bi-gem me-2"></i> Demo GSoft
                                </span>
                            </Link>
                        </div>
                        <div className="menu-inner-shadow"></div>
                        <Navigation handleLogout={handleLogout} />
                    </aside>
                    <div className="content-wrapper">
                        <div className="container-fluid flex-grow-1 container-p-y">
                            <AppRoutes />
                        </div>
                        <footer className="content-footer footer bg-footer-theme">
                            <div className="container-fluid">
                                <div className="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
                                    <div style={{ color: 'rgba(130, 22, 146, 1)', fontWeight: 'bold' }}>Demo GSOFT</div>
                                    <div className="d-none d-lg-inline-block">
                                        <span style={{ color: 'rgba(130, 22, 146, 1)', fontStyle: 'italic' }}>Empowering Retailers</span>
                                    </div>
                                </div>
                            </div>
                        </footer>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
