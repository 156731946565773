// src/lang/hr.js
export default {
    LoginPage: {
        "email_label": "E-mail",
        "password_label": "Lozinka",
        "login_button": "Prijavi se",
        "login_error": "Prijava nije uspjela. Provjerite e-mail i lozinku.",
        Tutorial: {
            "welcome_title": "Dobrodošli u GSOFT Demo!",
            "welcome_message": "Dobrodošli u demo našeg sustava! Slobodno istražite i iskusite sve funkcionalnosti koje smo implementirali.",
            "info_message": "Ako se ikada osjećate izgubljeno ili nešto ne razumijete, kliknite na ikonu info kako biste pronašli objašnjenje svake stranice i segmenta.",
            "demo_credentials": "Za testiranje možete koristiti: \n - E-mail: organization1@dwm.com, Lozinka: password \n - E-mail: organization2@dwm.com, Lozinka: password. \nOrganizacija 1 ima 6 tisuća korisnika, a organizacija 2 ima 4 tisuće korisnika, simulirajući interakciju korisnika između dvije organizacije.",
            "about_app": "Ova aplikacija je osmišljena kako bi pojednostavila upravljanje programima lojalnosti za maloprodaju integrirajući digitalne garancije za kupljene artikle i olakšavajući upravljanje kampanjama za promocije. Nudi snažne analitičke alate koji pomažu korisnicima da učinkovito prate tržišne trendove. Konsolidacijom upravljanja bodovima, popustima i garancijama u jedinstvenu platformu, korisnici mogu eliminirati poteškoće s rukovanjem papirnatim garancijama i računima. Ovaj inovativni pristup osigurava neometano iskustvo, omogućujući kupcima da lako upravljaju svojim nagradama za lojalnost i informacijama o garanciji—sve unutar jedne praktične aplikacije.",
            "about_app_title": "O aplikaciji",
            "info_title": "Informacije",
            "credentials_title": "Demo Login Podaci",
            question_title: "Imate pitanja?",
            question_message: "Ako imate bilo kakva pitanja o sustavu ili ovoj stranici, slobodno kliknite na ikonu upitnika i pošaljite svoje pitanje. Netko će vam se uskoro javiti.",
            development_message:"Ukoliko vidite ovu ikonu to znaci da je ova funkcija trenutno u razvoju."
        },
    },
    Navigation: {
        "dashboard": "Nadzorna ploča",
        "users": "Korisnici",
        "transactions": "Transakcije",
        "venues": "Mjesta",
        "items": "Stavke",
        "warranties": "Garancije",
        "warranties_templates": "Predlošci Garancija",
        "campaigns": "Kampanje",
        "pos_integration": "POS Integracija",
        "settings": "Postavke",
        authorizedServices: "Ovlašteni servisi",
        "logout": "Odjava"
    },
    Dashboard: {
        title: 'Nadzorna ploča',
        loading: 'Učitavanje...',
        error: 'Pogreška pri učitavanju podataka nadzorne ploče',
        average_revenue_per_user: 'Prosječni prihod po korisniku',
        average_selling_transaction_value: 'Prosječna vrijednost prodajne transakcije',
        belonging_users_count: 'Broj pripadajućih korisnika',
        campaigns_count: 'Broj kampanja',
        manufacturer_count: 'Broj proizvođača',
        revenue_value: 'Vrijednost prihoda',
        selling_transactions_count: 'Broj prodajnih transakcija',
        sold_items_count: 'Broj prodanih artikala',
        total_transaction_volume: 'Ukupni obujam transakcija',
        users_count: 'Broj korisnika',
        venues_count: 'Broj lokacija',
        Tutorial: {
            "title": "Upute za nadzornu ploču",
            "explanation_title": "Pregled",
            "explanation_message": "Ova nadzorna ploča prikazuje statistiku vaše organizacije od dana registracije. Možete prilagoditi prikaz prema svojim željama."
        }
    },
    Users: {
        title: "Korisnici",
        Tutorial: {
            title: "Upute za korisnike",
            explanation_title: "Pregled",
            explanation_message: "Postoje dvije vrste korisnika unutar organizacija: korisnici registrirani putem svoje organizacije i korisnici registrirani putem drugih organizacija. Zbog strožih pravila privatnosti u punoj verziji, neka polja neće biti vidljiva za korisnike koji ne pripadaju organizaciji. Statistički pregledi mogu se prilagoditi prema potrebama organizacije, omogućujući im da odaberu koje informacije žele pratiti.",
        },
        statistics: "Statistika",
        timeline: "Vremenska linija",
        usersList: "Lista korisnika",
        UserStatistics: {
            loading: "Učitavanje statistike...",
            noStatistics: "Nema dostupnih statistika.",
            overviewTitle: "Pregled statistike",
            totalBuyers: "Ukupno kupaca",
            totalBuyersOwn: "Ukupno kupaca (vlastitih)",
            totalBuyersGlobal: "Ukupno kupaca (globalno)",
            totalRevenueOwn: "Ukupni prihodi (vlastiti)",
            totalRevenueGlobal: "Ukupni prihodi (globalno)",
            topBuyersByItems: "Najbolji kupci po artiklima",
            items: "artikala",
            own: "Vlastiti",
            global: "Globalno",
            topBuyersByTotalSpent: "Najbolji kupci po ukupno potrošenom",
            salesByCountry: {
                title: "Prodaja po državama",
                userCount: "{{userCount}} korisnika" // Placeholder for user count
            }
        },
        Timeline: {
            title: "Registracije korisnika po mjesecu",
            loading: "Učitavanje podataka vremenske linije...",
            error: "Pogreška prilikom učitavanja podataka vremenske linije",
            xAxisTitle: "Mjesec",
            yAxisTitle: "Broj registracija"
        },
        UsersList: {
            title: "Korisnici",
            addUserButton: "Dodaj korisnika",
            legend: "Legenda:",
            ownUser: "Vlastiti korisnik",
            globalUser: "Globalni korisnik",
            loading: "Učitavanje...",
            noUsers: "Nema dostupnih korisnika.",
            modalTitle: "Dodaj korisnika",
            columns: {
                userId: "ID korisnika",
                name: "Ime",
                totalSpent: "Ukupno potrošeno",
                firstTransactionDate: "Datum prve transakcije",
                lastTransactionDate: "Datum posljednje transakcije",
                countOfItems: "Broj artikala",
                venuesCount: "Broj mjesta"
            }
        },
        UserCreate: {
            title: "Dodaj korisnika",
            name: "Ime",
            surname: "Prezime",
            email: "Email",
            country: "Država",
            phoneNumber: "Broj telefona",
            personalId: "Osobna iskaznica",
            gender: "Spol",
            selectCountry: "Odaberite državu",
            selectGender: "Odaberite spol",
            cancel: "Odustani",
            save: "Spremi",
            saving: "Spremam...",
            errorAlert: "Molimo ispravite pogreške ispod."
        }
    },
    PhoneInput: {
        selectCountryCode: "Odaberite pozivni broj",
        placeholder: "Unesite broj telefona"
    },
    UserInfoModal: {
        title: "Unesite svoje podatke",
        explanation: "Molimo unesite svoje ime/tvrtku i email. Vaš email će se koristiti samo za odgovaranje na pitanja koja imate o sustavu tijekom demo verzije.",
        name: "Ime/Tvrtka",
        namePlaceholder: "Unesite svoje ime ili naziv tvrtke",
        email: "Email",
        emailPlaceholder: "Unesite svoj email",
        cancel: "Odustani",
        save: "Spremi",
        explanationSub: "Ako trenutno nemate pitanja ili niste sigurni hoćete li postavljati pitanja, možete ostaviti ova polja prazna.",
        errors: {
            "generic": "Molimo ispravite pogreške ispod.",
            "nameRequired": "Ime je obavezno.",
            "emailRequired": "Email je obavezan."
        }
    },
    QuestionModal: {
        title: "Pošaljite svoje pitanje",
        explanation: "Molimo ispunite dolje navedene informacije.",
        name: "Ime/Tvrtka",
        namePlaceholder: "Unesite svoje ime ili naziv tvrtke",
        email: "Email",
        emailPlaceholder: "Unesite svoj email",
        question: "Pitanje",
        questionPlaceholder: "Unesite svoje pitanje ovdje",
        cancel: "Odustani",
        save: "Pošalji",
        saving: "Šaljem...",
        errors: {
            generic: "Molimo ispravite pogreške ispod.",
            questionRequired: "Pitanje je obavezno."
        }
    },
    Transactions: {
        title: "Transakcije",
        overview: "Pregled",
        statistics: "Statistika",
        progress: "Napredak",
        loading: "Učitavanje transakcija...",
        noTransactions: "Nema dostupnih transakcija.",
        Tutorial: {
            title: "Upute za transakcije",
            welcome_message: "Dobrodošli u upute za transakcije! Evo što trebate znati.",
            period_selection_title: "Odabir razdoblja",
            period_selection_message: "Možete odabrati razdoblje za detalje koji se prikazuju u desnom kutu stranice. Odabrano razdoblje dijeli se u svakoj kartici koju odaberete.",
            overview_tab_title: "Pregledna kartica",
            overview_tab_message: "Prva Pregledna kartica prikazuje transakcije grupirane prema datumu i sažetak tog razdoblja u zaglavljima.",
            statistics_tab_title: "Statistika kartica",
            statistics_tab_message: "Druga kartica prikazuje demografske podatke korisnika i interakcije s organizacijom, poput spolova, zemalja u kojima su kupljene stavke, zemalja iz kojih potječu korisnici, dobnih skupina korisnika i gradova.",
            progress_tab_title: "Napredak kartica",
            progress_tab_message: "Treća kartica prikazuje usporedbu odabranog razdoblja. Na primjer, ako je odabrano 'Jučer', uspoređuje podatke s danom prije jučer. Ako je odabrano 'Prošli tjedan', uspoređuje podatke s prethodnim tjednom, a slično za 'Ovaj mjesec', 'Ovo tromjesečje' i 'Ovu godinu.'",
            customization_title: "Prilagodba",
            customization_message: "Sve ove značajke mogu se prilagoditi i obogatiti dodatnim značajkama poput filtriranja i ciljanje."
        },
        TransactionsOverview: {
            loading: "Učitavanje transakcija...",
            noStatistics: "Nema dostupnih statistika transakcija.",
            distinctManufacturers: "Različiti proizvođači",
            totalPrice: "Ukupna cijena",
            totalRevenue: "Ukupni prihod",
            totalTransactions: "Ukupan broj transakcija",
            distinctBuyers: "Različiti kupci",
            columns: {
                date: "Datum",
                totalPrice: "Ukupna cijena",
                totalRevenue: "Ukupni prihod",
                averagePrice: "Prosječna cijena",
                averageRevenue: "Prosječni prihod",
                transactionCount: "Broj transakcija",
            },
            questionTitle: "Imate pitanja?",
            questionMessage: "Ako imate pitanja o transakcijama ili prikazanim podacima, kliknite ikonu upitnika kako biste poslali svoje pitanje.",
        },
        TransactionsStatistics: {
            loading: "Učitavanje demografskih podataka...",
            error: "Greška pri učitavanju demografskih podataka: {{error}}",
            title: "Demografija transakcija",
            genderDistribution: "Raspodjela spola",
            countryDistribution: "Raspodjela po zemljama",
            ageGroupDistribution: "Raspodjela dobnih skupina",
            cityDistribution: "Raspodjela po gradovima",
            residentialCountryDistribution: "Raspodjela po rezidencijalnim zemljama",
            questionTitle: "Imate pitanja?",
            questionMessage: "Ako imate pitanja o demografiji ili prikazanim podacima, kliknite ikonu upitnika kako biste poslali svoje pitanje.",
        },
        TransactionsProgress: {
            title: "Napredak transakcija",
            showingDataFor: "Prikaz podataka za: {{period}}",
            totals: "Zbrojevi",
            distinctManufacturers: "Različiti proizvođači",
            totalPrice: "Ukupna cijena",
            totalRevenue: "Ukupni prihod",
            totalTransactions: "Ukupan broj transakcija",
            distinctBuyerCount: "Različiti broj kupaca",
            demographics: "Demografija",
            ageGroups: "Dobne skupine",
            genderDistribution: "Raspodjela prema spolu",
            countryDistribution: "Raspodjela prema državama",
            cityDistribution: "Raspodjela prema gradovima",
            residentialCountryDistribution: "Raspodjela prema državama prebivališta",
            currentPeriod: "Trenutno razdoblje",
            previousPeriod: "Prethodno razdoblje",
            loading: "Učitavanje...",
            error: "Greška pri dohvaćanju podataka o napretku: {{error}}"
        },
    },
    Venues: {
        overview: "Poslovnica Pregled",
        Tutorial: {
            title: "Tutorial za Poslovnice",
            welcome_message: "Dobrodošli u odjeljak za Poslovnice! Ovdje možete učinkovito upravljati svojim poslovnicama.",
            venues_management_title: "Upravljajte svojim poslovnicama",
            venues_management_message: "Možete dodati, urediti ili izbrisati poslovnice prema potrebi.",
            exploration_title: "Istražite uvid u poslovnice",
            exploration_message: "Kliknite 'Uredi' na poslovnici kako biste vidjeli uvide i metrike koje možete pratiti."
        },
        columns: {
            id: "ID",
            name: "Ime",
            email: "Email",
            phone: "Telefon",
            country: "Zemlja",
            address: "Adresa",
            action: "Akcija"
        },
        search_placeholder: "Pretraži po imenu ili opisu",
        add_new_button: "Dodaj novu",
        VenueForm: {
            title_create: "Kreiraj novu poslovnicu",
            title_Edit: "Edituj poslovnicu {{venueName}}",
            basic_info: "Osnovne informacije",
            name_label: "Ime:",
            name_placeholder: "Ime poslovnice",
            description_label: "Opis:",
            description_placeholder: "Opis poslovnice",
            email_label: "Email:",
            email_placeholder: "Email poslovnice",
            website_label: "Web stranica:",
            website_placeholder: "Web stranica poslovnice",
            location_details: "Detalji lokacije",
            country_label: "Zemlja:",
            select_country: "Odaberite zemlju",
            address_label: "Adresa:",
            address_placeholder: "Adresa poslovnice",
            city_label: "Grad:",
            city_placeholder: "Grad poslovnice",
            latitude_label: "Širina:",
            latitude_placeholder: "Širina",
            longitude_label: "Dužina:",
            longitude_placeholder: "Dužina",
            social_media: "Linkovi društvenih mreža",
            twitter_label: "Twitter:",
            twitter_placeholder: "https://twitter.com/venue",
            facebook_label: "Facebook:",
            facebook_placeholder: "https://facebook.com/venue",
            instagram_label: "Instagram:",
            instagram_placeholder: "https://instagram.com/venue",
            save_button: "Spremi"
        },
        "VenueView": {
            "title": "Poslovnica: {{name}}",
            "tabs": {
                "details": "Detalji",
                "statistics": "Statistika",
                "integration": "Integracija",
                "edit": "Uredi Poslovnicu"
            },
            "Tutorial": {
                "details_title": "Zavičajna kartica",
                "details_message": "Ova kartica pruža pregled trenutnih podataka o Poslovnici.",
                "statistics_title": "Statistika Kartica",
                "statistics_message": "Ova kartica prikazuje transakcije grupirane po danu za odabrano razdoblje u gornjem desnom kutu. Moći ćete preuzeti detaljnije transakcije kao CSV datoteku, što će biti omogućeno kasnije.",
                "integration_title": "Integracija Kartica",
                "integration_message": "Ovaj dio omogućava vam generiranje API ključa i tajne. To će se koristiti ako želite integrirati svoj sustav s samo jednom Poslovnicom. Ako trebate integrirati cijeli sustav, provjerite odjeljak POS integracije.",
                "edit_title": "Uredi Kartica",
                "edit_message": "Na ovoj kartici možete izmijeniti podatke o Poslovnici. Pazite da sačuvate svoje promjene."
            },
            "VenueStatistics": {
                "date": "Datum",
                "total_price": "Ukupna Cijena",
                "total_revenue": "Ukupni Prihodi",
                "total_quantity": "Ukupna Količina",
                "average_price": "Prosječna Cijena",
                "average_revenue": "Prosječni Prihodi",
                "total_discount": "Ukupni Popust",
                "total_tax": "Ukupni Porez",
                "transaction_count": "Broj Transakcija",
                "total_transactions": "Totallne Transakcije"
            },
            "VenueIntegration": {
                "api_integration": "API Integracija",
                "api_key": "API Ključ",
                "generate_secret": "Generiraj API Tajnu",
                "generated_secret": "API Tajna: {{secret}}",
                "secret_timer": "Tajna će nestati za {{seconds}} sekundi."
            },
            "VenueDetails": {
                "title": "Detalji Poslovnice",
                "name_label": "Naziv:",
                "email_label": "Email:",
                "description_label": "Opis:",
                "website_label": "Web Stranica:",
                "phone_label": "Telefon:",
                "address_label": "Adresa:",
                "city_label": "Grad:",
                "country_label": "Država:",
                "latitude_label": "Geografska Širina:",
                "longitude_label": "Geografska Dužina:",
                "organization_label": "Organizacija:",
                "social_media": "Linkovi Društvenih Mreža",
                "twitter": "Twitter",
                "facebook": "Facebook",
                "instagram": "Instagram"
            }
        }

    },
    "Items": {
    "overview": "Pregled Stavki",
    "performance_insights": "Uvidi U Performanse",
    "warranty_alerts": "Upozorenja na Jamstvo",
    "Tutorial": {
        "title": "Tutorial o Stavkama",
        "message": "Ovaj tutorial će vas provesti kroz funkcionalnosti sekcije Stavke."
    },
        "Overview": {
            "title": "Pregled Stavki",
            "model_number": "Broj Modela",
            "product_name": "Ime Proizvoda",
            "current_holding_count": "Trenutni Broj Držanja",
            "sold_items_count": "Broj Prodani Stavki",
            "release_year": "Godina Izdavanja",
            "warranty_coverage": "Pokriće Jamstva",
            "actions": "Akcije",
            "view": "Pregled"
        },
        "SearchFilters": {
            "search_placeholder": "Pretraži po broju modela, serijskom broju ili imenu proizvoda",
            "select_manufacturer": "Odaberi Proizvođača",
            "search_button": "Pretraži"
        },
        "ViewItem": {
            "tabs": {
                "description": "Opis",
                "transactions": "Transakcije",
                "charts": "Grafikon",
                "sold_items": "Prodani Artikli"
            },
            "Tutorial": {
                "welcome_message": "Dobrodošli u upute za prikaz stavke!",
                "tabs": {
                    "description": {
                        "title": "Kartica Opis",
                        "message": "Ovdje možete vidjeti detaljne informacije o stavci."
                    },
                    "transactions": {
                        "title": "Kartica Transakcije",
                        "message": "Ova kartica prikazuje sve transakcije povezane sa stavkom.",
                        "additional_message": "Imajte na umu da će grafikoni, vremenske linije i detalji stavki po demografiji korisnika biti dostupni u budućim ažuriranjima."
                    }
                },
            }
        },
        "ItemDescription": {
            "no_details": "Nema dostupnih detalja o artiklu",
            "model_number": "Broj modela:",
            "description": "Opis:",
            "release_year": "Godina izdanja:",
            "warranty_duration": "Trajanje jamstva:",
            "warranty_coverage": "Pokriće jamstva:",
            "documentation_url": "URL dokumentacije:",
            "view_documentation": "Pogledajte dokumentaciju",
            "technical_specifications": "Tehničke specifikacije",
            "no_technical_specifications": "Nema dostupnih tehničkih specifikacija",
            "year": "{{count}} godina",
            "month": "{{count}} mjesec"
        },
        "TransactionsTable": {
            "title": "Transakcije za Artikl",
            "date": "Datum",
            "buyer": "Kupac",
            "venue": "Mjesto",
            "quantity": "Količina",
            "price": "Cijena",
            "revenue": "Prihod",
            "payment_method": "Način Plačanja",
            "transaction_type": "Tip Transakcije",
            "shipping_cost": "Trošak Dostave",
            "warranty": "Jamstvo",
            "n_a": "Nema"
        }
    },
    Warranties: {
        warrantiesOverview: "Pregled jamstava",
        warrantyTemplates: "Predlošci jamstava",
        searchWarranties: "Pretraživanje jamstava",
        noWarranties: "Nema pronađenih jamstava.",
        noTemplates: "Nema pronađenih predložaka.",
        searchByWarrantyName: "Pretraži po nazivu jamstva",
        enterWarrantyName: "Unesite naziv jamstva",
        createWarrantyTemplate: "Izradite predložak jamstva",
        templateName: "Naziv predloška",
        templateDescription: "Opis predloška",
        selectManufacturer: "Odaberite proizvođača",
        selectCategory: "Odaberite kategoriju",
        createNewTemplate: "Izradite novi predložak",
        cancel: "Otkaži",
        createTemplate: "Izradite predložak",
        warrantyName: "Naziv jamstva",
        status: "Status",
        expirationDate: "Datum isteka",
        coverageDetails: "Detalji pokrića",
        termsAndConditions: "Uvjeti i odredbe",
        documentation: "Dokumentacija",
        view: "Pogledaj",
        description: "Opis",
        manufacturer: "Proizvođač",
        category: "Kategorija",
        trackStatusTitle: "Praćenje statusa jamstva",
        trackStatusMessage: "Možete pratiti status svojih jamstava kroz kartice pregleda i predložaka.",
        searchWarrantiesTitle: "Pretraživanje jamstava",
        searchWarrantiesMessage: "Upotrijebite funkciju pretraživanja za filtriranje jamstava po nazivu.",
        documentationTitle: "Dokumentacija",
        documentationMessage: "Osigurajte da imate potrebnu dokumentaciju za zahtjeve.",
        manageTemplatesTitle: "Upravljanje predlošcima",
        manageTemplatesMessage: "Jednostavno kreirajte i upravljajte predlošcima jamstava.",
        welcomeMessage: "Dobrodošli u odjeljak za upravljanje jamstvima.",
        createTemplateTitle: "Izrada predložaka jamstava",
        createTemplateMessage: "Možete izraditi predloške za različite vrste jamstava.",
    },
    AuthorizedServices: {
        overview: "Pregled ovlaštenih servisnih centara",
        addNewService: "Dodaj novi servis",
        noServices: "Nema dostupnih ovlaštenih servisa.",
        createService: "Kreiraj ovlašteni servis",
        serviceName: "Naziv servisa",
        serviceAddress: "Adresa servisa",
        servicePhone: "Telefon servisa",
        cancel: "Odustani",
        create: "Kreiraj",
    },
    "POSIntegration": {
        "pageTitle": "Integracija POS-a",
        "apiTitle": "API za integraciju POS sustava",
        "apiDescription": "API za integraciju POS sustava s našom aplikacijom.",
        "generateApiKeySummary": "Generiraj API ključ",
        "apiKeyGenerated": "API ključ generiran",
        "importItemsSummary": "Uvezi stavke",
        "itemsImportedSuccessfully": "Stavke uspješno uvezene"
    },
    "Campaigns": {
        "name": "Ime",
        "description": "Opis",
        "startDate": "Datum početka",
        "endDate": "Datum završetka",
        "goalDescription": "Opis cilja",
        "budget": "Proračun",
        "actions": "Akcije",
        "edit": "Uredi",
        "delete": "Izbriši",
        "addNew": "Dodaj novu kampanju",
        "search": "Pretraži kampanje...",
        "noWarranties": "Nema pronađenih kampanja.",
        "Tutorial": {
            "welcomeMessage": "Dobrodošli u upravljanje kampanjama!",
            "createCampaignTitle": "Kreiraj novu kampanju",
            "createCampaignMessage": "Ovdje možete kreirati novu kampanju prema vašim poslovnim potrebama.",
            "manageCampaignsTitle": "Upravljajte svojim kampanjama",
            "manageCampaignsMessage": "U ovom dijelu možete pregledavati, uređivati i brisati svoje postojeće kampanje. Koristite pretraživač za brzo pronalaženje određene kampanje.",
            "tutorialTitle": "Tutorial za kampanje",
            "tutorialMessage": "Ovaj tutorial će vas voditi kroz proces učinkovito upravljanje kampanjama."
        },
        "CampaignCreate": {
            "title": "Kreiraj novu kampanju",
            "name": "Naziv kampanje",
            "description": "Opis",
            "startDate": "Datum početka",
            "endDate": "Datum završetka",
            "budget": "Proračun",
            "goalDescription": "Opis cilja",
            "createCampaign": "Kreiraj kampanju",
            "cancel": "Odustani",
            "loading": "Kreiranje kampanje..."
        },
        "CampaignEdit": {
            "editCampaignTitle": "Uredi Kampanju",
            "initialReach": "Početni Dohvat",
            "promotion": "Promocija",
            "reach": "Dohvat",
            "type": "Tip",
            "price": "Cijena",
            "suggestions": "Preporuke",
            "noSuggestions": "Nema dostupnih preporuka.",
            "updateCampaign": "Ažuriraj Kampanju",
            "calculations": "Analiza i Preporuke",
            "calculate": "Izračunaj",
            "reaches": "Dohvati",
            "prices": "Cijene",
            "welcomeMessage": "Dobrodošli na stranicu za Uređivanje Kampanje!",
            "managePromotions": "Upravljajte svojim promocijama",
            "successUpdate": "Kampanja je uspješno ažurirana",
            "failedUpdate": "Neuspjeh u ažuriranju kampanje",
            "failedCalculation": "Neuspjeh u izračunu podataka kampanje",
            "goalDescription": "Opis Cilja",
            "budget": "Proračun",
            "startDate": "Datum Početka",
            "endDate": "Datum Zatvaranja",
            "description": "Opis",
            "placeholderGoalDescription": "Unesite opis cilja kampanje",
            "placeholderBudget": "Unesite proračun kampanje",
            "placeholderStartDate": "Odaberite datum početka",
            "placeholderEndDate": "Odaberite datum zatvaranja",
            "placeholderDescription": "Unesite opis kampanje",
            "placeholderName": "Unesite naziv kampanje",
            "name": "Naziv Kampanje",
            "revenue": "Prihod",
            "strategy": "Strategija",
            "breakdown": "Razrada Kampanje",
            "target": "Dohvat Cilja {index}",
            "promotionLabel": "Promocija {index}",
            "initialReachPromotion": "Početni Dohvat: {value}",
            "strategyReach": "Strategija {index} (Tip: {type}) Dohvat: {value}",
            "pricesLabel": "Cijene",
            "priceStrategy": "Strategija {index} (Tip: {type}) Cijena: ${price}",
            "suggestionsForPromotion": "Preporuke za Promociju {index}:",
            "noSuggestionsAvailable": "Nema dostupnih preporuka.",
            "Tutorial": {
                "tutorialTitle": "Upute za uređivanje kampanje",
                "welcomeMessage": "Dobrodošli u upute za uređivanje kampanje! Ovdje ćete naučiti kako učinkovito upravljati svojim kampanjama.",
                "campaignSettingsTitle": "Pregled postavki kampanje",
                "campaignSettingsMessage": "U ovom odjeljku možete konfigurirati osnovne postavke svoje kampanje.",
                "promotionsListTitle": "Popis promocija",
                "promotionsListMessage": "Ovaj odjeljak prikazuje sve promocije povezane s vašom kampanjom.",
                "promotionEditInstruction": "Kliknite na promociju da biste uredili njezine detalje.",
                "addPromotionInstruction": "Koristite gumb 'Dodaj promociju' za stvaranje nove promocije.",
                "conditionsTitle": "Uvjeti",
                "conditionsMessage": "Uvjeti su pravila koja određuju kada je promocija aktivna.",
                "conditionsInstruction1": "Možete dodati više uvjeta za promociju.",
                "conditionsInstruction2": "Osigurajte da su uvjeti ispunjeni prije nego što promocija krene.",
                "strategiesTitle": "Strategije",
                "strategiesMessage": "Strategije definiraju kako će se promocija provoditi.",
                "strategiesInstruction1": "Možete dodati više strategija za promociju.",
                "strategiesInstruction2": "Odaberite najbolje strategije na temelju ciljeva svoje kampanje.",
                "targetsTitle": "Ciljevi",
                "targetsMessage": "Ciljevi su segmenti publike koje želite dosegnuti svojom promocijom.",
                "targetsInstruction1": "Pažljivo definirajte svoju ciljnu publiku.",
                "targetsInstruction2": "Možete dodati više ciljeva za svaku promociju.",
                "incentivesTitle": "Poticaji",
                "incentivesMessage": "Poticaji su pogodnosti koje nudite kako biste potaknuli sudjelovanje kupaca.",
                "incentivesInstruction1": "Razmotrite koji će poticaji biti najprivlačniji vašoj publici.",
                "incentivesInstruction2": "Možete ponuditi više vrsta poticaja za svaku promociju.",
                "schedulesTitle": "Rasporedi",
                "schedulesMessage": "Rasporedi određuju vrijeme vaših promocija.",
                "schedulesInstruction1": "Postavite datume početka i završetka za svoje promocije.",
                "schedulesInstruction2": "Prilagodite svoje rasporede na temelju izvedbe kampanje."
            }
        },
        "PromotionEdit": {
            "basicInformation": "Osnovne informacije",
            "targets": "Ciljevi({{count}})",
            "strategies": "Strategije({{count}})",
            "conditions": "Uvjeti({{count}})",
            "incentives": "Incentivi({{count}})",
            "addTarget": "Dodaj cilj",
            "addStrategy": "Dodaj strategiju",
            "addCondition": "Dodaj uvjet",
            "addIncentive": "Dodaj poticaj",
            "editPromotion": "Uredi promociju: {{promotionName}}",
            "promotionName": "Promocija"
        },
        "PromotionList": {
            "promotionsList": "Popis Promocija",
            "addPromotion": "Dodaj Promociju",
            "promotion": "Promocija {{index}}"
        }
    }
};
