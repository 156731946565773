import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './AppWithRouter';
import reportWebVitals from './reportWebVitals';
import './lang/i18n';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {SettingsProvider} from "./components/SettingsContext";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <SettingsProvider>
        <Root />
    </SettingsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
