import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const DiscountPercentageComponent = ({ value, operator, onChange }) => {
    return (
        <FormGroup>
            {/* Operator Selection */}
            <Label for="operator">Operator</Label>
            <Input
                type="select"
                id="operator"
                value={operator || ''}
                onChange={(e) => onChange('operator', e.target.value)}
            >
                <option value="">Select Operator</option>
                <option value="=">=</option>
                <option value="<">{"<"}</option>
                <option value=">">{">"}</option>
            </Input>

            {/* Discount Percentage Input */}
            <Label className="mt-2" for="value">Discount Percentage</Label>
            <Input
                type="number"
                id="value"
                value={value || ''}
                onChange={(e) => onChange('value', e.target.value)}
                placeholder="Enter discount percentage"
                min="0"
                max="100" // Assuming that the discount percentage is between 0 and 100
            />
        </FormGroup>
    );
};

export default DiscountPercentageComponent;
