import React, { useState } from 'react';
import TransactionsOverview from './TransactionsOverview';
import TransactionsStatistics from './TransactionsStatistics';
import TransactionsProgress from './TransactionsProgress';
import PeriodSelectBox from "../../../global/PeriodSelectBox";
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'; // Import icons
import { Modal } from 'react-bootstrap';
import QuestionModal from "../../../global/QuestionModal"; // Import Modal from react-bootstrap

const Transactions = () => {
    const { t } = useTranslation(); // Initialize translation hook
    const [activeTab, setActiveTab] = useState('overview');
    const [selectedPeriod, setSelectedPeriod] = useState('yesterday');
    const [showQuestionModal, setShowQuestionModal] = useState(false); // State for the question modal
    const [showTutorialModal, setShowTutorialModal] = useState(false); // State for the tutorial modal

    const handlePeriodChange = (e) => {
        setSelectedPeriod(e.target.value);
    };

    const handleShowQuestionModal = () => setShowQuestionModal(true);
    const handleCloseQuestionModal = () => setShowQuestionModal(false);

    const handleShowTutorialModal = () => setShowTutorialModal(true);
    const handleCloseTutorialModal = () => setShowTutorialModal(false);

    return (
        <div className="nav-tabs-shadow nav-align-top">
            <div className="d-flex justify-content-end mb-3">
                <PeriodSelectBox value={selectedPeriod} onChange={handlePeriodChange} />
                <button
                    className="btn btn-link"
                    onClick={handleShowQuestionModal}
                    style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)', marginLeft: '0.5rem' }}
                >
                    <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '1.5rem' }} />
                </button>
                <button
                    className="btn btn-link"
                    onClick={handleShowTutorialModal}
                    style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)', marginLeft: '0.5rem' }}
                >
                    <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '1.5rem' }} />
                </button>
            </div>
            <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                    <button
                        type="button"
                        className={`nav-link ${activeTab === 'overview' ? 'active' : ''}`}
                        role="tab"
                        onClick={() => setActiveTab('overview')}
                    >
                        {t('Transactions.overview')}
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        type="button"
                        className={`nav-link ${activeTab === 'statistics' ? 'active' : ''}`}
                        role="tab"
                        onClick={() => setActiveTab('statistics')}
                    >
                        {t('Transactions.statistics')}
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        type="button"
                        className={`nav-link ${activeTab === 'progress' ? 'active' : ''}`}
                        role="tab"
                        onClick={() => setActiveTab('progress')}
                    >
                        {t('Transactions.progress')}
                    </button>
                </li>
            </ul>
            <div className="tab-content">
                <div className={`tab-pane fade ${activeTab === 'overview' ? 'show active' : ''}`} role="tabpanel">
                    <TransactionsOverview period={selectedPeriod} />
                </div>
                <div className={`tab-pane fade ${activeTab === 'statistics' ? 'show active' : ''}`} role="tabpanel">
                    <TransactionsStatistics period={selectedPeriod} />
                </div>
                <div className={`tab-pane fade ${activeTab === 'progress' ? 'show active' : ''}`} role="tabpanel">
                    <TransactionsProgress period={selectedPeriod} />
                </div>
            </div>

            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={handleCloseQuestionModal}
                param="transactions_overview" // Pass the parameter for the question part
            />

            {/* Tutorial Modal */}
            {showTutorialModal && (
                <Modal show={showTutorialModal} onHide={handleCloseTutorialModal} size="xl" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Transactions.Tutorial.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ height: 'auto' }}>
                            <div className="welcome-message" style={{ marginBottom: '1.5rem' }}>
                                <p style={{ fontSize: '1.5rem', lineHeight: '1.6', color: 'rgba(130, 22, 146, 1)' }}>
                                    {t('Transactions.Tutorial.welcome_message')}
                                </p>
                            </div>

                            <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                                <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                    {t('Transactions.Tutorial.period_selection_title', { defaultValue: "Period Selection" })}
                                </h5>
                                <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                    {t('Transactions.Tutorial.period_selection_message', {
                                        defaultValue: "You can choose the period for the details shown in the right corner of the page. The selected period is shared across each tab you select."
                                    })}
                                </p>
                            </div>

                            <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                                <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                    {t('Transactions.Tutorial.overview_tab_title', { defaultValue: "Overview Tab" })}
                                </h5>
                                <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                    {t('Transactions.Tutorial.overview_tab_message', {
                                        defaultValue: "The first Overview tab shows the transactions grouped by date and summarizes that period in the headers."
                                    })}
                                </p>
                            </div>

                            <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                                <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                    {t('Transactions.Tutorial.statistics_tab_title', { defaultValue: "Statistics Tab" })}
                                </h5>
                                <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                    {t('Transactions.Tutorial.statistics_tab_message', {
                                        defaultValue: "The second tab shows user demographics and interactions with the organization, such as genders, countries where items were purchased, the countries where users are from, age groups of users, and cities."
                                    })}
                                </p>
                            </div>

                            <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                                <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                    {t('Transactions.Tutorial.progress_tab_title', { defaultValue: "Progress Tab" })}
                                </h5>
                                <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                    {t('Transactions.Tutorial.progress_tab_message', {
                                        defaultValue: "The third tab shows a comparison of the selected period. For instance, if 'Yesterday' is selected, it compares data with the day before yesterday. If 'Last Week' is selected, it compares data with the previous week, and similarly for 'This Month,' 'This Quarter,' and 'This Year.'"
                                    })}
                                </p>
                            </div>

                            <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                                <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                    {t('Transactions.Tutorial.customization_title', { defaultValue: "Customization" })}
                                </h5>
                                <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                    {t('Transactions.Tutorial.customization_message', {
                                        defaultValue: "All these features can be customized and enriched even more with upcoming features such as filtering and targeting."
                                    })}
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            )}
        </div>
    );
};

export default Transactions;
