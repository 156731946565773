import React, { useContext, useEffect, useState } from 'react';
import AsyncSelect from 'react-select';
import { FormGroup, Label, Input } from 'reactstrap';
import { fetchItems } from "../../../../../services/Http/Api";
import { SettingsContext } from "../../../../SettingsContext";

const BundleItemsComponent = ({ value, operator, onChange, target, type, attribute }) => {
    const { siteSettings } = useContext(SettingsContext);
    const [itemOptions, setItemOptions] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    // Get supported operators based on target, type, and attribute
    let supportedOperators = [];
    if (target === 'incentives') {
        const incentiveTypes = siteSettings?.getIncentiveTypes() || [];
        const selectedIncentiveType = incentiveTypes.find(incentive => incentive.key === type);

        if (selectedIncentiveType) {
            const selectedAttribute = selectedIncentiveType.attributes.find(attr => attr.key === attribute);
            if (selectedAttribute) {
                supportedOperators = selectedAttribute.supportedOperators || [];
            }
        }
    }

    // Fetch all items once on component mount
    useEffect(() => {
        const fetchInitialItems = async () => {
            try {
                const response = await fetchItems({ search: '', perPage: 100, page: 1 });
                const options = response.data.map(item => ({
                    value: item.id,
                    label: item.product_name,
                }));
                setItemOptions(options);

                if (value) {
                    const parsedValues = JSON.parse(value);
                    const preSelectedItems = options.filter(option => parsedValues.includes(option.value));
                    setSelectedItems(preSelectedItems);
                }
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };

        fetchInitialItems();
    }, []);

    const handleSelectChange = (selected) => {
        const selectedValues = selected ? selected.map(opt => opt.value) : [];
        setSelectedItems(selected);
        onChange('value', JSON.stringify(selectedValues));
    };

    return (
        <FormGroup>
            <Label for="operator">Operator</Label>
            <Input
                type="select"
                id="operator"
                value={operator || ''}
                onChange={(e) => onChange('operator', e.target.value)}
            >
                <option value="">Select Operator</option>
                {supportedOperators.map(op => (
                    <option key={op} value={op}>{op}</option>
                ))}
            </Input>

            <Label className="mt-2" for="value">Bundle Items</Label>
            <AsyncSelect
                id="value"
                isMulti
                cacheOptions
                defaultOptions={itemOptions}
                value={selectedItems}
                onChange={handleSelectChange}
                placeholder="Search and select items"
                noOptionsMessage={() => "No items available"}
            />
        </FormGroup>
    );
};

export default BundleItemsComponent;
