import React from 'react';
import { useTranslation } from 'react-i18next';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const POSIntegration = () => {
    const { t } = useTranslation(); // Initialize translation hook

    const swaggerSpec = {
        swagger: "2.0",
        info: {
            title: t('POSIntegration.apiTitle'), // Translated API title
            version: "1.0.0",
            description: t('POSIntegration.apiDescription') // Translated API description
        },
        host: "demo.gsoft.hr",
        basePath: "/api/integration",
        paths: {
            "/generateApiKey": {
                post: {
                    summary: t('POSIntegration.generateApiKeySummary'), // Translated summary
                    parameters: [
                        {
                            name: "organizationId",
                            in: "body",
                            required: true,
                            schema: {
                                type: "string"
                            }
                        }
                    ],
                    responses: {
                        200: {
                            description: t('POSIntegration.apiKeyGenerated'), // Translated description
                            schema: {
                                type: "object",
                                properties: {
                                    apiKey: {
                                        type: "string"
                                    },
                                    secret: {
                                        type: "string"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "/importItems": {
                post: {
                    summary: t('POSIntegration.importItemsSummary'), // Translated summary
                    parameters: [
                        {
                            name: "items",
                            in: "body",
                            required: true,
                            schema: {
                                type: "array",
                                items: {
                                    type: "object",
                                    properties: {
                                        id: { type: "string" },
                                        name: { type: "string" },
                                        price: { type: "number" },
                                        category: { type: "string" }
                                    }
                                }
                            }
                        }
                    ],
                    responses: {
                        200: {
                            description: t('POSIntegration.itemsImportedSuccessfully'), // Translated description
                            schema: {
                                type: "object",
                                properties: {
                                    success: { type: "boolean" },
                                    message: { type: "string" }
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    return (
        <div>
            <h1>{t('POSIntegration.pageTitle')}</h1> {/* Translated page title */}
            <SwaggerUI spec={swaggerSpec} />
        </div>
    );
};

export default POSIntegration;
