import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const UserInfoModal = ({ show, onClose }) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});

    const handleSubmit = () => {
        const newErrors = {};
        if (!name) newErrors.name = t('UserInfoModal.errors.nameRequired');
        if (!email) newErrors.email = t('UserInfoModal.errors.emailRequired');

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            localStorage.setItem('contactEmail', email);
            localStorage.setItem('contactName', name);
            onClose(); // Close the modal after saving data
        }
    };

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('UserInfoModal.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t('UserInfoModal.explanation')}</p>

                <p>{t('UserInfoModal.explanationSub')}</p>
                {Object.keys(errors).length > 0 && (
                    <div className="alert alert-danger">{t('UserInfoModal.errors.generic')}</div>
                )}
                <Form>
                    <Form.Group controlId="formName">
                        <Form.Label>{t('UserInfoModal.name')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('UserInfoModal.namePlaceholder')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label>{t('UserInfoModal.email')}</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder={t('UserInfoModal.emailPlaceholder')}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {t('UserInfoModal.cancel')}
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {t('UserInfoModal.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserInfoModal;
