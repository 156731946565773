import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import QuestionModal from "../../../global/QuestionModal";
import VenuesList from "./VenuesList";

const Venues = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('1');
    const [showTutorialModal, setShowTutorialModal] = useState(false); // State for the tutorial modal
    const [showQuestionModal, setShowQuestionModal] = useState(false); // State for the question modal

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleCloseTutorialModal = () => {
        setShowTutorialModal(false);
        localStorage.setItem('tutorialSeen', 'true'); // Save to local storage
    };

    return (
        <div className="venues-page">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => toggle('1')}
                    >
                        {t('Venues.overview')}
                    </NavLink>
                </NavItem>
            </Nav>

            <div className="d-flex justify-content-end mb-3">
                <Button variant="link" onClick={() => setShowTutorialModal(true)} style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)' }}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </Button>
                <Button variant="link" onClick={() => setShowQuestionModal(true)} style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)', marginLeft: '1rem' }}>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </Button>
            </div>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <VenuesList />
                </TabPane>
            </TabContent>

            {/* Tutorial Modal */}
            <Modal show={showTutorialModal} onHide={handleCloseTutorialModal} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Venues.Tutorial.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: 'auto' }}>
                        <div className="welcome-message" style={{ marginBottom: '1.5rem' }}>
                            <p style={{ fontSize: '1.5rem', lineHeight: '1.6', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Venues.Tutorial.welcome_message')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Venues.Tutorial.venues_management_title')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Venues.Tutorial.venues_management_message')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Venues.Tutorial.exploration_title')}
                            </h5>
                            <p style={{ fontSize: '1.2rem', lineHeight: '1.6' }}>
                                {t('Venues.Tutorial.exploration_message')}
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={() => setShowQuestionModal(false)}
                param="venues" // Parameter for the question modal
            />
        </div>
    );
};

export default Venues;
