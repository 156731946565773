import React, { useState, useEffect } from 'react';
import UsersList from './UsersList';
import Statistics from './Statistics';
import Timeline from './Timeline';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { Modal } from 'react-bootstrap'; // Import Modal from react-bootstrap
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Users = () => {
    const { t } = useTranslation(); // Initialize translation hook
    const [showUsersTutorialModal, setShowUsersTutorialModal] = useState(!localStorage.getItem('UsersTutorialSeen'));

    useEffect(() => {
        // Ensure modal is shown only on the first visit
        if (!localStorage.getItem('UsersTutorialSeen')) {
            setShowUsersTutorialModal(true);
        }
    }, []);

    const handleCloseModal = () => {
        setShowUsersTutorialModal(false);
        localStorage.setItem('UsersTutorialSeen', 'true'); // Save to local storage
    };

    const handleShowModal = () => {
        setShowUsersTutorialModal(true);
    };

    return (
        <div className="nav-tabs-shadow nav-align-top">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h1>{t('Users.title')}</h1>
                <button
                    className="btn btn-link"
                    onClick={handleShowModal}
                    style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)' }}
                >
                    <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '1.5rem' }} />
                </button>
            </div>
            <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                    <button
                        type="button"
                        className="nav-link active"
                        role="tab"
                        data-bs-toggle="tab"
                        data-bs-target="#navs-top-statistics"
                        aria-controls="navs-top-statistics"
                        aria-selected="true"
                    >
                        {t('Users.statistics')} {/* Use translation */}
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        type="button"
                        className="nav-link"
                        role="tab"
                        data-bs-toggle="tab"
                        data-bs-target="#navs-top-timeline"
                        aria-controls="navs-top-timeline"
                        aria-selected="false"
                    >
                        {t('Users.timeline')} {/* Use translation */}
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        type="button"
                        className="nav-link"
                        role="tab"
                        data-bs-toggle="tab"
                        data-bs-target="#navs-top-users-list"
                        aria-controls="navs-top-users-list"
                        aria-selected="false"
                    >
                        {t('Users.usersList')} {/* Use translation */}
                    </button>
                </li>
            </ul>
            <div className="tab-content">
                <div className="tab-pane fade show active" id="navs-top-statistics" role="tabpanel">
                    <Statistics />
                </div>
                <div className="tab-pane fade" id="navs-top-timeline" role="tabpanel">
                    <Timeline />
                </div>
                <div className="tab-pane fade" id="navs-top-users-list" role="tabpanel">
                    <UsersList />
                </div>
            </div>

            {/* Users Tutorial Modal */}
            <Modal show={showUsersTutorialModal} onHide={handleCloseModal} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Users.Tutorial.title', { defaultValue: "Users Tutorial" })}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: 'auto' }}>
                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Users.Tutorial.explanation_title', { defaultValue: "Overview" })}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Users.Tutorial.explanation_message', {
                                    defaultValue: "There are two types of users for organizations: Users that have been registered through their organization and users that are registered by other organizations. Due to privacy in the full version, some fields will not be shown where the privacy of non-belonging users is stricter than the users they have added. These statistics overview can be customized depending on what insights the organization wants to have."
                                })}
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Users;
