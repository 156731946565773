// PromotionEdit.js
import React, {useEffect} from 'react';
import { Button } from 'reactstrap';
import { Accordion } from 'react-bootstrap';
import ConditionComponent from './ConditionComponent';
import TargetComponent from './TargetComponent';
import IncentiveComponent from './IncentiveComponent';
import PromotionSettings from "./PromotionSettings";
import StrategyComponent from "./StrategyComponent";
import { useTranslation } from 'react-i18next';

const PromotionEdit = ({
                           promotion,
                           selectedPromotionIndex,
                           setCampaign,
                           onUpdatePromotion,
                           onAddCondition,
                           onAddTarget,
                           onAddStrategy,
                           onAddIncentive
                       }) => {
    const { t } = useTranslation(); // Initialize translation hook

    // Handle input changes

    const handleInputChange = (field, value) => {
        setCampaign(prevCampaign => {
            const updatedPromotions = [...prevCampaign.promotions];

            if (field.startsWith("conditions")) {
                const [_, indexStr, subField] = field.split(".");
                const index = parseInt(indexStr, 10);
                const updatedCondition = {
                    ...updatedPromotions[selectedPromotionIndex].conditions[index],
                    [subField]: value
                };
                updatedPromotions[selectedPromotionIndex].conditions[index] = updatedCondition;
            } else if (field.startsWith("targets")) {
                const [_, indexStr, subField] = field.split(".");
                const index = parseInt(indexStr, 10);
                const updatedTarget = {
                    ...updatedPromotions[selectedPromotionIndex].targets[index],
                    [subField]: value
                };
                updatedPromotions[selectedPromotionIndex].targets[index] = updatedTarget;
            } else if (field.startsWith("incentives")) {
                const [_, indexStr, subField] = field.split(".");
                const index = parseInt(indexStr, 10);
                const updatedIncentive = {
                    ...updatedPromotions[selectedPromotionIndex].incentives[index],
                    [subField]: value
                };
                updatedPromotions[selectedPromotionIndex].incentives[index] = updatedIncentive;
            } else if (field.startsWith("strategies")) {
                const [_, indexStr, subField] = field.split(".");
                const index = parseInt(indexStr, 10);
                const updatedStrategy = {
                    ...updatedPromotions[selectedPromotionIndex].strategies[index],
                    [subField]: value
                };
                updatedPromotions[selectedPromotionIndex].strategies[index] = updatedStrategy;
            }else {
                updatedPromotions[selectedPromotionIndex] = {
                    ...updatedPromotions[selectedPromotionIndex],
                    [field]: value
                };
            }

            return {
                ...prevCampaign,
                promotions: updatedPromotions
            };
        });
    };

    return (
        <>
            <h4>{t('Campaigns.PromotionEdit.editPromotion', { promotionName: promotion.name || t('Campaigns.PromotionEdit.promotionName') })}</h4>
            <Accordion defaultActiveKey="0">
                {/* Basic Information Section */}
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t('Campaigns.PromotionEdit.basicInformation')}</Accordion.Header>
                    <Accordion.Body>
                        <PromotionSettings promotion={promotion} onChange={handleInputChange} />
                    </Accordion.Body>
                </Accordion.Item>
                {/* Targets Section */}
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('Campaigns.PromotionEdit.targets', { count: promotion.targets.length })}</Accordion.Header>
                    <Accordion.Body>
                        {promotion.targets.map((target, index) => (
                            <TargetComponent
                                key={`target_${index}`}
                                target={target}
                                onChange={(field, value) => handleInputChange(`targets.${index}.${field}`, value)}
                            />
                        ))}
                        <Button color="secondary" onClick={onAddTarget}>
                            {t('Campaigns.PromotionEdit.addTarget')}
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>

                {/* Strategies Section */}
                <Accordion.Item eventKey="2">
                    <Accordion.Header>{t('Campaigns.PromotionEdit.strategies', { count: promotion.strategies.length })}</Accordion.Header>
                    <Accordion.Body>
                        {promotion.strategies.map((strategy, index) => (
                            <StrategyComponent
                                key={`strategy_${index}`}
                                strategy={strategy}
                                onChange={(field, value) => handleInputChange(`strategies.${index}.${field}`, value)}
                            />
                        ))}
                        <Button color="secondary" onClick={onAddStrategy}>
                            {t('Campaigns.PromotionEdit.addStrategy')}
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>
                {/* Conditions Section */}
                <Accordion.Item eventKey="3">
                    <Accordion.Header>{t('Campaigns.PromotionEdit.conditions', { count: promotion.conditions.length })}</Accordion.Header>
                    <Accordion.Body>
                        {promotion.conditions.map((condition, index) => (
                            <ConditionComponent
                                key={`condition_${index}`}
                                condition={condition}
                                onChange={(field, value) => handleInputChange(`conditions.${index}.${field}`, value)}
                            />
                        ))}
                        <Button color="secondary" onClick={onAddCondition}>
                            {t('Campaigns.PromotionEdit.addCondition')}
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>
                {/* Incentives Section */}
                <Accordion.Item eventKey="4">
                    <Accordion.Header>{t('Campaigns.PromotionEdit.incentives', { count: promotion.incentives.length })}</Accordion.Header>
                    <Accordion.Body>
                        {promotion.incentives.map((incentive, index) => (
                            <IncentiveComponent
                                key={`incentive_${index}`}
                                incentive={incentive}
                                onChange={(field, value) => handleInputChange(`incentives.${index}.${field}`, value)}
                            />
                        ))}
                        <Button color="secondary" onClick={onAddIncentive}>
                            {t('Campaigns.PromotionEdit.addIncentive')}
                        </Button>
                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>
        </>
    );
};

export default PromotionEdit;
