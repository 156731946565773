// models/Campaign.js


import Promotion from "./Promotion/Promotion";

class Campaign {
    constructor(data = {}) {
        this.id = data.id || null;
        this.name = data.name || '';
        this.description = data.description || '';
        this.startDate = data.start_date || '';
        this.endDate = data.end_date || '';
        this.goalDescription = data.goal_description || '';
        this.budget = data.budget || 0;
        this.active = data.active || false;
        this.promotions = (data.promotions || []).map(promo => new Promotion(promo));
    }

    // Method to convert the model to the backend structure
    toBackendFormat() {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            start_date: this.startDate,
            end_date: this.endDate,
            goal_description: this.goalDescription,
            budget: this.budget,
            active: this.active,
            promotions: this.promotions.map(promo => promo.toBackendFormat()),
        };
    }

    addPromotion() {
        const newPromotion = new Promotion({
            id: this.promotions.length + 1, // Temporary ID
            name: '',
            description: '',
            start_date: '',
            end_date: '',
            days_of_week: [],
            start_time: '',
            end_time: '',
            discount: '',
            pointsMultiplier: '',
            conditions: [],
            targets: [],
            incentives: [],
            strategies: [],
        });
        this.promotions.push(newPromotion);
    }

    // Method to update a promotion
    updatePromotion(index, field, value) {
        if (this.promotions[index]) {
            this.promotions[index].updateField(field, value);
        }
    }

    addConditionToPromotion(index) {
        if (this.promotions[index]) {
            this.promotions[index].addCondition();
        }
    }

    addTargetToPromotion(index) {
        if (this.promotions[index]) {
            this.promotions[index].addTarget();
        }
    }

    addIncentiveToPromotion(index) {
        if (this.promotions[index]) {
            this.promotions[index].addIncentive();
        }
    }
}

export default Campaign;
