import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchDashboardData } from '../../../services/Http/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChartLine,
    faDollarSign,
    faUsers,
    faBuilding,
    faIndustry,
    faReceipt,
    faStore,
    faCoins,
    faChartBar,
    faShoppingCart,
    faInfoCircle,
    faQuestionCircle // Import question mark icon
} from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import QuestionModal from '../../global/QuestionModal'; // Import QuestionModal

function Dashboard() {
    const { t } = useTranslation();
    const [dashboardData, setDashboardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDashboardTutorialModal, setShowDashboardTutorialModal] = useState(!localStorage.getItem('DashboardTutorialSeen'));
    const [showQuestionModal, setShowQuestionModal] = useState(false); // State for the question modal

    useEffect(() => {
        const loadDashboardData = async () => {
            try {
                const data = await fetchDashboardData();
                // Handle the array response
                if (Array.isArray(data) && data.length > 0) {
                    const filteredData = filterData(data[0]);
                    setDashboardData(filteredData);
                } else {
                    setError(new Error('Invalid dashboard data format.'));
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        loadDashboardData();
    }, []);

    const handleCloseDashboardTutorialModal = () => {
        setShowDashboardTutorialModal(false);
        localStorage.setItem('DashboardTutorialSeen', 'true'); // Save to local storage
    };

    const handleShowDashboardTutorialModal = () => {
        setShowDashboardTutorialModal(true);
    };

    const handleShowQuestionModal = () => {
        setShowQuestionModal(true); // Open the QuestionModal
    };

    const handleCloseQuestionModal = () => {
        setShowQuestionModal(false); // Close the QuestionModal
    };

    if (loading) {
        return <div className="loading">{t('Dashboard.loading')}</div>;
    }

    if (error) {
        return <div className="error">{t('Dashboard.error')}: {error.message}</div>;
    }

    return (
        <div className="dashboard">
            <h1 className="dashboard-title">{t('Dashboard.title')}
                <button className="btn btn-link" onClick={handleShowQuestionModal} style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)' }}>
                    <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '1.5rem' }} />
                </button>
                <button className="btn btn-link" onClick={handleShowDashboardTutorialModal} style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)' }}>
                    <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '1.5rem' }} />
                </button>
            </h1>
            <div className="grid-container">
                {dashboardData && Object.entries(dashboardData).map(([key, value]) => (
                    <div key={key} className="grid-item">
                        <div className="item-content">
                            <FontAwesomeIcon
                                icon={selectIcon(key)}
                                size="3x"
                                className="item-icon"
                            />
                            <h3 className="item-title">{t(`Dashboard.${key}`)}</h3>
                            <p className="item-value">{formatNumber(value)}</p>
                        </div>
                    </div>
                ))}
            </div>

            {/* Dashboard Tutorial Modal */}
            <Modal show={showDashboardTutorialModal} onHide={handleCloseDashboardTutorialModal} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Dashboard.Tutorial.title', { defaultValue: "Dashboard Tutorial" })}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: 'auto' }}>
                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Dashboard.Tutorial.explanation_title', { defaultValue: "Overview" })}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Dashboard.Tutorial.explanation_message', {
                                    defaultValue: "This dashboard displays your organization's statistics from the day of registration. You can customize the view according to your preferences."
                                })}
                            </p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={handleCloseQuestionModal}
                param="dashboard" // Pass the parameter for the question part
            />
        </div>
    );
}

const filterData = (data) => {
    const keysToExtract = [
        'average_revenue_per_user',
        'average_selling_transaction_value',
        'belonging_users_count',
        'campaigns_count',
        'manufacturer_count',
        'revenue_value',
        'selling_transactions_count',
        'sold_items_count',
        'total_transaction_volume',
        'users_count',
        'venues_count'
    ];

    return Object.fromEntries(
        Object.entries(data).filter(([key, value]) => keysToExtract.includes(key) && parseFloat(value) !== 0)
    );
};

const formatNumber = (value) => {
    return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

const selectIcon = (key) => {
    const iconMapping = {
        'average_revenue_per_user': faDollarSign,
        'average_selling_transaction_value': faChartLine,
        'belonging_users_count': faUsers,
        'campaigns_count': faChartBar,
        'manufacturer_count': faIndustry,
        'revenue_value': faReceipt,
        'selling_transactions_count': faShoppingCart,
        'sold_items_count': faStore,
        'total_transaction_volume': faCoins,
        'users_count': faUsers,
        'venues_count': faBuilding
    };

    return iconMapping[key] || faChartLine;
};

export default Dashboard;
