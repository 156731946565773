import React, { useState, useEffect } from 'react';
import { fetchVenueStatistics } from '../../../../services/Http/Api'; // Assuming this API exists
import DataTable from 'react-data-table-component';
import PeriodSelectBox from "../../../global/PeriodSelectBox";
import { useTranslation } from 'react-i18next';

const numberFormatter = (value) => new Intl.NumberFormat('en-US').format(value);

const VenueStatistics = ({ venueId }) => {
    const { t } = useTranslation();
    const [venueStats, setVenueStats] = useState([]);
    const [totals, setTotals] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [sortBy, setSortBy] = useState('date');
    const [sortDirection, setSortDirection] = useState('asc');
    const [selectedPeriod, setSelectedPeriod] = useState('yesterday');

    useEffect(() => {
        const loadVenueStats = async () => {
            setLoading(true);
            try {
                const data = await fetchVenueStatistics({
                    venueId,
                    period: selectedPeriod,
                    page: currentPage,
                    perPage,
                    sortBy,
                    sortDirection
                });

                setTotals(data.totals);
                setVenueStats(data.stats.data);
                setTotalRows(data.stats.total);
            } catch (error) {
                console.error('Failed to load venue stats', error);
            } finally {
                setLoading(false);
            }
        };

        loadVenueStats();
    }, [selectedPeriod, currentPage, perPage, sortBy, sortDirection, venueId]);

    const handlePageChange = (page) => setCurrentPage(page);
    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };
    const handleSort = (column, sortDirection) => {
        const columnField = column.key || 'date'; // Use the key property or default to 'date'
        setSortBy(columnField);
        setSortDirection(sortDirection);
    };

    const columns = [
        { name: t('Venues.VenueView.VenueStatistics.date'), selector: row => new Date(row.date).toLocaleDateString(), sortable: true, key: 'date' },
        { name: t('Venues.VenueView.VenueStatistics.total_price'), selector: row => numberFormatter(row.total_price), sortable: true, key: 'total_price' },
        { name: t('Venues.VenueView.VenueStatistics.total_revenue'), selector: row => numberFormatter(row.total_revenue), sortable: true, key: 'total_revenue' },
        { name: t('Venues.VenueView.VenueStatistics.total_quantity'), selector: row => numberFormatter(row.total_quantity), sortable: true, key: 'total_quantity' },
        { name: t('Venues.VenueView.VenueStatistics.average_price'), selector: row => numberFormatter(row.average_price), sortable: true, key: 'average_price' },
        { name: t('Venues.VenueView.VenueStatistics.average_revenue'), selector: row => numberFormatter(row.average_revenue), sortable: true, key: 'average_revenue' },
        { name: t('Venues.VenueView.VenueStatistics.total_discount'), selector: row => numberFormatter(row.total_discount), sortable: true, key: 'total_discount' },
        { name: t('Venues.VenueView.VenueStatistics.total_tax'), selector: row => numberFormatter(row.total_tax), sortable: true, key: 'total_tax' },
        { name: t('Venues.VenueView.VenueStatistics.transaction_count'), selector: row => numberFormatter(row.transaction_count), sortable: true, key: 'transaction_count' },
    ];

    const handlePeriodChange = (e) => {
        setSelectedPeriod(e.target.value);
    };

    return (
        <div>
            <div className="d-flex justify-content-end mb-3">
                <PeriodSelectBox value={selectedPeriod} onChange={handlePeriodChange} />
            </div>

            <div className="totals-container d-flex justify-content-between mb-4">
                <div className="total-box">
                    <p>{t('Venues.VenueView.VenueStatistics.total_price')}</p>
                    <h4>{totals ? numberFormatter(totals.total_price) : 0}</h4>
                </div>
                <div className="total-box">
                    <p>{t('Venues.VenueView.VenueStatistics.total_quantity')}</p>
                    <h4>{totals ? numberFormatter(totals.total_quantity) : 0}</h4>
                </div>
                <div className="total-box">
                    <p>{t('Venues.VenueView.VenueStatistics.total_revenue')}</p>
                    <h4>{totals ? numberFormatter(totals.total_revenue) : 0}</h4>
                </div>
                <div className="total-box">
                    <p>{t('Venues.VenueView.VenueStatistics.total_transactions')}</p>
                    <h4>{totals ? numberFormatter(totals.total_transactions) : 0}</h4>
                </div>
            </div>

            <DataTable
                columns={columns}
                data={venueStats}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                onSort={handleSort}
                sortServer
                highlightOnHover
                progressPending={loading}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationDefaultPage={currentPage}
                paginationPerPage={perPage}
            />
        </div>
    );
};

export default VenueStatistics;
