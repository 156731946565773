class Language {
    constructor({ id, name, code }) {
        this.id = id; // public property
        this.name = name; // public property
        this.code = code; // public property
    }

    // Optional method for additional logic if needed
    getDisplayName() {
        return `${this.name} (${this.code})`;
    }
}

export default Language;
