import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Spinner, Modal } from 'reactstrap';
import { fetchItems } from "../../../../services/Http/Api";
import { useNavigate } from 'react-router-dom';
import SearchFilters from "./SearchFilters"; // Adjust the path based on your structure
import { useTranslation } from 'react-i18next'; // Import useTranslation
import QuestionModal from '../../../global/QuestionModal'; // Import QuestionModal
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'; // Import icons

const ItemsOverview = () => {
    const { t } = useTranslation(); // Initialize translation hook
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [filters, setFilters] = useState({ searchTerm: '', manufacturerId: '' });
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [showQuestionModal, setShowQuestionModal] = useState(false); // State for question modal
    const [showTutorialModal, setShowTutorialModal] = useState(false); // State for tutorial modal

    const handleViewItem = (id) => {
        navigate(`/items/view/${id}`); // Programmatically navigate to the ViewItem page
    };

    // Define columns with translations
    const columns = [
        {
            name: t('Items.Overview.model_number'), // Translated column name
            selector: row => row.model_number || "N/A", // Fallback to "N/A" if undefined
            key: 'model_number',
            sortable: true
        },
        {
            name: t('Items.Overview.product_name'), // Translated column name
            selector: row => row.product_name || "N/A", // Fallback to "N/A" if undefined
            key: 'product_name',
            sortable: true
        },
        {
            name: t('Items.Overview.current_holding_count'), // Translated column name
            selector: row => row.current_holding_count || 0, // Fallback to 0 if undefined
            key: 'current_holding_count',
            sortable: true
        },
        {
            name: t('Items.Overview.sold_items_count'), // Translated column name
            selector: row => row.sold_items_count || 0, // Fallback to 0 if undefined
            key: 'sold_items_count',
            sortable: true
        },
        {
            name: t('Items.Overview.release_year'), // Translated column name
            selector: row => row.release_year || "Unknown", // Fallback to "Unknown" if undefined
            key: 'release_year',
            sortable: true
        },
        {
            name: t('Items.Overview.warranty_coverage'), // Translated column name
            selector: row => row.warranty_coverage || "N/A", // Fallback to "N/A" if undefined
            key: 'warranty_coverage',
            sortable: true
        },
        {
            name: t('Items.Overview.actions'), // Translated column name
            cell: row => (
                <button
                    className="btn btn-info btn-sm"
                    onClick={() => handleViewItem(row.id)} // Pass the row's ID to the handler
                >
                    {t('Items.Overview.view')}
                </button>
            ),
        },
    ];

    // Fetch data from API
    const fetchData = async (page, size = perPage, appliedFilters = filters) => {
        setLoading(true);
        try {
            const response = await fetchItems({
                page,
                perPage: size,
                sortBy: sortField,
                sortDirection,
                search: appliedFilters.searchTerm,
                manufacturerId: appliedFilters.manufacturerId,
            });

            const { data, total } = response;
            setItems(data);      // Set items to display
            setTotalRows(total); // Set total number of rows for pagination
        } catch (error) {
            console.error("Error fetching items:", error); // Debug API call failure
        } finally {
            setLoading(false);
        }
    };

    // Update filter changes from SearchFilters when "Search" is pressed
    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);  // Update filters based on search inputs
        setCurrentPage(1);       // Reset to first page when filters change
        fetchData(1, perPage, newFilters);  // Fetch the new filtered data
    };

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchData(page); // Fetch data for the new page
    };

    // Handle change in number of rows per page
    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage); // Fetch data for the new number of rows
    };

    // Handle column sorting
    const handleSort = (column, sortDirection) => {
        setSortField(column.key);
        setSortDirection(sortDirection);
        fetchData(currentPage); // Refetch data with new sort settings
    };

    const handleShowQuestionModal = () => setShowQuestionModal(true);
    const handleCloseQuestionModal = () => setShowQuestionModal(false);

    const handleShowTutorialModal = () => setShowTutorialModal(true);
    const handleCloseTutorialModal = () => setShowTutorialModal(false);

    return (
        <div>
            <h4>{t('Items.Overview.title')}</h4>

            {/* Use the SearchFilters component */}
            <SearchFilters onFilter={handleFilterChange} />

            <DataTable
                columns={columns}
                data={items}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                onSort={handleSort}
                sortServer
                highlightOnHover
                progressPending={loading}
                progressComponent={<Spinner />}
            />
        </div>
    );
};

export default ItemsOverview;
