import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { fetchVenueById, updateVenue } from '../../../../services/Http/Api';
import VenueDetails from './VenueDetails';
import VenueStatistic from './VenueStatistic';
import VenueIntegration from './VenueIntegration';
import VenueForm from '../../../global/Venue/VenueForm';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import QuestionModal from "../../../global/QuestionModal"; // Import Question Modal
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'; // Import icons

const VenueView = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const [venue, setVenue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('details');

    // State for the modals
    const [showQuestionModal, setShowQuestionModal] = useState(false); // State for the question modal
    const [showTutorialModal, setShowTutorialModal] = useState(false); // State for the tutorial modal

    useEffect(() => {
        const loadVenue = async () => {
            try {
                const response = await fetchVenueById(id);
                setVenue(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching venue:', error);
            }
        };

        loadVenue();
    }, [id]);

    const handleSave = async (updatedVenueData) => {
        try {
            await updateVenue(id, updatedVenueData);
            // Handle post-save actions like notifications or redirect if needed
            //setVenue(updatedVenueData); // Update the venue state after save
        } catch (error) {
            console.error('Error saving venue:', error);
        }
    };

    if (loading) {
        return <div>{t('Venues.VenueView.loading')}</div>;
    }

    return (
        <div>
            <h2>{t('Venues.VenueView.title', { name: venue.name })}</h2>
            <div className="d-flex justify-content-end mb-3">
                <button
                    className="btn btn-link"
                    onClick={() => setShowQuestionModal(true)} // Show question modal
                    style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)', marginLeft: '0.5rem' }}
                >
                    <FontAwesomeIcon icon={faQuestionCircle} style={{ fontSize: '1.5rem' }} />
                </button>
                <button
                    className="btn btn-link"
                    onClick={() => setShowTutorialModal(true)} // Show tutorial modal
                    style={{ textDecoration: 'none', color: 'rgba(130, 22, 146, 1)', marginLeft: '0.5rem' }}
                >
                    <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '1.5rem' }} />
                </button>
            </div>
            <Tabs
                id="venue-view-tabs"
                activeKey={activeTab}
                onSelect={(key) => setActiveTab(key)}
                className="mb-3"
            >
                {/* Details Tab */}
                <Tab eventKey="details" title={t('Venues.VenueView.tabs.details')}>
                    <VenueDetails venue={venue} />
                </Tab>

                {/* Statistics Tab */}
                <Tab eventKey="statistics" title={t('Venues.VenueView.tabs.statistics')}>
                    <VenueStatistic venueId={venue.id} />
                </Tab>

                {/* Integration Tab */}
                <Tab eventKey="integration" title={t('Venues.VenueView.tabs.integration')}>
                    <VenueIntegration venueId={venue.id} />
                </Tab>

                {/* Edit Tab */}
                <Tab eventKey="edit" title={t('Venues.VenueView.tabs.edit')}>
                    <VenueForm venue={venue} onSave={handleSave} />
                </Tab>
            </Tabs>

            {/* Question Modal */}
            <QuestionModal
                show={showQuestionModal}
                onClose={() => setShowQuestionModal(false)} // Close modal
                param="venue_overview" // Pass the parameter for the question part
            />

            {/* Tutorial Modal */}
            <Modal show={showTutorialModal} onHide={() => setShowTutorialModal(false)} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Venues.VenueView.Tutorial.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: 'auto' }}>
                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Venues.VenueView.Tutorial.details_title')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Venues.VenueView.Tutorial.details_message')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Venues.VenueView.Tutorial.statistics_title')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Venues.VenueView.Tutorial.statistics_message')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Venues.VenueView.Tutorial.integration_title')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Venues.VenueView.Tutorial.integration_message')}
                            </p>
                        </div>

                        <div className="card" style={{ padding: '20px', marginBottom: '1.5rem', backgroundColor: '#f8f9fa', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                            <h5 style={{ fontSize: '1.25rem', marginBottom: '1rem', color: 'rgba(130, 22, 146, 1)' }}>
                                {t('Venues.VenueView.Tutorial.edit_title')}
                            </h5>
                            <p style={{ fontSize: '1rem', lineHeight: '1.6', margin: 0 }}>
                                {t('Venues.VenueView.Tutorial.edit_message')}
                            </p>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    );
};

export default VenueView;
