import React from 'react';

const GeneralSettings = () => {
    return (
        <div>
            <h3>General Settings</h3>
            {/* Form for Organization Info */}
            <form>
                <div className="mb-3">
                    <label className="form-label">Organization Name</label>
                    <input type="text" className="form-control" placeholder="Enter organization name" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea className="form-control" placeholder="Enter description"></textarea>
                </div>
                <div className="mb-3">
                    <label className="form-label">Logo</label>
                    <input type="file" className="form-control" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Currency</label>
                    <input type="text" className="form-control" placeholder="Enter currency" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Time Zone</label>
                    <select className="form-control">
                        <option>UTC</option>
                        <option>PST</option>
                        <option>EST</option>
                        {/* Add more time zones as needed */}
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">Save Changes</button>
            </form>
        </div>
    );
};

export default GeneralSettings;
