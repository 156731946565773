import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { getCurrentUser } from "../../services/Http/AuthService";
import WarrantiesList from '../parts/OrgUser/WarrantiesList';
import WarrantiesTemplatesList from '../parts/OrgUser/WarrantiesTemplatesList';
import CampaignList from '../parts/OrgUser/campaign/CampaignList';
import POSIntegration from '../parts/OrgUser/POSIntegration';
import Settings from '../parts/OrgUser/Settings/Settings';
import OrganizationDetail from "../parts/OrgUser/OrganizationDetail";
import ProtectedRoute from '../ProtectedRoute';
import ManufactorItemsListHolding from "../parts/OrgUser/ManufactorItemsListHolding";
import Dashboard from "../parts/OrgUser/Dashboard";
import Users from "../parts/OrgUser/Users/Users";
import Transactions from "../parts/OrgUser/Transactions/Transactions";
import Venues from "../parts/OrgUser/Venues/Venues";
import VenueCreate from "../parts/OrgUser/Venues/VenueCreate";
import VenueView from "../parts/OrgUser/Venues/VenueView";
import Items from "../parts/OrgUser/Items/Items";
import ViewItem from "../parts/OrgUser/Items/Item/ViewItem";
import CampaignEdit from "../parts/OrgUser/campaign/CampaignEdit";
import CampaignCreate from "../parts/OrgUser/campaign/CampaignCreate";
import AuthorizedServiceCenters from "../parts/OrgUser/AuthorizedServiceCenters";

const AppRoutes = () => {
    const user = getCurrentUser();

    // Define predefined routes with route key as condition
    const predefinedRoutes = [
        { key: 'dashboard', path: '/', element: <Dashboard /> },
        { key: 'users', path: '/users', element: <Users /> },
        { key: 'transactions', path: '/transactions', element: <Transactions /> },
        { key: 'venues', path: '/venues', element: <Venues /> },
        { key: 'items', path: '/items', element: <Items /> },
        { key: 'warranties', path: '/warranties', element: <WarrantiesList /> },
        { key: 'warranties_templates', path: '/warranties_templates', element: <WarrantiesTemplatesList /> },
        { key: 'authorized_services', path: '/authorized_services', element: <AuthorizedServiceCenters /> },
        { key: 'campaigns', path: '/campaigns', element: <CampaignList /> },
        { key: 'integration', path: '/integration', element: <POSIntegration /> },
        { key: 'settings', path: '/settings', element: <Settings /> },
        { key: 'logout', path: '/logout', element: <Navigate replace to="/" /> },
        { key: 'organizationDetail', path: '/organizations/:id', element: <OrganizationDetail /> },
        { key: 'venueCreate', path: '/venues/create', element: <VenueCreate /> },
        { key: 'venueView', path: '/venues/view/:id', element: <VenueView /> },
        { key: 'viewItem', path: '/items/view/:itemId', element: <ViewItem /> },
        { key: 'campaignCreate', path: '/campaigns/create', element: <CampaignCreate /> },
        { key: 'campaignEdit', path: '/campaigns/edit/:id', element: <CampaignEdit /> },
    ];

    return (
        <Routes>
            {predefinedRoutes.map(({ key, path, element }) => (
                <Route
                    key={key}
                    path={path}
                    element={

                            <ProtectedRoute element={element} />

                    }
                />
            ))}

            {/* Ensure to have a catch-all route for undefined paths */}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};

export default AppRoutes;
