import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Spinner } from 'reactstrap';
import { fetchItemPerformanceInsights } from '../../../../../services/Http/Api';
import PeriodSelectBox from "../../../../global/PeriodSelectBox";
import { useTranslation } from 'react-i18next'; // Import useTranslation

const TransactionsTable = ({ itemId }) => {
    const { t } = useTranslation(); // Initialize translation hook
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [selectedPeriod, setSelectedPeriod] = useState('last_7_days'); // Default period

    useEffect(() => {
        const loadTransactions = async () => {
            setLoading(true);
            try {
                const data = await fetchItemPerformanceInsights(itemId, currentPage, perPage, selectedPeriod);
                setTransactions(data.data || []);
                setTotalRows(data.total); // Assuming your pagination returns the total count
            } catch (error) {
                console.error('Error fetching transactions:', error);
            } finally {
                setLoading(false);
            }
        };

        loadTransactions();
    }, [itemId, currentPage, perPage, selectedPeriod]); // Include selectedPeriod dependency

    const handlePageChange = (page) => setCurrentPage(page);
    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    const handlePeriodChange = (event) => {
        setSelectedPeriod(event.target.value);
        setCurrentPage(1); // Reset to page 1 when the period changes
    };

    const columns = [
        { name: t('Items.TransactionsTable.date'), selector: row => new Date(row.date_of_transaction).toLocaleDateString(), sortable: true },
        { name: t('Items.TransactionsTable.buyer'), selector: row => `${row.buyer?.name} ${row.buyer?.surname}`, sortable: true },
        { name: t('Items.TransactionsTable.venue'), selector: row => row.venue?.name || t('Items.TransactionsTable.n_a'), sortable: true },
        { name: t('Items.TransactionsTable.quantity'), selector: row => row.quantity, sortable: true },
        { name: t('Items.TransactionsTable.price'), selector: row => `$${parseFloat(row.price).toFixed(2)}`, sortable: true },
        { name: t('Items.TransactionsTable.revenue'), selector: row => `$${parseFloat(row.revenue).toFixed(2)}`, sortable: true },
        { name: t('Items.TransactionsTable.payment_method'), selector: row => row.payment_method, sortable: true },
        { name: t('Items.TransactionsTable.transaction_type'), selector: row => row.transaction_type, sortable: true },
        { name: t('Items.TransactionsTable.shipping_cost'), selector: row => `$${JSON.parse(row.shipping_details)?.cost || t('Items.TransactionsTable.n_a')}`, sortable: true },
        { name: t('Items.TransactionsTable.warranty'), selector: row => JSON.parse(row.warranty_details)?.warranty_period || t('Items.TransactionsTable.n_a'), sortable: true },
    ];

    return (
        <div>
            <div className="d-flex justify-content-end mb-3">
                <PeriodSelectBox value={selectedPeriod} onChange={handlePeriodChange} />
            </div>

            <h5>{t('Items.TransactionsTable.title')}</h5>
            {loading ? (
                <div className="text-center">
                    <Spinner color="primary" />
                </div>
            ) : (
                <DataTable
                    columns={columns}
                    data={transactions}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    highlightOnHover
                    progressPending={loading}
                />
            )}
        </div>
    );
};

export default TransactionsTable;
