import React from 'react';
import { Table, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const ItemDescription = ({ itemDetails }) => {
    const { t } = useTranslation(); // Initialize translation hook

    if (!itemDetails) return <div>{t('Items.ItemDescription.no_details')}</div>;

    // Parse technical specifications from JSON if available
    const technicalSpecifications = itemDetails.technical_specifications
        ? JSON.parse(itemDetails.technical_specifications)
        : null;

    // Function to render the technical specifications recursively
    const renderSpecifications = (specifications) => {
        if (!specifications) return null;

        return (
            <Table bordered responsive size="sm">
                <tbody>
                {Object.entries(specifications).map(([key, value]) => (
                    <tr key={key}>
                        <th style={{ width: '30%', textTransform: 'capitalize' }}>{key.replace(/_/g, ' ')}</th>
                        <td>{typeof value === 'object' ? renderSpecifications(value) : value}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        );
    };

    // Function to format the warranty duration
    const formatWarrantyDuration = (months) => {
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;
        const yearsDisplay = years > 0 ? `${years} ${t('Items.ItemDescription.year', { count: years })}` : '';
        const monthsDisplay = remainingMonths > 0 ? `${remainingMonths} ${t('Items.ItemDescription.month', { count: remainingMonths })}` : '';
        return `${yearsDisplay}${yearsDisplay && monthsDisplay ? ' ' : ''}${monthsDisplay}` || '0 months';
    };

    return (
        <div>
            <Card className="mb-4">
                <CardBody>
                    <CardTitle tag="h5">{itemDetails.product_name}</CardTitle>
                    <CardSubtitle className="text-muted mb-2">{itemDetails.manufacturer.name}</CardSubtitle>
                    <img
                        src={itemDetails.image_url}
                        alt={itemDetails.product_name}
                        style={{ maxWidth: '200px', marginBottom: '15px' }}
                    />
                    <Table borderless>
                        <tbody>
                        <tr>
                            <th>{t('Items.ItemDescription.model_number')}</th>
                            <td>{itemDetails.model_number}</td>
                        </tr>
                        <tr>
                            <th>{t('Items.ItemDescription.description')}</th>
                            <td>{itemDetails.description}</td>
                        </tr>
                        <tr>
                            <th>{t('Items.ItemDescription.release_year')}</th>
                            <td>{itemDetails.release_year}</td>
                        </tr>
                        <tr>
                            <th>{t('Items.ItemDescription.warranty_duration')}</th>
                            <td>{formatWarrantyDuration(itemDetails.warranty_duration)}</td>
                        </tr>
                        <tr>
                            <th>{t('Items.ItemDescription.warranty_coverage')}</th>
                            <td>{itemDetails.warranty_coverage}</td>
                        </tr>
                        <tr>
                            <th>{t('Items.ItemDescription.documentation_url')}</th>
                            <td>
                                <a href={itemDetails.documentation_url} target="_blank" rel="noopener noreferrer">
                                    {t('Items.ItemDescription.view_documentation')}
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>

            {/* Display Technical Specifications */}
            <Card>
                <CardBody>
                    <CardTitle tag="h5">{t('Items.ItemDescription.technical_specifications')}</CardTitle>
                    {technicalSpecifications ? (
                        renderSpecifications(technicalSpecifications)
                    ) : (
                        <div>{t('Items.ItemDescription.no_technical_specifications')}</div>
                    )}
                </CardBody>
            </Card>
        </div>
    );
};

export default ItemDescription;
